import React, { Component } from 'react';
import moment from 'moment';
import {Form, Row, Col, Modal, Button} from 'react-bootstrap';
import DataGrid from '../common_components/DataGrid.js';
import TabContainer from '../common_components/TabContainer.js';
import InputText from '../common_components/InputText.js';
import DataDropdown from '../common_components/DataDropdown.js';
import { getAPI, postAPI, postAPIMultipartForm, openBirtReport, validateForm, resetObject, getLoggedInUser, convertTimestampToDate, saveSpecOutput, showFormValidationAlert, constants, mapArrayToObject} from '../common_components/common.js';
import {MyInkIQReactGA} from '../common_components/common.js';
import NewFormulaForm from '../formulas/NewFormulaForm.js';
import OrderForm from '../orders/OrderForm.js';
import NewTaskForm from '../tasks/NewTaskForm.js';
import NewCallReportForm from '../call-reports/NewCallReportForm.js';
import {
			countries, 
			umCodes, 
			contactTypes, 
			customerStatuses,
			paymentTerms,
			illuminants,
			observerAngles,
			deltaETypes,
			devices,
			salesReps,
			shippers,
			emailTemplates,
			orderStatuses,
			orderTypes,
			viscosityCups,
			dryerTypes,
			meteringSystems,
			bladeTypes,
			containersTypes,
			corporates,
		} 
	from '../common_components/commonData.js';

import Config from '../Config';
import config from '../Config';

const { Translate, Localize } = require('react-i18nify');
//#region Variable declarations
var countrieslist = {};

var umCodeList = {};

var contactTypeList = [];

var statuses = {};

var customerTerms = {};

var illuminantList = {};

var observerAngleList = {};

var deltaEs = {};

var deviceList = {};

var repnames = [];

var shippersList = {};

var emailTemplateList = [];

var orderStatusList = [];

var orderTypesList = [];

var viscosityCupsList = {};

var dryerTypesList = {};

var meteringSystemsList = {};

var bladeTypesList = {};

var corporatesList = {};

const locales = {
	"EN_US": "USA",
	"ES_MX": "MEXICO",
};

var containersList = [];

function blankRep() {
	return {
		actorId:0,
		customerId:0,
		displayName:null,
		firstName:null,
		id:0,
		lastName:null,
		// model:"com.phototype.myinkiq.model.vos.dtos::CustomerSalesRepsDto",
		name:null
	};
};

function blankContactEmailStuff() {
	return {
	"id":null,
	"name":null,
	"customerContactId":null,
	"emailTemplateId":null
	};
};
//#endregion
class CustomerDeleteConfirmation extends Component {
	onDelete() {
		this.props.onConfirm();
		this.props.onClose();
	}

	render() {
		return(<Modal show={this.props.visible}>
			<Modal.Body><Translate value='components.customers.areYouSure'/></Modal.Body>
			<Modal.Footer>
				<Button  bsStyle='danger' onClick={() => this.onDelete()}><Translate value='components.customers.delete'/>
				</Button>
				<Button onClick={this.props.onClose}><Translate value='components.customers.cancel'/></Button>
			</Modal.Footer>
		</Modal>);
	}
}

class CustomerPopin extends React.Component {
	// Properties:
	//  editCustomerId: the unique id for the user
	constructor(props) {
		super(props);
		this.reactGA = new MyInkIQReactGA();
		
		if(Config.locale == 'ES_MX') {
			this.state = {
				showDeleteWarning: false,
				userData:{
					"id": null,
					"name": null,
					"statusLookupId": null,
					"umCodeLookupId": null,
					"customerTermsId": 136,
					"orderDiscountPercent": 0,
					"customerSinceDate": moment.now().valueOf(),
					"notes": "",
					"salesGoal": null,
					"defaultShipperId": 14,
					"addressId": null,
					"address1":  null,
					"address2": null,
					"city": null,
					"stateId": null,
					"countryId":154,
					"phoneNumber": null,
					"faxNumber": null,
					"zipCode": null,
					"website": "",
					"retired": false,
					"defaultShipTime": 1,
					"illuminantId": 5,
					"observerAngleId": 112,
					"deltaETypeId": 60,
					"colorTolerance": 2,
					"batchTolerance":null,
					"deviceTypeLookupId": 68,
					"setupReadsCount": 1,
					"productionReadsCount": 3,
					"targetReadsCount": 6,
					"qcreadsCount": 1,
					"printHMISFlag": true,
					"locale":'ES_MX',
					"taxExempt":null,
					"rfc":null,
					"lrnotes": "",
					"bfuelSurcharge": true,
					"bfreightSurcharge": null,
					"bplasticDrums": null,
					"bcustomizeBarcode": null,
					"bconsignment": null,
					"bAutoPrintCofA": null,
					"bSpectro1": null,
					"bTieredVolumeDiscounts": null,
					"tier1StartVolume": null,
					"tier1EndVolume": null,
					"tier1Discount": null,
					"tier2StartVolume": null,
					"tier2EndVolume": null,
					"tier2Discount": null,
					"tier3StartVolume": null,
					"tier3EndVolume": null,
					"tier3Discount": null,
					"drawDownsNeeded": null,
					"drawDownsSent": null,
					"bdrawDownsWithOrder": false,
					"lsdNeeded": null,
					"lsdSent": null,
					"contractId": null,
				    "startDate": null,
					"endDate": null,
					"pdfLocation": null,
					"totalAmount": null,
					"percentOfTotalContract": null,
					// "bUseZahn2EZCup": null,
					"viscosityCupLookupId": null,
					"bNewCustomer": true,
					"qboCustomerRefId": null,
					"bWetSample": true,
				},
				salesReps:[],
				repsToPush:[],
				repsToPop:[],
				notes:[],
				clientUsers:[],
				addressList:[],
				addresses:[],
				activeCustomers:[],
				formulas: [],
				contactList:[],
				contacts: [],
				orders:[],
				presses:[],
				states: [],
				tasks: [],
				callReports: [],
				contracts:[],
				contractsList:[],
				isLoaded: false,
				showSpecOutput: false,
				specOutputDate: '',
				salesSectionData: {},
				defaultWeights: [],
			};
		} else {
			this.state = {
				showDeleteWarning: false,
				userData:{
					"id": null,
					"name": null,
					"statusLookupId": null,
					"umCodeLookupId": null,
					"customerTermsId": 136,
					"orderDiscountPercent": 0,
					"customerSinceDate": moment.now().valueOf(),
					"notes": "",
					"salesGoal": null,
					"defaultShipperId": 14,
					"addressId": null,
					"address1":  null,
					"address2": null,
					"city": null,
					"stateId": null,
					"countryId":1,
					"phoneNumber": null,
					"faxNumber": null,
					"zipCode": null,
					"website": "",
					"retired": false,
					"defaultShipTime": 1,
					"illuminantId": 5,
					"observerAngleId": 112,
					"deltaETypeId": 60,
					"colorTolerance": 2,
					"batchTolerance":null,
					"deviceTypeLookupId": 68,
					"setupReadsCount": 1,
					"productionReadsCount": 3,
					"targetReadsCount": 6,
					"qcreadsCount": 1,
					"printHMISFlag": true,
					"locale":'EN_US',
					"taxExempt":null,
					"rfc":null,
					"bfuelSurcharge": true,
					"bfreightSurcharge": null,
					"bplasticDrums": null,
					"bcustomizeBarcode": null,
					"bconsignment": null,
					"bAutoPrintCofA": null,
					"bSpectro1": null,
					"bTieredVolumeDiscounts": null,
					"tier1StartVolume": null,
					"tier1EndVolume": null,
					"tier1Discount": null,
					"tier2StartVolume": null,
					"tier2EndVolume": null,
					"tier2Discount": null,
					"tier3StartVolume": null,
					"tier3EndVolume": null,
					"tier3Discount": null,
					"drawDownsNeeded": null,
					"drawDownsSent": null,
					"bdrawDownsWithOrder": false,
					"lsdNeeded": null,
					"lsdSent": null,
					"contractId": null,
					"startDate": null,
					"endDate": null,
					"pdfLocation": null,
					"totalAmount": null,
					"percentOfTotalContract": null,
					// "bUseZahn2EZCup": null,
					"viscosityCupLookupId": null,
					"bNewCustomer": true,
					"qboCustomerRefId": null,
					"bWetSample": true,
				},
				salesReps:[],
				repsToPush:[],
				repsToPop:[],
				notes:[],
				clientUsers:[],
				addressList:[],
				addresses:[],
				activeCustomers:[],
				formulas: [],
				contactList:[],
				contacts: [],
				orders:[],
				presses:[],
				states: [],
				tasks: [],
				callReports: [],
				contracts:[],
				contractsList:[],
				isLoaded: false,
				showSpecOutput: false,
				specOutputDate: '',
				salesSectionData: {},
				defaultWeights: [],
			};
		}
		this.state.salesSectionData = {			
		};
		this.handleInputChangedUser = this.handleInputChangedUser.bind(this);
		this.handleInputChangedSalesReps = this.handleInputChangedSalesReps.bind(this);
		this.handleInputChangedSalesSection = this.handleInputChangedSalesSection.bind(this);
		this.handleSaveChangedAddress = this.handleSaveChangedAddress.bind(this);
		this.handleSaveChangedContract = this.handleSaveChangedContract.bind(this);
		this.handleSaveChangedContact = this.handleSaveChangedContact.bind(this);
		this.handleSaveChangedNote = this.handleSaveChangedNote.bind(this);
		this.handleSaveChangedSalesSection = this.handleSaveChangedSalesSection.bind(this);
		this.refreshFormulaData = this.refreshFormulaData.bind(this);
		this.refreshPressData = this.refreshPressData.bind(this);
		this.refreshOrdersData = this.refreshOrdersData.bind(this);
		this.refreshTasksData = this.refreshTasksData.bind(this);
		this.refreshCallReportsData = this.refreshCallReportsData.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onSaveDefaultWeight = this.onSaveDefaultWeight.bind(this);
		this.onDeleteDefaultWeight = this.onDeleteDefaultWeight.bind(this);
		this.refreshDefaultWeightsData = this.refreshDefaultWeightsData.bind(this);
		this.shipmentOutlierChecked = this.shipmentOutlierChecked.bind(this);
		this.saveCustomerSalesReps = this.saveCustomerSalesReps.bind(this);
	}
	

	async componentDidMount() {
		// Make common requests and format data accordingly

		// #region global variable requests

		// reset values to null
		countrieslist = {};
		umCodeList = {};
		contactTypeList = [];
		statuses = {};
		customerTerms = {};
		illuminantList = {};
		observerAngleList = {};
		deltaEs = {};
		deviceList = {};
		repnames = [];
		shippersList = {};
		emailTemplateList = [];
		orderStatusList = [];
		orderTypesList = [];
		viscosityCupsList = {};
		dryerTypesList = {};
		meteringSystemsList = {};
		bladeTypesList = {};
		countries()
		.then(countryData => {
			for (const country of countryData) {
				countrieslist[country.id] = country.name;
			};
		});

		umCodes()
		.then(umCodeData => {
			for (const code of umCodeData) {
				umCodeList[code['id']] = code['value']
			};
		});

		contactTypes()
		.then(contactTypeData => {
			// for (const element of contactTypeData) {
			//	contactTypeList[element['id']] = element['name']
			// };
			contactTypeList = contactTypeData;
		});

		customerStatuses()
		.then(statusData => {
			for (const element of statusData) {
				statuses[element['id']] = element['value']
			};
		});

		paymentTerms()
		.then(Data => {
			for (const element of Data) {
				customerTerms[element['id']] = element['name']
			};
		});

		illuminants()
		.then(Data => {
			for (const element of Data) {
				illuminantList[element['illuminantId']] = element['code']
			};
		});

		observerAngles()
		.then(Data => {
			for (const element of Data) {
				observerAngleList[element['id']] = element['name']
			};
		});

		deltaETypes()
		.then(Data => {
			for (const element of Data) {
				deltaEs[element['id']] = element['name']
			};
		});

		devices()
		.then(Data => {
			for (const element of Data) {
				deviceList[element['id']] = element['name']
			};
		});

		salesReps()
		.then(Data => {
			for (const element of Data) {
				repnames.push( {value: element.id, label: element.name} )
			};
		});

		shippers()
		.then(Data => {
			for (const element of Data) {
				shippersList[element['id']] = element['name']
			};
		});

		emailTemplates()
		.then(Data => {
			for (const element of Data) {
				emailTemplateList.push( {value: element.id, label: element.category} );
				// emailTemplateList.push( {value: element.id, label: element.category + " (" + element.language + ")"} );
			};
		});

		orderStatuses()
		.then(Data => {
			for (const element of Data) {
				if (!orderStatusList.includes(element['value'])) {
					orderStatusList.push(element['value'])
				}
			};
		});
		
		orderTypes()
		.then(Data => {
			for (const element of Data) {
				orderTypesList.push(element['value'])
			};
		});

		await viscosityCups()
		.then(Data => {
			for (const element of Data) {
				// Do NOT add Din 4
				if(element['id'] != constants.viscosity_cup_id_din4 && !element['retired'])
					viscosityCupsList[element['id']] = element['name'];
			};
		});

		dryerTypes()
		.then(Data => {
			for (const element of Data) {
				dryerTypesList[element['id']] = element['value']
			};
		});

		meteringSystems()
		.then(Data => {
			for (const element of Data) {
				meteringSystemsList[element['id']] = element['value']
			};
		});

		bladeTypes()
		.then(Data => {
			for (const element of Data) {
				bladeTypesList[element['id']] = element['value']
			};
		});
		
		containersTypes()
		.then(containersTypesData => {
			for (const element of containersTypesData) {
				containersList.push(element)
			};
		});
		
		// Corporates
		await corporates()
		.then(corporatesData => {
			// console.log("corporatesData: ", corporatesData);
			for (const element of corporatesData) {
				if(element['bCorporate'] == 1)
					corporatesList[element['id']] = element['name'];
			};
		});
		//#endregion

		if (this.props.editCustomerId != null) {
			
			// Get Customer Data
			var url1 = "customers/"+this.props.editCustomerId;
			var url2 = "customers/"+this.props.editCustomerId+"/salesreps";
			var url3 = "customers/"+this.props.editCustomerId+"/clientusers";
			var url6 = "customers/"+this.props.editCustomerId+"/notes";
			var url7 = "customers/"+this.props.editCustomerId+"/shippingaddresses/list";
			var url8 = "customers/"+this.props.editCustomerId+"/shippingaddresses";
			var url9 = "customers/"+this.props.editCustomerId+"/contacts/list";
			var url10 = "customers/"+this.props.editCustomerId+"/contacts";
			var url11 = "customers/"+this.props.editCustomerId+"/formulas";
			var url12 = "customers/"+this.props.editCustomerId+"/orders";
			var url13 = "customers/list";
			var url14 = "customers/"+this.props.editCustomerId+"/presses/list";
			var url15 = "tasks/search?customerId=" + this.props.editCustomerId;
			var url16 = "callreports/search?customerId=" + this.props.editCustomerId;
			var url17 = "customers/" + this.props.editCustomerId + "/salesData";

			var url18 = "customers/"+this.props.editCustomerId+"/contracts/list";
			// var url19 = "customers/"+this.props.editCustomerId+"/contracts";
			var url19 = "customers/"+this.props.editCustomerId+"/weight/exceptions/list";

			

			Promise.all([
				// Get Customer Data
				getAPI(url1),
				// Get Sales Reps
				getAPI(url2),
				// Get Client Users
				getAPI(url3),
				// Get notes
				getAPI(url6),
				// Get the list of Shipping Addresses // there is different data between the list and the actual objects
				getAPI(url7),
				// Get the shipping address objects
				getAPI(url8),
				// Get list of contacts// there is different data between the list and the actual objects
				getAPI(url9),
				// Get contact objects
				getAPI(url10),
				// Get formulas
				getAPI(url11),
				// Get orders
				getAPI(url12),
				// Get Customers list for active customers for order form
				getAPI(url13),
				// Get list of presses
				getAPI(url14),
				// Get list of tasks
				getAPI(url15),
				// Get list of call reports
				getAPI(url16),
				// Get Customers Sales Section Data
				getAPI(url17),
				//getAPI(url18)
				getAPI(url18),
				// getAPI(url19),
				getAPI(url19),
			])
			.then(
				([user, reps, clients, notes, addressList, addresses, contactList, contacts, formulas, orders, customers, presses, tasks, callReports, salesSection, contractsList, defaultWeights
				// , contracts
				]) => {
					var activeCustomers = customers['data'].filter(obj => obj.status == 'Active');
					user = user['data'][0];
					let activeFormulas = formulas["data"].filter(row => row.status != "Inactive");
					activeFormulas = activeFormulas.map(row => {
						row.displayName = row.customerFormulaName ? row.customerFormulaName : row.name;
						return row;
					});
					var salesSectionData = salesSection['data'][0] != undefined ? salesSection['data'][0] : this.state.salesSectionData;
					if(salesSectionData['customerId'] == undefined) salesSectionData['customerId'] = this.props.editCustomerId;
					// console.log("defaultWeights['data']: ", defaultWeights['data']);
					console.log("user: ", user);
					getAPI("common/states/"+user['countryId'])
					.then(res =>{
						this.setState({
							isLoaded: true,
							userData: user,
							salesReps: reps['data'],
							clientUsers: clients['data'],
							notes: notes['data'],
							addresses: addresses['data'],
							addressList: addressList['data'],
							contacts: contacts['data'],
							contactList: contactList['data'],
							formulas: activeFormulas,
							orders: orders['data'],
							activeCustomers: activeCustomers,
							presses: presses['data'],
							states: res['data'],
							tasks: tasks['data'],
							callReports: callReports['data'],
							salesSectionData: salesSectionData,
							contractsList: contractsList['data'],
							// contracts: contracts['data'],
							defaultWeights: defaultWeights['data'],
						});
					});
				},
				(error) => {
					this.setState({
					isLoaded: true,
					error
					});
				}
			);
		} else {
			getAPI("common/states/"+this.state.userData.countryId)
			.then(res =>{
				var userData = this.state.userData;
				for (const key in statuses) {
					if (statuses[key] == "Active") {
						userData.statusLookupId = key
					}	
				}
				if (Config.locale == 'ES_MX') {
					for (const key in umCodeList) {
						if (umCodeList[key] == "Kg") {
							userData.umCodeLookupId = key
						}	
					}
					this.setState({
						states: res['data'],
						userData: userData,
					})
				} else {
					for (const key in umCodeList) {
						if (umCodeList[key] == "Lb") {
							userData.umCodeLookupId = key
						}	
					}
					this.setState({
						states: res['data'],
						userData: userData,
					})
				}
			})
		}
	}

	onDelete() {
		postAPI("customers/"+this.props.editCustomerId, {})
		.then(() => {
			this.reactGA.gaEvent("Customer", "Delete", "Customer deleted successfully.");
			this.props.onClose();
		})
	}

	onSave() {
		var userData = this.state.userData;
		if (this.state.states.length == 0){
			
			userData['stateId'] = 0;
			this.setState({
				userData: userData,
			})	
		}
		postAPI("customers",this.state.userData)
		.then(resCustomer => resCustomer["data"][0])
		.then(resCustomerData => {
			this.reactGA.gaEvent("Customer", "Save", "Customer saved successfully.");
			// Samee - ToDos - Add Customer Shipping AddressForm
			const customerId = resCustomerData["id"];
			
			// Must be for a new customer
			const customerEditId = this.state.userData.id;
			// console.log("customerEditId: ", customerEditId);
			if(customerEditId == null) {
				var customerShippingAddress = {
					"id": 0,
					"customerId": customerId, // Be sure not to leve populated when removing addresses
					"addressId": 0,
					"address1": userData.address1,
					"address2": userData.address2,
					"city": userData.city,
					"stateId": userData.stateId,
					"countryId": userData.countryId,
					"phoneNumber": userData.phoneNumber,
					"faxNumber": userData.faxNumber,
					"zipCode": userData.zipCode,
					"website": userData.website,
					"billingAddressFlag": false,
					"retired": false,
					// "stateCode": null
				};
				// console.log("customerShippingAddress: ", customerShippingAddress);
				postAPI("customers/" + customerId + "/shippingaddresses", customerShippingAddress).then(() => {});
			}
			
			this.saveCustomerSalesReps(resCustomerData["id"]);
			/*
			var addRepPromises = [];
			if (this.state.repsToPush.length > 0) {
				for (var topush of this.state.repsToPush) {
					if (topush['customerId'] === null) {
						topush['customerId'] = resCustomerData["id"];
					}
					// console.log("topush: ", topush);
					var salesRepActorId = topush['actorId'];
					var objActorCustomer = {
						"customerId": resCustomerData["id"],
						"model":"com.phototype.myinkiq.model.vos.dtos::UserCustomersDto",
						"id":0,
						"name":null,
						"userId": salesRepActorId
					};
					addRepPromises.push(postAPI("customers/"+resCustomerData["id"]+"/salesreps", topush));	
					addRepPromises.push(postAPI("users/" + salesRepActorId + "/customers", objActorCustomer));	
				}
			}
			var deleteRepPromises = [];
			if (this.state.repsToPop.length > 0) {
				for (var topop of this.state.repsToPop) {
					// console.log(topop);
					deleteRepPromises.push(postAPI("customers/"+resCustomerData["id"]+"/salesreps/"+topop.id, {}));			
				}
			}
			Promise.all(addRepPromises)
			.then(() => {
				Promise.all(deleteRepPromises)
				.then(() => {
					this.props.onClose();
				});
			});
			*/
		});
	}
	
	async saveCustomerSalesReps(customerId) {
		// Delete Customer Sales Rep
		if (this.state.repsToPop.length > 0) {
			for (var topop of this.state.repsToPop) {
				// console.log(topop);
				await postAPI("customers/" + customerId + "/salesreps/" + topop.id, {});
			}
		}
		
		if (this.state.repsToPush.length > 0) {
			for (var topush of this.state.repsToPush) {
				if (topush['customerId'] === null) {
					topush['customerId'] = customerId;
				}
				// console.log("topush: ", topush);
				var salesRepActorId = topush['actorId'];
				var objActorCustomer = {
					"customerId": customerId,
					"model":"com.phototype.myinkiq.model.vos.dtos::UserCustomersDto",
					"id":0,
					"name":null,
					"userId": salesRepActorId
				};
				await postAPI("customers/" + customerId + "/salesreps", topush);
				await postAPI("users/" + salesRepActorId + "/customers", objActorCustomer);
			}
		}
		alert("Customer has been saved successfully");
		this.props.onClose();
	}
	
	onDeleteDefaultWeight(deleteId) {
		// console.log("onDeleteDefaultWeight - deleteId ", deleteId);
		postAPI("/customers/" + this.props.editCustomerId + "/weight/exceptions/" + deleteId, {}).then(resFormulaComponent => {
			this.refreshDefaultWeightsData();
		});
		
	}
	
	onSaveDefaultWeight(defaultWeight) {
		postAPI("/customers/" + this.props.editCustomerId + "/weight/exceptions/", defaultWeight).then(resComponent => {
			// console.log("resComponent: ", resComponent);
			if(resComponent['error']) {
				alert("Error " + resComponent['error']['status'] + " while saving customer default weight " + defaultWeight["id"] + " - " + resComponent['error']['error']);
			} else {
				this.refreshDefaultWeightsData();
			}
		});
	}
	
	refreshDefaultWeightsData() {
		var url19 = "customers/" + this.props.editCustomerId + "/weight/exceptions/list";
		getAPI(url19).then(resDefaultWeights => {
			this.setState({
				defaultWeights: resDefaultWeights['data']
			});
		});
	}

	handleInputChangedSalesReps(event, name) {
		var salesReps = this.state.salesReps;
		var repsToPop = this.state.repsToPop;
		var repsToPush = this.state.repsToPush;
		// console.log(event)
		// console.log("Before: ","salesReps: ", this.state.salesReps, "To Push: ", this.state.repsToPush, "To Pop: ", this.state.repsToPop)
		// Because of the nature of these updates, they will only ever add or remove one value at a time...
		// add value
		if (event.length > salesReps.length) {
			// console.log("Adding")
			for (var fresh of event) {
			// event.forEach(fresh => {
				var flag = false;
				for (var stale of salesReps) {
				// this.state.salesReps.forEach(stale => {
					if (fresh.value === stale.actorId) {
						flag = true;
						break;
					}
				}//)
				if (flag === false) { // not in state data so add it
					var newRep = blankRep();
					newRep.customerId = this.state.userData['id']
					newRep.actorId = fresh.value
					newRep.displayName = fresh.label
					// Check to see if rep is in pop list
					var i = 0;
					for (var test of repsToPop) {
					// this.state.repsToPop.forEach( check => {
						if (newRep.actorId === test.actorId) {
							// var old = repsToPop.splice(i,1)[0];
							// newRep = old;
							// this.setState({
							//	repsToPop: repsToPop
							// })
							flag = true; //set to know if it needs to be added to the push list or not
							break;
						}
						i += 1
					}//)
					// if (!flag) { // it was not in the pop list so it needs to be added database side
						repsToPush.push(newRep)
						this.setState({
							repsToPush: repsToPush
						})
					// }
					salesReps.push(newRep)
					this.setState({
						salesReps: salesReps
					})
					break;
				}
			};//);
		} else {// remove value
			// console.log("Removing")
			for (var stale of this.state.salesReps) {
				var flag = false;
				for (var fresh of event) {
					// event.forEach(fresh => {
					if (fresh.value === stale.actorId) {
						flag = true;
						break;
					}
				}//)
				if (flag === false) { // not in event data so remove it
					// Check to see if rep is in push list
					var j = 0;
					for (var test of repsToPush) {
					// this.state.repsToPush.forEach( check => {
						if (stale.actorId === test.actorId) {
							// repsToPush.splice(j,1)
							// this.setState({
							//	repsToPush: repsToPush
							// })
							flag = true; //set to know if it needs to be added to the pop list or not
							break;
						}
						j += 1
					}//)
					// if (!flag) { // it was not in the pop list so it needs to be removed database side
						repsToPop.push(stale)
						this.setState({
							repsToPop: repsToPop
						})
					// }
					// console.log("Stale: ", stale)
					var i = salesReps.findIndex(x => x===stale)
					salesReps.splice(i,1)
					this.setState({
						salesReps: salesReps
					})
					break;
				}
			};//);
		}
		// console.log("After: ","salesReps: ", salesReps, "To Push: ", repsToPush, "To Pop: ", repsToPop)
	}

	handleInputChangedUser(event, name) {
		// console.log(event)
		var value = null;
		if (event instanceof moment) {
			value = event.valueOf();
		} else if (event.target != undefined) {
			const target = event.target;
			value = target.type === 'checkbox' ? target.checked : target.value;
		} else {
			return
		}
		
		var userData = this.state.userData;
		userData[name] = value;
		
		if (name == 'countryId' && ((value != null) && (value != undefined) && (value != 0)) ) {
			getAPI("common/states/"+this.state.userData.countryId)
			.then(res =>{
				this.setState({
					states: res['data'],
				})
			})
		}
		// console.log("savestate: ", userData)
		this.setState({
		  userData: userData,
		});
	}
	
	handleInputChangedSalesSection(event, name) {
		// console.log(event)
		var value = null;
		if (event instanceof moment) {
			value = event.valueOf();
		} else if (event.target != undefined) {
			const target = event.target;
			value = target.type === 'checkbox' ? target.checked : target.value;
		} else {
			return;
		}
		
		var salesSectionData = this.state.salesSectionData;
		salesSectionData[name] = value;
		
		// console.log("salesSectionData: ", salesSectionData)
		this.setState({
		  salesSectionData: salesSectionData,
		});
		
	}

	handleSaveChangedAddress(dataIn, deleteFlag=false) {
		// Make post/delete requests
			
		if (deleteFlag) { // remove
			postAPI("customers/"+this.props.editCustomerId+"/shippingaddresses/"+dataIn["id"], {})
			.then(() => {
				Promise.all([
					getAPI("customers/"+this.props.editCustomerId+"/shippingaddresses/list"), // Update list of addresses
					getAPI("customers/"+this.props.editCustomerId+"/shippingaddresses") // Update address objects
				])
				.then(([list, objects]) => {
					this.setState({
						addressList: list["data"],
						addresses: objects["data"]
					})
				});
			});
		} else { // add/edit
			postAPI("customers/"+this.props.editCustomerId+"/shippingaddresses", dataIn)
			.then(() => {
				Promise.all([
					getAPI("customers/"+this.props.editCustomerId+"/shippingaddresses/list"), // Update list of addresses
					getAPI("customers/"+this.props.editCustomerId+"/shippingaddresses") // Update address objects
				])
				.then(([list, objects]) => {
					this.setState({
						addressList: list["data"],
						addresses: objects["data"]
					})
				});
			});
		}
	}
	
	handleSaveChangedSalesSection(dataIn, deleteFlag=false) {
		// Make post/delete requests
			
		if (deleteFlag) { // remove
			
		} else { // add/edit
			postAPI("customers/" + this.props.editCustomerId + "/salesData", dataIn)
			.then(() => {
				Promise.all([
					getAPI("customers/" + this.props.editCustomerId + "/salesData") // Update Sales Section Data
				])
				.then(([salesSection]) => {
					this.setState({
						salesSectionData: salesSection["data"][0]
					})
				});
			});
		}
	}

	
	handleSaveChangedContract(dataIn, pdfFile = null, deleteFlag=false) {
			
		if (deleteFlag) { // remove
			postAPI("customers/"+this.props.editCustomerId+"/contracts/"+dataIn["id"], {})
			.then(() => {
				Promise.all([
					getAPI("customers/"+this.props.editCustomerId+"/contracts/list"), // Update list of addresses
					// getAPI("customers/"+this.props.editCustomerId+"/contracts") // Update address objects
				])
				.then(([list, objects]) => {
					this.setState({
						contractsList: list["data"],
						// contracts: objects["data"]
					})
				});
			});
		} else { // add/edit
			postAPI("customers/"+this.props.editCustomerId+"/contracts", dataIn)
			.then((res) => {
				console.log("res after saving customerContract: ", res);
				if(pdfFile != null) {
					const customerContractId = res['data'][0]["id"]; // dataIn["id"];
					postAPIMultipartForm("customers/" + this.props.editCustomerId + "/uploadContract/" + customerContractId, pdfFile).then((res) => {
						this.refreshContractsData();
					});
				} else {
					this.refreshContractsData();
				}
			});
		}
	}
	
	refreshContractsData() {
		Promise.all([
			getAPI("customers/"+this.props.editCustomerId+"/contracts/list"), // Update list of addresses
		])
		.then(([list, objects]) => {
			this.setState({
				contractsList: list["data"],
			})
		});
	}

	handleSaveChangedContact(dataIn, emailsToPush, emailsToPop, deleteFlag=false) {
		// Make post/delete requests
		if (deleteFlag) { // remove
			postAPI("customers/"+this.props.editCustomerId+"/contacts/"+dataIn["id"], {})
			.then(() => {
				Promise.all([
					getAPI("customers/"+this.props.editCustomerId+"/contacts/list"), // Update list of contacts
					getAPI("customers/"+this.props.editCustomerId+"/contacts") // Update contact objects
				])
				.then(([list, objects]) => {
					this.setState({
						contactList: list["data"],
						contacts: objects["data"]
					})
				});
			})
		} else { // add/edit
			postAPI("customers/"+this.props.editCustomerId+"/contacts", dataIn)
			.then((result) => {
				// console.log(result)
				var data = result.data[0];
				var addEmailPromises = [];
				if (emailsToPush.length > 0) {
					for (var topush of emailsToPush) {
						// console.log(topush);
						topush.customerContactId = data['id'];
						addEmailPromises.push(postAPI("customers/"+this.props.editCustomerId+"/contacts/"+data["id"]+"/emails", topush));			
					}
				}
				var deleteEmailPromises = []
				if (emailsToPop.length > 0) {
					for (var topop of emailsToPop) {
						// console.log(topop);
						deleteEmailPromises.push(postAPI("customers/"+this.props.editCustomerId+"/contacts/"+data["id"]+"/emails/"+topop["id"], {}));			
					}
				}
				Promise.all(addEmailPromises)
				.then(() => {
					Promise.all(deleteEmailPromises)
					.then(() => {
						Promise.all([
							getAPI("customers/"+this.props.editCustomerId+"/contacts/list"), // Update list of contacts
							getAPI("customers/"+this.props.editCustomerId+"/contacts") // Update contact objects
						])
						.then(([list, objects]) => {
							this.setState({
								contactList: list["data"],
								contacts: objects["data"]
							});
						});
					});
				});
			});
		}
	}

	refreshFormulaData() {
		var url = "customers/"+this.props.editCustomerId+"/formulas";
		getAPI(url)
		.then(response => {
			let activeFormulas = response["data"].filter(row => row.status != "Inactive");
			activeFormulas = activeFormulas.map(row => {
				row.displayName = row.customerFormulaName ? row.customerFormulaName : row.name;
				return row;
			});
			// console.log("activeFormulas: ", activeFormulas);
			this.setState({
				formulas: activeFormulas
			})
		})
	}

	refreshOrdersData() {
		var url = "customers/"+this.props.editCustomerId+"/orders";
		getAPI(url)
		.then(response => {
			this.setState({
				orders: response["data"]
			})
		})
	}
	
	shipmentOutlierChecked(checked, orderId) {
		let orders = this.state.orders;
		const rowIndex = orders.findIndex((row) => {
			return row.orderId == orderId;
		});
		// console.log("shipmentOutlierChecked() called - checked: ", checked, ", orderId: ", orderId, ", rowIndex: ", rowIndex);
		if(rowIndex > -1) {
			const bShipmentOutlier = checked ? 1 : 0;
			orders[rowIndex]['bShipmentOutlier'] = checked;
			this.setState({
				orders: orders
			});
			getAPI("salesorders/" + orderId + "/setShipmentOutlier/?bShipmentOutlier=" + bShipmentOutlier).then((result) => {
				
			});
			
		}
	}

	refreshPressData() {
		var url = "customers/"+this.props.editCustomerId+"/presses/list";
		getAPI(url)
		.then(response => {
			this.setState({
				presses: response["data"]
			})
		})
	}
	
	refreshTasksData() {
		var url = "tasks/search?customerId=" + this.props.editCustomerId;
		getAPI(url)
		.then(response => {
			this.setState({
				tasks: response["data"]
			})
		})
	}
	
	refreshCallReportsData() {
		var url = "callreports/search?customerId=" + this.props.editCustomerId;
		getAPI(url)
		.then(response => {
			this.setState({
				callReports: response["data"]
			})
		})
	}

	handleSaveChangedNote(dataIn, deleteFlag=false) {
		// Make post/delete requests
			
		if (deleteFlag) { // remove
			postAPI("customers/"+this.props.editCustomerId+"/notes/"+dataIn["id"], {})
			.then(() => {
				getAPI("customers/"+this.props.editCustomerId+"/notes")// Update list of notes
				.then(list => {
					this.setState({
						notes: list["data"]
					})
				});
			});
		} else { // add/edit
			postAPI("customers/"+this.props.editCustomerId+"/notes", dataIn)
			.then(() => {
				getAPI("customers/"+this.props.editCustomerId+"/notes")// Update list of notes
				.then(list => {
					this.setState({
						notes: list["data"]
					})
				});
			});
		}
	}

	render() {
		var states = {};
		for (const state of this.state.states) {
			states[state.id] = state.name;
		}

		const addressTab = (this.props.editCustomerId != null) ? 
			<AddressesTab
				addressList={this.state.addressList}
				addresses={this.state.addresses}
				handleSaveChangedAddress={this.handleSaveChangedAddress}
				editCustomerId={this.props.editCustomerId}
				states={states}
			/>
			:
			null;

		const contactsTab = (this.props.editCustomerId != null) ? 
			<ContactsTab
				editCustomerId={this.props.editCustomerId}
				handleSaveChangedContact={this.handleSaveChangedContact}
				contactList={this.state.contactList}
				contacts={this.state.contacts}
			/>
			:
			null;

		const formulasTab = (this.props.editCustomerId != null) ? 
			<FormulasTab
				editCustomerId={this.props.editCustomerId}
				formulas={this.state.formulas}
				refreshData={this.refreshFormulaData}
			/>
			:
			null;

		const ordersTab = (this.props.editCustomerId != null) ?
			<OrdersTab
				editCustomerId={this.props.editCustomerId}
				activeCustomers={this.state.activeCustomers}
				orders={this.state.orders}
				refreshData={this.refreshOrdersData}
				shipmentOutlierChecked={this.shipmentOutlierChecked}
			/>
			:
			null;
		
		const processNotesTab = (this.props.editCustomerId != null) ? 
			<ProcessNotesTab
				editCustomerId={this.props.editCustomerId}
				onSave={this.handleSaveChangedNote}
				notes={this.state.notes}
			/>
			:
			null;

		const pressesTab = (this.props.editCustomerId != null) ?
			<PressesTab
				editCustomerId={this.props.editCustomerId}
				presses={this.state.presses}
				refreshPressData={this.refreshPressData}
			/>
			:
			null;
		
		const tasksTab = (this.props.editCustomerId != null) ?
			<TasksTab
				editCustomerId={this.props.editCustomerId}
				tasks={this.state.tasks}
				refreshTasksData={this.refreshTasksData}
			/>
			:
			null;
		
		const callReportsTab = (this.props.editCustomerId != null) ?
			<CallReportsTab
				editCustomerId={this.props.editCustomerId}
				callReports={this.state.callReports}
				refreshCallReportsData={this.refreshCallReportsData}
			/>
			:
			null;

		const salesTab = (this.props.editCustomerId != null) ?
			<SalesTab
				editCustomerId={this.props.editCustomerId}
				salesSectionData={this.state.salesSectionData}
				salesSectionDataUpdate={this.handleInputChangedSalesSection}
				onSave={this.handleSaveChangedSalesSection}
			/>
			:
			null;
	    const contractsTab = (this.props.editCustomerId != null) ?
			<ContractsTab
				editCustomerId={this.props.editCustomerId}
				contractsList={this.state.contractsList}
				refreshContractsData={this.refreshContractsData}
				handleSaveChangedContract={this.handleSaveChangedContract}
			/>
			:
			null;

		const tabTitles =  (this.props.editCustomerId != null) ? 
			[
				<Translate value='components.customers.customerInfo'/>,
				<Translate value='components.customers.additionalAddresses'/>,
				<Translate value='components.customers.contacts'/>,
				<Translate value='components.customers.formulas'/>,
				<Translate value='components.customers.orders'/>,
				<Translate value='components.customers.processNotes'/>,
				<Translate value='components.customers.presses' />,
				<Translate value='components.tabs.tasks' />,
				<Translate value='components.tabs.callReports' />,
				<Translate value='components.tabs.salesSection' />,
				<Translate value='components.customers.contracts' />,
			] : [
				<Translate value='components.customers.customerInfo'/>
			];

		const deletePack =  ( this.props.editCustomerId != null) ? 
			this.state.showDeleteWarning ?
			<CustomerDeleteConfirmation visible={this.state.showDeleteWarning} onConfirm={this.onDelete} onClose={() => this.setState({showDeleteWarning:false})} />
				:
				<Button  disabled={this.state.userData.statusLookupId == 149 ?true : false} bsStyle='danger' onClick={() => this.setState({showDeleteWarning:true})}><Translate value='components.customers.deleteCustomer'/></Button>
			:
			null;

		return (
			<Modal show={this.props.visible} bsSize="large">
				<Modal.Header className='header'>
					<Modal.Title className='title'>{this.state.userData['name']}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TabContainer
						tabTitles={tabTitles}
						tabs={[
							<CustomerInfoTab 
								editCustomerId={this.props.editCustomerId}
								onSave={this.onSave}
								userDataUpdate={this.handleInputChangedUser}
								repsDataUpdate={this.handleInputChangedSalesReps}
								userData={this.state.userData}
								salesReps={this.state.salesReps}
								clientUsers={this.state.clientUsers}
								defaultWeights={this.state.defaultWeights}
								onSaveDefaultWeight={this.onSaveDefaultWeight}
								onDeleteDefaultWeight={this.onDeleteDefaultWeight}
								states={states}
							/>,
							addressTab,
							contactsTab,
							formulasTab,
							ordersTab,
							processNotesTab,
							pressesTab,
							tasksTab,
							callReportsTab,
							salesTab,
							contractsTab,
						]}
						defaultKey={this.props.defaultKey}
					/>
				</Modal.Body>
			<Modal.Footer>
				{deletePack}
				<Button onClick={this.props.onClose}><Translate value='components.customers.close'/></Button>
			</Modal.Footer>
			</Modal>
		);
	}
}

class CustomerInfoTab extends React.Component {
	constructor(props) {
		super(props);

		let customerInfoTabValidationFields = {
			name: "required",
			rfc: "",
			address1: "required",
			city: "required",
			countryId: "required",
			stateId: Config.locale.toLowerCase() == 'en_us' ? "required" : "",
			zipCode: "required",
			phoneNumber: "required",
			locale: "required",
			statusLookupId: "required",
			salesReps: "notEmpty",
			defaultShipperId: "required",
			defaultShipTime: "required,numeric",
			umCodeLookupId: "required",
			customerSinceDate: "required",
			illuminantId: "required",
			observerAngleId: "required",
			deltaETypeId: "required",
			deviceTypeLookupId: "required",
			colorTolerance: "required,numeric",
			setupReadsCount: "required,numeric",
			productionReadsCount: "required,numeric",
			targetReadsCount: "required,numeric",
			qcreadsCount: "required,numeric",
		}

		const errorsTemplate = resetObject(customerInfoTabValidationFields)
		this.state = {
			errors: errorsTemplate,
			
			// Default Weight Data
			showDefaultWeightForm: false,
			editDefaultWeightRow: null,
			deletedDefaultWeights: [],
			selectedDefaultWeightRowIndex: null,
			bShowCustomerMatches: false,
			arrCustomerMatches: [],
		}
		this.validate = this.validate.bind(this);
		this.customerInfoTabValidationFields = customerInfoTabValidationFields;

		// Default Weights functions
		this.addDefaultWeight = this.addDefaultWeight.bind(this);
		this.editDefaultWeight = this.editDefaultWeight.bind(this);
		this.defaultWeightRowSelected = this.defaultWeightRowSelected.bind(this);
		this.saveDefaultWeight = this.saveDefaultWeight.bind(this);
		this.removeDefaultWeight = this.removeDefaultWeight.bind(this);

	}

	// Default Weights functions
	addDefaultWeight(event) {
		event.preventDefault();
		
		this.setState({
			editDefaultWeightRow: null,
			selectedDefaultWeightRowIndex: null,
			showDefaultWeightForm: true,
		});
	}
	
	defaultWeightRowSelected(rowIndex) {
		this.setState({
			selectedDefaultWeightRowIndex: rowIndex,
		});
	}

	editDefaultWeight(editRow) {
		this.setState({
			editDefaultWeightRow: editRow,
			showDefaultWeightForm: true,
		});
	}
	
	
	removeDefaultWeight(event) {
		event.preventDefault();
		const selectedDefaultWeightRowIndex = this.state.selectedDefaultWeightRowIndex;
		// console.log("selectedDefaultWeightRowIndex: ", selectedDefaultWeightRowIndex);
		if(selectedDefaultWeightRowIndex != null) {
			// console.log("this.props.defaultWeights[]: ", this.props.defaultWeights[selectedDefaultWeightRowIndex]);
			this.props.onDeleteDefaultWeight(this.props.defaultWeights[selectedDefaultWeightRowIndex]['id']);
		}
	}
	
	saveDefaultWeight(defaultWeight) {
		this.props.onSaveDefaultWeight(defaultWeight);
		this.setState({
			showDefaultWeightForm: false,
		});
	}

	
	validate() {
		// Validate Form
		let validation = {};
		var testData = this.props.userData
		var customerInfoTabValidationFields = this.customerInfoTabValidationFields;
		testData['salesReps'] = this.props.salesReps
		if (!(this.props.userData['locale'] == null)) {
			customerInfoTabValidationFields['rfc'] = this.props.userData['locale'].toLowerCase() == 'es_mx' ? "required" : "";
		}
		// console.log("validate() - this.props.userData: ", this.props.userData);
		if(this.props.userData.countryId == 1) {
			customerInfoTabValidationFields.stateId = Config.locale.toLowerCase() == 'en_us' ? "required" : "";
			customerInfoTabValidationFields.zipCode = Config.locale.toLowerCase() == 'en_us' ? "required" : "";
		} else {
			customerInfoTabValidationFields.stateId = "";
			customerInfoTabValidationFields.zipCode = "";
		}
		
		validation = validateForm(this.props.userData, customerInfoTabValidationFields);
		// console.log("this.props.userData: ", this.props.userData, ", validation: ", validation);
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			console.log("this.props.userData['id']: ", this.props.userData['id']);
			if(this.props.userData['id'] == null) {
				// Get customer Matches
				getAPI("customers/matchByName?customerName=" + this.props.userData["name"]).then(matches => {
					this.setState({
						arrCustomerMatches: matches['data']
					});
					if(matches['data'].length == 0) {
						this.props.onSave();
					}
					else {
						this.setState({
							bShowCustomerMatches: true,
						});
					}
				});
			} else {
				this.props.onSave();
			}
		}
	}

	render() {
		var selectedReps = [];
		this.props.salesReps.forEach(element => {
			selectedReps.push(
				{
					value: element.actorId, label: element.displayName
				}
			)
		});
		var rfcInput = this.props.userData['locale'] == 'ES_MX' ? 
			<InputText
				required  
				label="RFC" 
				name="rfc" 
				value={this.props.userData['rfc']} 
				action={this.props.userDataUpdate}
				errMsg={this.state.errors.rfc}
			/>
			: 
			null;

		var customerDefaultWeightForm = this.state.showDefaultWeightForm ? 
		(<CustomerDefaultWeightForm
			onClose={() => this.setState({showDefaultWeightForm: false})}
			onSave={this.saveDefaultWeight}
			visible={this.state.showDefaultWeightForm}
			editDefaultWeightRow={this.state.editDefaultWeightRow}
			customerId={this.props.editCustomerId}
			/>) 
			: '';
			
		var customerMatches = this.state.bShowCustomerMatches ? 
		(<Modal show={this.state.bShowCustomerMatches}>
			<Modal.Body><Translate value='components.customers.matchesFound'/>
				<ul>
				{this.state.arrCustomerMatches.map(row => {
					return <li>{row['name']}</li>;
				})}
				</ul>
			</Modal.Body>
			<Modal.Footer>
				<Button  bsStyle='danger' onClick={() => {
					this.props.onSave();
					this.setState({bShowCustomerMatches: false, arrCustomerMatches: []});
				}}><Translate value='components.customers.saveAnyway'/>
				</Button>
				<Button onClick={() => {
					this.setState({bShowCustomerMatches: false, arrCustomerMatches: []});
				}}>
					<Translate value='components.customers.cancel'/>
				</Button>
			</Modal.Footer>
		</Modal>) : '';
		
		// console.log("countrieslist: ", countrieslist, ", viscosityCupsList: ", viscosityCupsList);
		return (
			<Form class='form-page' horizontal>
				<Row>
					<Col md={6}>
						<h4>{<Translate value='components.customers.mainAddress'/>}</h4>
						<InputText 
							required 
							label={<Translate value='components.customers.customerName'/>} 
							name="name" 
							value={this.props.userData['name']} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.name}
						/>
						<InputText  
							type='CheckBox' 
							label={<Translate value='components.customers.isCorporate'/>} 
							id='bCorporate' 
							name='bCorporate' 
							value={this.props.userData["bCorporate"] != null && this.props.userData["bCorporate"] == true}  
							action={this.props.userDataUpdate}
							// Don't think the code is set up to handle boolean values properly. But because a default value is set, it cannot ever be null
						/>
						{this.props.userData["bCorporate"] != true && 
						<DataDropdown 
							required 
							label={<Translate value='components.customers.parentCorporate'/>} 
							name="parentCorporateId" 
							data={corporatesList} 
							value={this.props.userData['parentCorporateId']} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.parentCorporateId}
						/>
						}
						{rfcInput}
						<InputText 
							required 
							label={<Translate value='components.customers.address1'/>} 
							name='address1' 
							value={this.props.userData['address1']} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.address1}
						/>
						<InputText 
							label={<Translate value='components.customers.address2'/>} 
							name='address2' 
							value={this.props.userData['address2']} 
							action={this.props.userDataUpdate}
						/>
						<InputText 
							required 
							label={<Translate value='components.customers.city'/>} 
							name='city' 
							value={this.props.userData['city']} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.city}
						/>
						<DataDropdown 
							required 
							label={<Translate value='components.customers.country'/>} 
							name="countryId" 
							data={countrieslist} 
							value={this.props.userData['countryId']} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.countryId}
						/>
						<DataDropdown 
							required={this.customerInfoTabValidationFields.stateId == 'required'}
							label={<Translate value='components.customers.stateProvince'/>} 
							name="stateId" 
							data={this.props.states} 
							value={this.props.userData['stateId']} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.stateId}
							// err message not needed due to nature of state sometimes needing to be empty 
						/>
						<InputText 
							required 
							label={<Translate value='components.customers.zipCode'/>} 
							name='zipCode' 
							value={this.props.userData['zipCode']} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.zipCode}
						/>
						<InputText 
							required 
							label={<Translate value='components.customers.phone'/>} 
							name='phoneNumber' 
							value={this.props.userData['phoneNumber']} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.phoneNumber
							}
						/>
						<InputText 
							label={<Translate value='components.customers.fax'/>} 
							name='faxNumber' 
							value={this.props.userData['faxNumber']} 
							action={this.props.userDataUpdate}
						/>
						<DataDropdown 
							required 
							label="Locale"
							name="locale" 
							data={locales} 
							value={this.props.userData['locale']} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.locale}
						/>
					</Col>
					<Col md={6}>
						<h4>{<Translate value='components.customers.clientUsers'/>}</h4>
						<DataGrid
							id="client-users"
							columns={[
								<Translate value='components.customers.userName' />,
								<Translate value='components.customers.lastUsed' />,
								<Translate value='components.customers.loginCount' />,
							]}
							fields={['name', "lastLoginDate", "loginCount"]}
							formatColumns={{1:{'date': true}}} 
							data={this.props.clientUsers}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<h4>{<Translate value='components.customers.miscInfo'/>}</h4>
						<DataDropdown 
							required 
							label={<Translate value='components.customers.status'/>} 
							id='status' 
							name="statusLookupId" 
							value={this.props.userData["statusLookupId"]} 
							action={this.props.userDataUpdate} 
							data={statuses}
							errMsg={this.state.errors.statusLookupId}
						/>
						<InputText 
							required 
							label={<Translate value='components.customers.salesReps'/>} 
							id='salesReps' 
							name='salesReps' 
							type='MultiSelect' 
							data={repnames} 
							value={selectedReps} 
							action={this.props.repsDataUpdate}
							errMsg={this.state.errors.salesReps}
						/>
						<InputText 
							label={<Translate value='components.customers.salesGoal'/>} 
							id='salesGoal' 
							name='salesGoal' 
							value={this.props.userData["salesGoal"]} 
							action={this.props.userDataUpdate}
						/>
						<InputText  
							type='CheckBox' 
							label={<Translate value='components.customers.newCustomer'/>} 
							id='bNewCustomer' 
							name='bNewCustomer' 
							value={this.props.userData["bNewCustomer"] != null && this.props.userData["bNewCustomer"] == true}  
							action={this.props.userDataUpdate}
							// Don't think the code is set up to handle boolean values properly. But because a default value is set, it cannot ever be null
						/>
						<DataDropdown 
							required 
							label={<Translate value='components.customers.defaultShipper'/>} 
							id='defaultShipper' 
							name='defaultShipperId' 
							data={shippersList} 
							action={this.props.userDataUpdate} 
							value={this.props.userData["defaultShipperId"]}
							errMsg={this.state.errors.defaultShipperId}
						/>
						<InputText 
							required 
							label={<Translate value='components.customers.defaultShipTime'/>} 
							id='defaultShipTime' 
							name='defaultShipTime' 
							value={this.props.userData["defaultShipTime"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.defaultShipTime}
						/>
						<DataDropdown 
							required 
							label={<Translate value='components.customers.umCode'/>} 
							id='umCode' 
							name='umCodeLookupId' 
							data={umCodeList} 
							value={this.props.userData["umCodeLookupId"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.umCodeLookupId}
						/>
						<DataDropdown 
							label={<Translate value='components.customers.customerTerms'/>} 
							id='customerTerms' 
							name='customerTermsId' 
							data={customerTerms} 
							value={this.props.userData["customerTermsId"]} 
							action={this.props.userDataUpdate}
						/>
						<InputText 
							label={<Translate value='components.customers.discountPercent'/>} 
							id='discountPercent' 
							name='orderDiscountPercent'  
							value={this.props.userData["orderDiscountPercent"]} 
							action={this.props.userDataUpdate}
						/>
						<InputText 
							required 
							type='DateTime' 
							label={<Translate value='components.customers.customerSince'/>} 
							id='customerSince' 
							name='customerSinceDate' 
							value={this.props.userData["customerSinceDate"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.customerSinceDate}
						/>
						<InputText 
							required 
							type='CheckBox' 
							label={<Translate value='components.customers.printHmis'/>} 
							id='printHmis' 
							name='printHMISFlag' 
							value={this.props.userData["printHMISFlag"]}  
							action={this.props.userDataUpdate}
							// Don't think the code is set up to handle boolean values properly. But because a default value is set, it cannot ever be null
						/>
						<InputText 
							type='TextArea' 
							label={<Translate value='components.customers.notes'/>} 
							id='notes' 
							name='notes' 
							value={this.props.userData["notes"]} 
							action={this.props.userDataUpdate}
						/>
						<InputText 
							type='TextArea' 
							label={<Translate value='components.customers.lrNotes'/>} 
							id='lrnotes' 
							name='lrnotes' 
							value={this.props.userData["lrnotes"]} 
							action={this.props.userDataUpdate}
						/>
						<InputText  
							type='CheckBox' 
							label={<Translate value='components.customers.bFuelSurcharge'/>} 
							id='bfuelSurcharge' 
							name='bfuelSurcharge' 
							value={this.props.userData["bfuelSurcharge"] != null && this.props.userData["bfuelSurcharge"] == true}  
							action={this.props.userDataUpdate}
							// Don't think the code is set up to handle boolean values properly. But because a default value is set, it cannot ever be null
						/>
						<InputText  
							type='CheckBox' 
							label={<Translate value='components.customers.bFreightSurcharge'/>} 
							id='bfreightSurcharge' 
							name='bfreightSurcharge' 
							value={this.props.userData["bfreightSurcharge"] != null && this.props.userData["bfreightSurcharge"] == true}  
							action={this.props.userDataUpdate}
							// Don't think the code is set up to handle boolean values properly. But because a default value is set, it cannot ever be null
						/>
						<InputText  
							type='CheckBox' 
							label={<Translate value='components.customers.bPlasticDrums'/>} 
							id='bplasticDrums' 
							name='bplasticDrums' 
							value={this.props.userData["bplasticDrums"] != null && this.props.userData["bplasticDrums"] == true}  
							action={this.props.userDataUpdate}
							// Don't think the code is set up to handle boolean values properly. But because a default value is set, it cannot ever be null
						/>
						<InputText  
							type='CheckBox' 
							label={<Translate value='components.customers.bCustomizeBarcode'/>} 
							id='bcustomizeBarcode' 
							name='bcustomizeBarcode' 
							value={this.props.userData["bcustomizeBarcode"] != null && this.props.userData["bcustomizeBarcode"] == true}  
							action={this.props.userDataUpdate}
							// Don't think the code is set up to handle boolean values properly. But because a default value is set, it cannot ever be null
						/>
						<InputText  
							type='CheckBox' 
							label={<Translate value='components.customers.consignmentCustomer'/>} 
							id='bconsignment' 
							name='bconsignment' 
							value={this.props.userData["bconsignment"] != null && this.props.userData["bconsignment"] == true}  
							action={this.props.userDataUpdate}
							// Don't think the code is set up to handle boolean values properly. But because a default value is set, it cannot ever be null
						/>
						<InputText  
							type='CheckBox' 
							label={<Translate value='components.customers.autoPrintCofA'/>} 
							id='bAutoPrintCofA' 
							name='bAutoPrintCofA' 
							value={this.props.userData["bAutoPrintCofA"] != null && this.props.userData["bAutoPrintCofA"] == true}  
							action={this.props.userDataUpdate}
							// Don't think the code is set up to handle boolean values properly. But because a default value is set, it cannot ever be null
						/>
						<InputText  
							type='CheckBox' 
							label={<Translate value='components.customers.bSpectro1'/>} 
							id='bSpectro1' 
							name='bSpectro1' 
							value={this.props.userData["bSpectro1"] != null && this.props.userData["bSpectro1"] == true}  
							action={this.props.userDataUpdate}
							// Don't think the code is set up to handle boolean values properly. But because a default value is set, it cannot ever be null
						/>
						<InputText  
							type='CheckBox' 
							label={<Translate value='components.customers.Draw Downs with Order'/>} 
							id='bdrawDownsWithOrder' 
							name='bdrawDownsWithOrder' 
							value={this.props.userData["bdrawDownsWithOrder"] != null && this.props.userData["bdrawDownsWithOrder"] == true}  
							action={this.props.userDataUpdate}
							// Don't think the code is set up to handle boolean values properly. But because a default value is set, it cannot ever be null
						/>
						{config.env == "mex" && <InputText  
							type='CheckBox' 
							label={<Translate value='components.customers.bWetSample'/>} 
							id='bWetSample' 
							name='bWetSample' 
							value={this.props.userData["bWetSample"] != null && this.props.userData["bWetSample"] == true}  
							action={this.props.userDataUpdate}
						/>}
						<DataDropdown 
							label={<Translate value='components.customers.viscosityCupConversion' />} 
							data={viscosityCupsList}
							name="viscosityCupLookupId"
							value={this.props.userData["viscosityCupLookupId"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.viscosityCupLookupId}
						/>
						
					</Col>
					<Col md={6}>
						<h4>{<Translate value='components.customers.defaultMeasurementConditions'/>}</h4>
						<DataDropdown 
							required 
							label={<Translate value='components.customers.illuminant'/>} 
							name='illuminantId' 
							data={illuminantList} 
							value={this.props.userData["illuminantId"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.illuminantId}
						/>
						<DataDropdown 
							required 
							label={<Translate value='components.customers.observerAngle'/>} 
							name='observerAngleId' 
							data={observerAngleList} 
							value={this.props.userData["observerAngleId"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.observerAngleId}
						/>
						<DataDropdown 
							required 
							label={'ΔE'} 
							name='deltaETypeId' 
							data={deltaEs} 
							value={this.props.userData["deltaETypeId"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.deltaETypeId}
						/>
						<DataDropdown 
							required 
							label={<Translate value='components.customers.device'/>} 
							name='deviceTypeLookupId' 
							data={deviceList} 
							value={this.props.userData["deviceTypeLookupId"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.deviceTypeLookupId}
						/>
						<InputText 
							required 
							label={<Translate value='components.customers.colorTolerance'/>} 
							name='colorTolerance' 
							value={this.props.userData["colorTolerance"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.colorTolerance}
						/>
						<InputText 
							required 
							label={<Translate value='components.customers.setupReadCount'/>} 
							name='setupReadsCount' 
							value={this.props.userData["setupReadsCount"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.setupReadsCount}
						/>
						<InputText 
							required 
							label={<Translate value='components.customers.productionReadCount'/>} 
							name='productionReadsCount' 
							value={this.props.userData["productionReadsCount"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.productionReadsCount}
						/>
						<InputText 
							required 
							label={<Translate value='components.customers.targetReadCount'	/>} 
							name='targetReadsCount' 
							value={this.props.userData["targetReadsCount"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.targetReadsCount}
						/>
						<InputText 
							required 
							label={<Translate value='components.customers.qcReadCount'/>} 
							name='qcreadsCount' 
							value={this.props.userData["qcreadsCount"]} 
							action={this.props.userDataUpdate}
							errMsg={this.state.errors.qcreadsCount}
						/>
					</Col>
					<col></col>
					<Col md={6} >
						<h4>{<Translate value='components.customers.tierDiscounts'/>}</h4>

						<InputText 
						label={<Translate value="components.customers.tieredVolumeDiscount" />} 
						id={"bTieredVolumeDiscounts"} 
						name={"bTieredVolumeDiscounts"} 
						action={this.props.userDataUpdate}  
						type={"CheckBox"} 
						value={this.props.userData["bTieredVolumeDiscounts"]}
					/>
						
						{this.props.userData["bTieredVolumeDiscounts"] == true &&
						<label>{<Translate value='components.customers.tier1'/>}</label>}
						
						{this.props.userData["bTieredVolumeDiscounts"] == true && <InputText  
							label={<Translate value='components.customers.tier1Discount'/>} 
							id='tier1Discount' 
							name='tier1Discount' 
							value={this.props.userData["tier1Discount"]} 
							action={this.props.userDataUpdate}
						/> } 

                        {this.props.userData["bTieredVolumeDiscounts"] == true &&<InputText  
							label={<Translate value='components.customers.tier1StartVolume'/>} 
							id='tier1StartVolume' 
							name='tier1StartVolume' 
							value={this.props.userData["tier1StartVolume"]} 
							action={this.props.userDataUpdate}
						/>}

                        {this.props.userData["bTieredVolumeDiscounts"] == true && <InputText  
							label={<Translate value='components.customers.tier1EndVolume'/>} 
							id='tier1EndVolume' 
							name='tier1EndVolume' 
							value={this.props.userData["tier1EndVolume"]} 
							action={this.props.userDataUpdate}
						/>}

                         {this.props.userData["bTieredVolumeDiscounts"] == true &&
                          <label>{<Translate value='components.customers.tier2'/>}</label>}
						
						{this.props.userData["bTieredVolumeDiscounts"] == true && <InputText  
							label={<Translate value='components.customers.tier2Discount'/>} 
							id='tier2Discount' 
							name='tier2Discount' 
							value={this.props.userData["tier2Discount"]} 
							action={this.props.userDataUpdate}
						/> } 

                        {this.props.userData["bTieredVolumeDiscounts"] == true && <InputText  
							label={<Translate value='components.customers.tier2StartVolume'/>} 
							id='tier2StartVolume' 
							name='tier2StartVolume' 
							value={this.props.userData["tier2StartVolume"]} 
							action={this.props.userDataUpdate}
						/> }

                        {this.props.userData["bTieredVolumeDiscounts"] == true &&<InputText  
							label={<Translate value='components.customers.tier2EndVolume'/>} 
							id='tier2EndVolume' 
							name='tier2EndVolume' 
							value={this.props.userData["tier2EndVolume"]} 
							action={this.props.userDataUpdate}
						/>  }

                         {this.props.userData["bTieredVolumeDiscounts"] == true &&
                          <label>{<Translate value='components.customers.tier3'/>}</label>}
						
						{this.props.userData["bTieredVolumeDiscounts"] == true && <InputText  
							label={<Translate value='components.customers.tier3Discount'/>} 
							id='tier3Discount' 
							name='tier3Discount' 
							value={this.props.userData["tier3Discount"]} 
							action={this.props.userDataUpdate}
						/>  }

                        {this.props.userData["bTieredVolumeDiscounts"] == true && <InputText  
							label={<Translate value='components.customers.tier3StartVolume'/>} 
							id='tier3StartVolume' 
							name='tier3StartVolume' 
							value={this.props.userData["tier3StartVolume"]} 
							action={this.props.userDataUpdate}
						/>}

                        {this.props.userData["bTieredVolumeDiscounts"] == true &&<InputText  
							label={<Translate value='components.customers.tier3EndVolume'/>} 
							id='tier3EndVolume' 
							name='tier3EndVolume' 
							value={this.props.userData["tier3EndVolume"]} 
							action={this.props.userDataUpdate}
						/>}
						 

					</Col>	

					<col></col>
					<Col md={6} >
						<h4>{<Translate value='components.customers.defaultPrints'/>}</h4>

						
						
						<InputText  
							label={<Translate value='components.customers.drawDownsNeeded'/>} 
							id='drawDownsNeeded' 
							name='drawDownsNeeded' 
							value={this.props.userData["drawDownsNeeded"]} 
							action={this.props.userDataUpdate}
						/> 

                        <InputText  
							label={<Translate value='components.customers.drawDownsSent'/>} 
							id='drawDownsSent' 
							name='drawDownsSent' 
							value={this.props.userData["drawDownsSent"]} 
							action={this.props.userDataUpdate}
						/> 
						
                        <InputText  
							label={<Translate value='components.customers.lsdNeeded'/>} 
							id='lsdNeeded' 
							name='lsdNeeded' 
							value={this.props.userData["lsdNeeded"]} 
							action={this.props.userDataUpdate}
						/> 

                        <InputText  
							label={<Translate value='components.customers.lsdSent'/>} 
							id='lsdSent' 
							name='lsdSent' 
							value={this.props.userData["lsdSent"]} 
							action={this.props.userDataUpdate}
						/> 

						<h4>{<Translate value='components.customers.defaultWeights'/>}</h4>
						<DataGrid
							columns={["Container", "Weight"]}
							fields={["containerName", "weight"]}
							data={this.props.defaultWeights}
							onDoubleClick={this.editDefaultWeight} 
							onSelect={this.defaultWeightRowSelected}
							selectedRowIndex={this.state.selectedComponentRowIndex}
							heightOffset={350}
						/>
						<Button onClick={this.removeDefaultWeight}><Translate value='components.buttons.delete' /></Button>&nbsp;
						<Button onClick={this.addDefaultWeight}>Add</Button>
						{customerDefaultWeightForm}
						{customerMatches}
					</Col>
					

				</Row>
				<Button onClick={this.validate} bsStyle="primary"><Translate value='components.customers.saveChanges'/></Button>
			</Form>
		);
	}
}

class CustomerDefaultWeightForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "Weight",
			formData: {
				containerId:0,
				containerName: '',
				customerId: this.props.customerId,
				id:0,
				model:"com.phototype.myinkiq.model.vos.dtos::CustomerWeightExceptionsDto",
				name:null,
				weight:0,
				retired:false,
			},
			component: null, // component selected {value: 1, label: 'abc'}
			// componentsList: [],
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	
	componentDidMount() {
		/////////////// CHECKING FOR EDIT MODE //////////////////
		if(this.props.editDefaultWeightRow) { // If this is not null
			const editDefaultWeightRow = this.props.editDefaultWeightRow;
			this.setState({
				formData: editDefaultWeightRow,
				title: "Weight: " + editDefaultWeightRow['weight'],
			});
		}
	}

	handleInputChanged(event, name) {
		var formData = this.state.formData;
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;		
		
		switch(name) {
			
			case 'containerId':
				let containerName = value != "" ? containersList.filter(row => row.id == value)[0]['name'] : "";
				formData['containerName'] = containerName;
				break;
		}
		
		formData[name] = value;

		this.setState({
		  formData: formData,
		});
	
		
	}
	
	onSave(event) {
		event.preventDefault();
		var formulaCustomerPricing = this.state.formData;
		this.props.onSave(formulaCustomerPricing);
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		const objContainers = mapArrayToObject(containersList, 'id', 'name');
		
		const bSalesPerson = loggedInUser.userTypeId == constants.sales_type_id 
		|| loggedInUser.userTypeId == constants.customer_type_id;
		
		return(
			<Modal show={this.props.visible} bsSize="medium">
				<Modal.Header>
					<Modal.Title>{this.state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className='form-page' horizontal>
						<Row>
							<Col md={12}>
								<DataDropdown label={<Translate value="components.labels.container" />} required id={"containerId"} name={"containerId"} data={objContainers} value={this.state.formData.containerId} action={this.handleInputChanged} />
								<InputText label={<Translate value="components.labels.weight" />} id={"weight"} name={"weight"} value={this.state.formData.weight} action={this.handleInputChanged} />
							</Col>
						</Row>
						<br />
						<br />
						<br />
						<br />
						<br />
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={this.onSave} bsStyle="primary" disabled={loggedInUser.accessFormulasCode == 'V' || bSalesPerson}><Translate value="components.buttons.saveChanges" /></Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

class AddressesTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			states: [],
			columns: 
			[
				<Translate value='components.customers.address1' />,
				<Translate value='components.customers.city' />,
				<Translate value='components.customers.stateProvince' />,
				<Translate value='components.customers.zipCode' />,
				<Translate value='components.customers.country' />,
				<Translate value='components.customers.phoneNumber' />,
				<Translate value='components.customers.billingAddress' />
			],
			fields: ['address1', 'city', 'stateName', 'zipCode', 'countryName', 'phoneNumber', 'billingAddress'],
			newAddressFormVisible: false,
			editAddressFormVisible: false,
			tempAddressData: {
				"id": null,
				"customerId": this.props.editCustomerId, // Be sure not to leve populated when removing addresses
				"addressId": null,
				"address1": null,
				"address2": null,
				"city": null,
				"stateId": null,
				"countryId": null,
				"phoneNumber": null,
				"faxNumber": null,
				"zipCode": null,
				"billingAddressFlag": false,
				"retired": false,
				"stateCode": null
			},
			isLoaded: false, // Not sure if we need this
		};
		this.showNewAddressForm = this.showNewAddressForm.bind(this);
		this.showEditAddressForm = this.showEditAddressForm.bind(this);
		this.handleInputChangedTempAddress = this.handleInputChangedTempAddress.bind(this);
		this.handleSaveAddressData = this.handleSaveAddressData.bind(this);
		this.updateStateList = this.updateStateList.bind(this);
	}

	componentDidMount() {
		
	}

	updateStateList(countryId) {
		if((countryId != undefined) && (countryId != null) && (countryId != 0)){
			getAPI("common/states/"+countryId)
			.then(res =>{
				this.setState({
					states: res['data'],
				})
			})
		}
	}

	resetTempAddress() {
		this.setState({
			tempAddressData: {
				"id": null,
				"customerId": this.props.editCustomerId, // Be sure not to leve populated when removing addresses
				"addressId": null,
				"address1": null,
				"address2": null,
				"city": null,
				"stateId": null,
				"countryId": null,
				"phoneNumber": null,
				"faxNumber": null,
				"zipCode": null,
				"billingAddressFlag": false,
				"retired": false,
				"stateCode": null
			}
		});
	}

	handleInputChangedTempAddress(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		
		var addressData = this.state.tempAddressData;
		addressData[name] = value;

		if (name == 'countryId') {
			this.updateStateList(value)
		}
		
		this.setState({
		  tempAddressData: addressData,
		});

		// console.log(this.state)
		// console.log(this.state, this.props)
	}

	showNewAddressForm() {
		this.resetTempAddress()
		this.setState({
			newAddressFormVisible: true,
			editAddressFormVisible: false,
		});
	}

	showEditAddressForm(dataIn) {
		var addressId = this.props.addressList[dataIn]["id"];
		if((addressId != undefined) && (addressId != null) && (addressId != 0)){
			for (const address of this.props.addresses) {
				if (addressId === address.id){
					var countryId = address["countryId"];
					if((countryId != undefined) && (countryId != null) && (countryId != 0)){
						getAPI("common/states/"+countryId)
						.then(res =>{
							this.setState({
								tempAddressData: address,
								states: res['data'],
								editAddressFormVisible: true,
								newAddressFormVisible: false,
							})
						})
					}
					break;
				}
			}
		}
	}

	handleSaveAddressData(event, deleteFlag=false) {
		this.props.handleSaveChangedAddress(this.state.tempAddressData, deleteFlag)
		this.setState({
			editAddressFormVisible: false,
			newAddressFormVisible: false,
		})
		this.resetTempAddress()
	}

	render() {
		var states = {};
		for (const state of this.state.states) {
			states[state.id] = state.name;
		}
		var hasBillingAddress = false;
		for (const address of this.props.addressList) {
			if (address.billingAddressFlag) {
				hasBillingAddress = true;
			}
		}
		var billingAddressId = null;
		for (const address of this.props.addressList) {
			if (address.billingAddressFlag) {
				billingAddressId = address.id;
			}
		}
		var addressForm = this.state.newAddressFormVisible ? 
			<AddressForm
				isEditForm={false}
				handleInputChangedTempAddress={this.handleInputChangedTempAddress}
				tempAddressData={this.state.tempAddressData}
				states={states}
				hasBillingAddress={hasBillingAddress}
				billingAddressId={billingAddressId}
				close={() => this.setState({newAddressFormVisible: false})}
				onSave={this.handleSaveAddressData}
			/>
			:
			this.state.editAddressFormVisible ? 
			<AddressForm
				isEditForm={true}
				handleInputChangedTempAddress={this.handleInputChangedTempAddress}
				states={states}
				tempAddressData={this.state.tempAddressData}
				hasBillingAddress={hasBillingAddress}
				billingAddressId={billingAddressId}
				close={() => this.setState({editAddressFormVisible: false})}
				onSave={this.handleSaveAddressData}
			/>
			: 
			<Button onClick={this.showNewAddressForm} bsStyle="primary">{<Translate value='components.customers.addAddress'/>}</Button>;
		// console.log(states)
		return(<Form className='form-page' horizontal>
			<h4>{<Translate value='components.customers.addresses'/>}</h4>
			<DataGrid
				columns={this.state.columns}
				onSelect={this.showEditAddressForm}
				fields={this.state.fields}
				data={this.props.addressList}
				formatColumns=
				{{
					6: {'checkbox': true,
						'disabled': true,
						'colCheckBound': 'billingAddressFlag'
					},
				}}
			/>
			<Row>
				{addressForm}
			</Row>
		</Form>);
	}
}

const addressesFormValidationFields = {
	address1: "required",
	city: "required",
	countryId: "required",
	phoneNumber: "required",
}
class AddressForm extends Component {
	constructor(props) {
		super(props);

		const errorsTemplate = resetObject(addressesFormValidationFields)
		this.state = { 
			errors: errorsTemplate
		}
		this.onDelete = this.onDelete.bind(this);
		this.validate = this.validate.bind(this);
	}

	componentDidMount() {
		// console.log('test')
	}

	onDelete(event) {
		this.props.onSave(event, true)
	}

	validate(event) {
		// Validate Form
		let validation = {};
		validation = validateForm(this.props.tempAddressData, addressesFormValidationFields);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			this.props.onSave(event);
		}
	}

	render() {
		const deleteButton = this.props.isEditForm ? 
			<Button bsStyle="danger" onClick={this.onDelete}>{<Translate value='components.customers.deleteAddress'/>}</Button>
			:
			null;

		var title = this.props.isEditForm ? 
			<Translate value='components.customers.editAddress'/>
			:
			 <Translate value='components.customers.addNewAddress'/>;

		var billingCheckboxDisabled = this.props.hasBillingAddress && this.props.billingAddressId != this.props.tempAddressData['id'];
		
		return(
			<Col md={6}>
				<h4>{title}</h4>
				<InputText 
					required 
					label={<Translate value='components.customers.address1'/>} 
					value={this.props.tempAddressData['address1']} 
					name='address1'
					action={this.props.handleInputChangedTempAddress}
					errMsg={this.state.errors.address1}
				/>
				<InputText 
					label={<Translate value='components.customers.address2'/>} 
					value={this.props.tempAddressData['address2']}
					name='address2' 
					action={this.props.handleInputChangedTempAddress}
				/>
				<InputText 
					required 
					label={<Translate value='components.customers.city'/>} 
					value={this.props.tempAddressData['city']}
					name='city'
					action={this.props.handleInputChangedTempAddress}
					errMsg={this.state.errors.city}
				/>
				<DataDropdown 
					required 
					value={this.props.tempAddressData['countryId']}
					name='countryId'
					data={countrieslist}
					label={<Translate value='components.customers.country'/>} 
					action={this.props.handleInputChangedTempAddress}
					errMsg={this.state.errors.countryId}
				/>
				<DataDropdown 
					required 
					value={this.props.tempAddressData['stateId']}
					name='stateId'
					data={this.props.states}
					label={<Translate value='components.customers.stateProvince'/>} 
					action={this.props.handleInputChangedTempAddress}
				/>
				<InputText 
					label={<Translate value='components.customers.zipCode'/>} 
					value={this.props.tempAddressData['zipCode']}
					name='zipCode' 
					action={this.props.handleInputChangedTempAddress}
				/>
				<InputText 
					required 
					value={this.props.tempAddressData['phoneNumber']}
					label={<Translate value='components.customers.phone'/>} 
					name='phoneNumber' 
					action={this.props.handleInputChangedTempAddress}
					errMsg={this.state.errors.phoneNumber}
				/>
				<InputText 
					label={<Translate value='components.customers.fax'/>} 
					value={this.props.tempAddressData['faxNumber']}
					name='faxNumber' 
					action={this.props.handleInputChangedTempAddress}
				/>
				<InputText 
					required
					disabled={billingCheckboxDisabled}
					type='CheckBox' 
					label={<Translate value='components.customers.billingAddress'/>}
					id='billingAddressFlag'
					name='billingAddressFlag'
					value={this.props.tempAddressData["billingAddressFlag"]}
					action={this.props.handleInputChangedTempAddress}
				/>
				<Row>
					<Col md={3}>
						{deleteButton}
					</Col>
					<Col md={3}>
						<Button onClick={this.props.close}>{<Translate value='components.customers.close'/>}</Button>
					</Col>
					<Col md={3}>
						<Button bsStyle="primary" onClick={this.validate}>{<Translate value='components.customers.saveAddress'/>}</Button>
					</Col>
				</Row>
			</Col>
		)
	}
}

class ContactsTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: 
			[
				<Translate value='components.customers.name'/>,
				<Translate value='components.customers.type'/>,
				<Translate value='components.customers.email'/>,
				<Translate value='components.customers.phoneNumber'/>,
				<Translate value='components.customers.mobileNumber'/>
			],
			fields: ['name', 'type', 'emailAddress', 'phoneNumber', 'mobileNumber'],
			newContactFormVisible: false,
			editContactFormVisible: false,
			tempContactData: 
			{
				"id":null,
				"name":"",
				"emailAddress":"",
				"phoneNumber":"",
				"mobileNumber":"",
				"typeLookupId":null,
				"retired":false,
				"spanishFlag":(config.locale == 'ES_MX')
			},
			contactEmails:[],
			emailsToPop:[],
			emailsToPush:[],
			contactEmailStuff: 
			{
				"id":null,
				"name":null,
				"customerContactId":null,
				"emailTemplateId":null
			},
			isLoaded: false, // Not sure if we need this
		};
		this.showNewContactForm = this.showNewContactForm.bind(this);
		this.showEditContactForm = this.showEditContactForm.bind(this);
		this.handleInputChangedTempContact = this.handleInputChangedTempContact.bind(this);
		this.handleSaveContactData = this.handleSaveContactData.bind(this);
		this.handleInputChangedContactEmails = this.handleInputChangedContactEmails.bind(this);
		// this. = this..bind(this);
		// this. = this..bind(this);
		
	}

	componentDidMount() {

	}

	resetTempContact() {
		this.setState({
			tempContactData: 
			{
				"id":null,
				"name":"",
				"emailAddress":"",
				"phoneNumber":"",
				"mobileNumber":"",
				"typeLookupId":null,
				"retired":false,
				"spanishFlag":(config.locale == 'ES_MX')
			},
			contactEmailStuff: 
			{
				"id":null,
				"name":null,
				"customerContactId":null,
				"emailTemplateId":null
			},
			contactEmails: [],
			emailsToPop:[],
			emailsToPush:[],
		});
	}
	resetContactEmailStuff() {
		this.setState({
			contactEmailStuff: blankContactEmailStuff
		})
	}

	handleInputChangedContactEmails(event, name) {
		var contactEmails = this.state.contactEmails;
		var emailsToPop = this.state.emailsToPop;
		var emailsToPush = this.state.emailsToPush;
		// console.log(event)
		// Because of the nature of these updates, they will only ever add or remove one value at a time...
		// add value
		if (event.length > contactEmails.length) {
			// console.log("Adding")
			for (var fresh of event) {
				var flag = false;
				for (var stale of contactEmails) {
					if (fresh.value === stale.emailTemplateId) {
						flag = true;
						break;
					}
				}
				if (flag === false) { // not in state data so add it
					var newEmail = blankContactEmailStuff();
					newEmail.emailTemplateId = fresh.value
					newEmail.category = fresh.label
					// Check to see if email is in pop list
					var i = 0;
					for (var test of emailsToPop) {
						// console.log(test, newEmail)
						if (newEmail.emailTemplateId === test.emailTemplateId) {
							var old = emailsToPop.splice(i,1)[0];
							newEmail = old;
							this.setState({
								emailsToPop: emailsToPop
							})
							flag = true; //set to know if it needs to be added to the push list or not
							break;
						}
						i += 1
					}
					if (!flag) { // it was not in the pop list so it needs to be added database side
						emailsToPush.push(newEmail)
						this.setState({
							emailsToPush: emailsToPush
						})
					}
					contactEmails.push(newEmail)
					this.setState({
						contactEmails: contactEmails
					})
					break;
				}
			};
		} else {// remove value
			// console.log("Removing")
			for (var stale of this.state.contactEmails) {
				// console.log(stale, event, this.state)
				var flag = false;
				for (var fresh of event) {
					if (fresh.value === stale.emailTemplateId) {
						flag = true;
						break;
					}
				}
				if (flag === false) { // not in event data so remove it
					// Check to see if email is in push list
					var j = 0;
					for (var test of emailsToPush) {
						if (stale.emailTemplateId === test.emailTemplateId) {
							emailsToPush.splice(j,1)
							this.setState({
								emailsToPush: emailsToPush
							})
							flag = true; //set to know if it needs to be added to the pop list or not
							break;
						}
						j += 1
					}
					if (!flag) { // it was not in the pop list so it needs to be removed database side
						emailsToPop.push(stale)
						this.setState({
							emailsToPop: emailsToPop
						})
					}
					var i = contactEmails.findIndex(x => x===stale)
					contactEmails.splice(i,1)
					this.setState({
						contactEmails: contactEmails
					})
					break;
				}
			};
		}
	}

	handleInputChangedTempContact(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		
		var contactData = this.state.tempContactData;
		contactData[name] = value;
		
		this.setState({
		  tempContactData: contactData,
		});

		// console.log(this.state)
		// console.log(this.state, this.props)
	}

	showNewContactForm() {
		this.resetTempContact()
		this.setState({
			newContactFormVisible: true,
			editContactFormVisible: false,
		});
	}

	showEditContactForm(dataIn) {
		// console.log(dataIn, this.props.contactList[dataIn]["id"], this.props)
		var contactId = this.props.contactList[dataIn]["id"];
		if((contactId != undefined) && (contactId != null) && (contactId != 0)){
			for (const contact of this.props.contacts) {
				if (contactId === contact.id){
					getAPI("customers/"+this.props.editCustomerId+"/contacts/"+contactId+"/emails")
					.then( emails => {
						this.setState({
							tempContactData: contact,
							contactEmails: emails["data"],
							editContactFormVisible: true,
							newContactFormVisible: false,
						})
					})
					break;
				}
			}
		}
	}

	handleSaveContactData(event, deleteFlag=false) {
		var tempContactData = this.state.tempContactData;
		tempContactData["customerId"] = this.props.editCustomerId;
		this.props.handleSaveChangedContact(tempContactData, this.state.emailsToPush, this.state.emailsToPop, deleteFlag)
		this.setState({
			editContactFormVisible: false,
			newContactFormVisible: false,
		})
		this.resetTempContact()
	}

	render() {
		var selectedEmails = [];
		// console.log("this.state.contactEmails: ", this.state.contactEmails, ", emailTemplateList: ", emailTemplateList);
		for (const email of this.state.contactEmails) {
			var test = emailTemplateList.filter(disp => disp.value == email.emailTemplateId);
			if(test[0]) {
				selectedEmails.push(
					{value: email.emailTemplateId, label: test[0].label}
				);
			}
			/*
			// var test = emailTemplateList.filter(disp => disp.value == email.emailTemplateId);
			var test = emailTemplateList.filter(disp => disp.label == email.name);
			if(test[0]) {
				selectedEmails.push(
					{value: email.emailTemplateId, label: test[0].label}
				)
			}*/
			
		}
		// console.log(emailTemplates, selectedEmails)

		var contactForm = this.state.newContactFormVisible ? 
			<ContactForm
				isEditForm={false}
				handleInputChangedTempContact={this.handleInputChangedTempContact}
				handleInputChangedContactEmails={this.handleInputChangedContactEmails}
				tempContactData={this.state.tempContactData}
				selectedEmails={selectedEmails}
				close={() => this.setState({newContactFormVisible: false})}
				onSave={this.handleSaveContactData}
			/>
			:
			this.state.editContactFormVisible ? 
			<ContactForm
				isEditForm={true}
				handleInputChangedTempContact={this.handleInputChangedTempContact}
				handleInputChangedContactEmails={this.handleInputChangedContactEmails}
				tempContactData={this.state.tempContactData}
				selectedEmails={selectedEmails}
				close={() => this.setState({editContactFormVisible: false})}
				onSave={this.handleSaveContactData}
			/>
			: 
			<Button onClick={this.showNewContactForm} bsStyle="primary">{<Translate value='components.customers.addContact'/>}</Button>;

		return(<Form className='form-page' horizontal>
			<h4><Translate value='components.customers.contacts'/></h4>
			<DataGrid
				columns={this.state.columns}
				fields={this.state.fields}
				onSelect={this.showEditContactForm}
				data={this.props.contactList}
			/>
			<Row>
				{contactForm}
			</Row>
		</Form>);
	}
}

var contactFormValidationFields = {
	//emailsRecived: "notEmpty",
	name: "required",
	phoneNumber: "required",
	typeLookupId: "required",
	phoneNumber: "required",
	emailAddress: "required",
}

class ContactForm extends Component {
	constructor(props) {
		super(props);
		const errorsTemplate = resetObject(contactFormValidationFields)
		this.state = { 
			errors: errorsTemplate
		}
		this.onDelete = this.onDelete.bind(this);
		this.validate = this.validate.bind(this);
	}

	onDelete(event) {
		this.props.onSave(event, true)
	}

	validate(event) {
		let validation = {};
		var toValidate = this.props.tempContactData;

		var validationFields = contactFormValidationFields;

		//toValidate['emailsRecived'] = this.props.selectedEmails;
		validation = validateForm(toValidate, validationFields);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result) {
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		} else {
			this.props.onSave(event);
		}
	}

	render() {

		const deleteButton = this.props.isEditForm ? 
			<Button bsStyle="danger" onClick={this.onDelete}>{<Translate value='components.customers.deleteContact'/>}</Button>
			:
			null;
		
		const emailsRecieved = 
			<InputText 
				//required 
				label={<Translate value='components.customers.emailsRecieved'/>}  
				name='emailsRecived' 
				type='MultiSelect' 
				data={emailTemplateList} 
				value={this.props.selectedEmails} 
				action={this.props.handleInputChangedContactEmails}
				errMsg={this.state.errors.emailsRecived}
			/>;

		var title = this.props.isEditForm ? <Translate value='components.customers.editContact'/>
		: <Translate value='components.customers.addNewContact'/>;
		return(
			<Col md={6}>
				<h4>{title}</h4>
				<InputText 
					required 
					label={<Translate value='components.customers.name'/>} 
					name="name"
					value={this.props.tempContactData['name']}
					action={this.props.handleInputChangedTempContact}
					errMsg={this.state.errors.name}
				/>
				<DataDropdown
					required
					label={<Translate value='components.customers.Position'/>}
					name='typeLookupId'
					data={contactTypeList}
					colValue={"id"}
					colText={"name"}
					value={this.props.tempContactData['typeLookupId']}
					action={this.props.handleInputChangedTempContact}
					errMsg={this.state.errors.typeLookupId}
				/>
				<InputText
					required
					label={<Translate value='components.customers.phoneNumber'/>}
					name='phoneNumber'
					value={this.props.tempContactData['phoneNumber']}
					action={this.props.handleInputChangedTempContact}
					errMsg={this.state.errors.phoneNumber}
				/>
				<InputText 
					label={<Translate value='components.customers.mobileNumber'/>}
					name='mobileNumber'
					value={this.props.tempContactData['mobileNumber']}
					action={this.props.handleInputChangedTempContact}
				/>
				<InputText 
					required
					label={<Translate value='components.customers.email'/>}
					name='emailAddress'
					value={this.props.tempContactData['emailAddress']}
					action={this.props.handleInputChangedTempContact}
					errMsg={this.state.errors.emailAddress}
				/>
				<InputText 
					label={<Translate value='components.customers.spanish'/>} 
					type='CheckBox' 
					name='spanishFlag'
					value={this.props.tempContactData['spanishFlag']}
					action={this.props.handleInputChangedTempContact}
				/>
				{emailsRecieved}
				<Row>
					<Col md={3}>
						{deleteButton}
					</Col>
					<Col md={3}>
						<Button onClick={this.props.close}>{<Translate value='components.customers.close'/>}</Button>
					</Col>
					<Col md={3}>
						<Button bsStyle="primary" onClick={this.validate}>{<Translate value='components.customers.saveContact'/>}</Button>
					</Col>
				</Row>
			</Col>
		)
	}
}

class FormulasTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns:
			[
				<Translate value='components.customers.formulaNumber'/>,
				<Translate value='components.customers.name'/>,
				<Translate value='components.customers.substrate'/>,
				<Translate value='components.customers.anilox'/>,
				<Translate value='components.customers.price'/>,
				<Translate value='components.customers.lastOrdered'/>,
			],
			fields: ['formula', 'displayName', 'substrate', 'anilox', 'price', 'lastOrderedDate'],
			isLoaded: false, // Not sure if we need this
			formulaFormVisible: false,
			editFormulaId: null,
			searchText: '',
			searchFormulaNumber: '',
		};
		this.showFormulaForm = this.showFormulaForm.bind(this);
		this.handleCloseFormulaForm = this.handleCloseFormulaForm.bind(this)
		this.handleSaveFormulaForm = this.handleSaveFormulaForm.bind(this)
	}

	showFormulaForm(dataIn) {
		// console.log(dataIn)
		this.setState({
			editFormulaId: dataIn['formulaId'],
			formulaFormVisible: true,
		});
	}

	handleCloseFormulaForm() {
		this.setState({formulaFormVisible: false});
	}

	handleSaveFormulaForm() {
		this.handleCloseFormulaForm();
		// Samee - this is temporary, do not refresh data so that the sort order does not get affected.
		// this.props.refreshData();
	}
	

	render() {
		var formulaForm = this.state.formulaFormVisible ?
			<NewFormulaForm
				onClose={this.handleCloseFormulaForm}
				onSave={this.handleSaveFormulaForm}
				visible={this.state.formulaFormVisible}
				editFormulaId={this.state.editFormulaId}
			/>
			:
			null;
		var specOutputModal = this.state.showSpecOutput ? 
		<Modal 
			show={this.state.showSpecOutput} 
			onHide={() => this.setState({showSpecOutput: false})}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<Translate value='components.reports.specSystemReport' />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className='form-page' horizontal>
					<InputText type='DateTime' 
						label={<Translate value='components.reports.exportSinceDate' />} 
						action={event => {
							const value = event._d ? 
								convertTimestampToDate(event._d, false, false, true) : event;
							// console.log("value: ", value);
							this.setState({specOutputDate: value});
						}}
					/>
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button bsStyle='primary' 
					onClick={() => this.setState({showSpecOutput: false})}
				>
					<Translate value='components.buttons.close' />
				</Button>
				<Button bsStyle='primary' 
					onClick={() => {
						const cid = this.props.editCustomerId;
						getAPI("formulas/export/speccustjson?fid=0&cid="
								+ cid + "&d="
								+ this.state.specOutputDate).then(resSpecOutput => {
							// console.log("resSpecOutput: ", resSpecOutput);
							if(resSpecOutput['data'].length == 0)
								alert("No Blending Formulas have been created since the specified date.");
							else
								saveSpecOutput(resSpecOutput['data']);
						});
					}}>
					<Translate value='components.buttons.export' />
				</Button>
			</Modal.Footer>
		</Modal> : "";
		return(
			<Form className='form-page' horizontal>
			<h4><Translate value='components.customers.formulas'/></h4>
			<Row>
				<Col md={11}>
					<div class="full-height">
						<DataGrid
							columns={this.state.columns}
							fields={this.state.fields}
							data={this.props.formulas}
							onDoubleClick={this.showFormulaForm}
							formatColumns=
							{{
								0: {'textFilter': 'formula'},
								1: {'textFilter': 'displayName'},
								4: {'currency': true},
								5: {'date': true},
							}}
							onTextFieldChanged={(fieldName, val) => {
								// console.log("called - fieldName: ", fieldName, ", val: ", val);
								if(fieldName == 'displayName')
									this.setState({searchText: val});
								if(fieldName == 'formula')
									this.setState({searchFormulaNumber: val});
							}}
						/>
					</div>
				</Col>
				<Col md={1}>
					<Row>
						<Button onClick={() => this.setState({showSpecOutput: true})}>
						<Translate value="components.reports.specOutput" />
						</Button>
					</Row>
					<Row>
						<Button onClick={() => {
							openBirtReport('BCM_Customer_Formula_List_Price',{customer: this.props.editCustomerId, search: this.state.searchText, searchFormulaNumber: this.state.searchFormulaNumber})
						}}>PDF</Button>
					</Row>
					<Row>
						<Button onClick={() => {
							openBirtReport('BCM_Customer_Formula_List_Price',{customer: this.props.editCustomerId, search: this.state.searchText, searchFormulaNumber: this.state.searchFormulaNumber}, 'xlsx')
						}}>XLS</Button>
					</Row>
				</Col>
			</Row>
			{formulaForm}
			{specOutputModal}
		</Form>);
	}
}

class OrdersTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: 
			[
				<Translate value='components.customers.orderId'/>,
				<Translate value='components.customers.orderedDate'/>,
				<Translate value='components.customers.type'/>,
				<Translate value='components.customers.status'/>,
				<Translate value='components.customers.orderAmount'/>,
				<Translate value='components.customers.shipmentOutlier'/>
			],
			fields: ['orderId', 'orderedDate', 'type', 'statusName', 'amount', 'orderId'],
			filter: {},
			showOrderForm: false,
			editOrderId: null,
			isLoaded: false, // Not sure if we need this
			bDisableShipmentOutlier: false,
		};
		this.handleCloseOrderForm = this.handleCloseOrderForm.bind(this);
		this.handleSaveOrder = this.handleSaveOrder.bind(this);
		this.showOrderForm = this.showOrderForm.bind(this);
		this.handleFilterChanged= this.handleFilterChanged.bind(this);
		this.chkShipmentOutlierChecked = this.chkShipmentOutlierChecked.bind(this);
	}

	showOrderForm(dataIn) {
		// console.log(dataIn)
		this.setState({
			editOrderId: dataIn['orderId'],
			showOrderForm: true,
		});
	}
	
	handleCloseOrderForm() {
		this.setState({
			showOrderForm: false,
		});
	}

	handleSaveOrder() {
		this.handleCloseOrderForm();
		this.props.refreshData();
	}

	handleFilterChanged(event, name) {
		var value = null;
		if (event.target != undefined) {
			const target = event.target;
			value = target.type === 'checkbox' ? target.checked : target.value;
		} else {
			return
		}
		var tempFilter = this.state.filter;
		if (value === 'All') {
			delete tempFilter[name]
		} else {
			tempFilter[name] = value
		}
		this.setState({filter: tempFilter})
	}
	
	chkShipmentOutlierChecked(event) {
		const checked = event.target.checked;
		const value = event.target.value;
		
		// console.log("checked: ", checked, ", value: ", value);
		this.props.shipmentOutlierChecked(checked, value);
	}
	
	componentDidMount() {
		const loggedInUser = getLoggedInUser();
		const bDisableShipmentOutlier = !loggedInUser.shipmentOutlierAccessFlag;
		console.log("componentDidMount() : ", bDisableShipmentOutlier);
		this.setState({bDisableShipmentOutlier: bDisableShipmentOutlier});
	}

	render() {
		var orderFormContent = this.state.showOrderForm ? <OrderForm
			visible={this.state.showOrderForm}
			onClose={this.handleCloseOrderForm}
			onSave={this.handleSaveOrder}
			activeCustomers={this.props.activeCustomers}
			editOrderId={this.state.editOrderId}
		/> : "";

		var statusRadioButtons = []
		for (const status of orderStatusList) {
			statusRadioButtons.push(<InputText type="Radio" action={this.handleFilterChanged} name='statusName' value={status} label={status}/>)
		}
		statusRadioButtons.push(<InputText type="Radio" action={this.handleFilterChanged} name='statusName' value='All' defaultChecked label='All'/>)
		var statusRadioGroup = 
			<Form>
				<Translate value='components.customers.orderStatus'/>:
				{statusRadioButtons}
			</Form>

		var typeRadioButtons = []
		for (const type of orderTypesList) {
			if (type === 'All') {
				typeRadioButtons.push(<InputText type="Radio" action={this.handleFilterChanged} name='type' value='All' defaultChecked label='All'/>)
			} else {
				typeRadioButtons.push(<InputText type="Radio" action={this.handleFilterChanged} name='type' value={type} label={type}/>)
			}
		}
		var typeRadioGroup =
			<Form>
				<Translate value='components.customers.orderType'/>
				{typeRadioButtons}
			</Form>
		return(<Form className='form-page' horizontal>
			<h4><Translate value='components.customers.orders'/></h4>
			<Row>
				<Col md={6}>{statusRadioGroup}</Col>
				<Col md={6}>{typeRadioGroup}</Col>
			</Row>
			<InputText
				type="text"
				name='orderId'
				label={<Translate value='components.customers.orderId'/>}
				value={this.state.filter['orderId']}
				action={this.handleFilterChanged}
			/>
			<DataGrid
				columns={this.state.columns}
				fields={this.state.fields}
				filter={this.state.filter}
				data={this.props.orders}
				onDoubleClick={this.showOrderForm}
				formatColumns=
				{{
					1: {'date': true},
					4: {'currency': true},
					5: {checkbox: {}, 
						colCheckBound: 'bShipmentOutlier', 
						callbackCheckbox: this.chkShipmentOutlierChecked,
						disabled: this.state.bDisableShipmentOutlier,
						},
				}}
			/>
			{orderFormContent}
		</Form>);
	}
}

class ProcessNotesTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: [<Translate value='components.customers.note'/>, <Translate value='components.customers.processedBy'/>, <Translate value='components.customers.processedDate'/>],
			fields: ['note', 'processBy', 'processDate'],
			tempNoteData: 
			{
				"customerId":this.props.editCustomerId,
				"note":"",
				"processBy":null,
				"processDate": null,
				"retired":false,
			},
			newNoteFormVisible: false,
			editNoteFormVisible: false,
			isLoaded: false, // Not sure if we need this
		};
		this.handleSaveNote = this.handleSaveNote.bind(this);
		this.resetTempData = this.resetTempData.bind(this);
		this.closeNoteForm = this.closeNoteForm.bind(this);
		this.showEditNoteForm = this.showEditNoteForm.bind(this)
		this.handleDeleteNote = this.handleDeleteNote.bind(this)
		this.handleChangedNoteData = this.handleChangedNoteData.bind(this)
		this.showNewNoteForm = this.showNewNoteForm.bind(this)
		this.handleProcessed = this.handleProcessed.bind(this)
		// this. = this..bind(this)
	}

	handleDeleteNote() {
		this.props.onSave(this.state.tempNoteData, true)
		this.closeNoteForm()
	}
	
	showEditNoteForm(dataIn) {
		const toEdit = this.props.notes[dataIn]
		this.resetTempData()
		this.setState({
			tempNoteData: toEdit,
			editNoteFormVisible: true,
			newNoteFormVisible: false,
		})
	}

	showNewNoteForm() {
		this.resetTempData()
		this.setState({
			editNoteFormVisible: false,
			newNoteFormVisible: true,
		})
	} 

	handleSaveNote() {
		this.props.onSave(this.state.tempNoteData)
		this.closeNoteForm()
	}

	closeNoteForm() {
		this.resetTempData()
		this.setState({
			newNoteFormVisible: false,
			editNoteFormVisible: false,
		})
	}
	
	resetTempData() {
		this.setState({
			tempNoteData: 
			{
				"customerId":this.props.editCustomerId,
				"note":"",
				"processBy":null,
				"processDate": null,
				"retired":false,
			},
		})
	}

	handleChangedNoteData(event, name) {
		var value = null;
		if (event instanceof moment) {
			value = event.valueOf();
		} else if (event.target != undefined) {
			const target = event.target;
			value = target.type === 'checkbox' ? target.checked : target.value;
		} else {
			return
		}
		
		var noteData = this.state.tempNoteData;
		noteData[name] = value;
		
		this.setState({
		  tempNoteData: noteData,
		});
	}

	handleProcessed(event, unused) {
		if (event.target != undefined) {
			
		} else {
			return
		}
		var noteData = this.state.tempNoteData;
		const loggedInUser = getLoggedInUser();
		noteData["processDate"] = Date.now();
		noteData["processBy"] = loggedInUser.userName;
		this.setState({
			tempNoteData: noteData,
		});
	}

	render() {
		var noteForm = this.state.newNoteFormVisible ? 
			<ProcessNotesForm
				isEditForm={false}
				close={this.closeNoteForm}
				tempNoteData={this.state.tempNoteData}
				onChangeNote={this.handleChangedNoteData}
				onSave={this.handleSaveNote}
			/>
			:
			this.state.editNoteFormVisible ? 
			<ProcessNotesForm
				isEditForm={true}
				onDelete={this.handleDeleteNote}
				tempNoteData={this.state.tempNoteData}
				onChangeNote={this.handleChangedNoteData}
				handleProcessed={this.handleProcessed}
				close={this.closeNoteForm}
				onSave={this.handleSaveNote}
			/>
			: 
			<Button onClick={this.showNewNoteForm} bsStyle="primary">{<Translate value='components.customers.addNote'/>}</Button>;
		return(<Form className='form-page' horizontal>
			<h4><Translate value='components.customers.processNotes'/></h4>
			<DataGrid
				columns={this.state.columns}
				fields={this.state.fields}
				data={this.props.notes}
				onSelect={this.showEditNoteForm}
				formatColumns={{2:{'date': true}}}
			/>
			{noteForm}
		</Form>);
	}
}

class ProcessNotesForm extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
	}

	render() {
		var processedBool = false;
		var approvedBool = false;
		if(this.props.tempNoteData["processBy"] != null) {
			processedBool = true;
		}
		if(this.props.tempNoteData["approvedBy"] != null) {
			approvedBool = true;
		}
		const deleteButton = this.props.isEditForm ? 
			<Button bsStyle="danger" onClick={this.props.onDelete}>{<Translate value='components.customers.deleteNote'/>}</Button>
			:
			null;

		const headerText = this.props.isEditForm ?
			<Translate value='components.customers.editNote'/>
			:
			<Translate value='components.customers.addNote'/>

		const processed = this.props.isEditForm ?
			<InputText
				type={'CheckBox'}
				disabled={processedBool||!approvedBool}
				label={<Translate value='components.customers.processed'/>}
				value={processedBool}
				action={this.props.handleProcessed}
			/>
			:
			null;
		return(
			<Form>
				<Row>
					<Col md={6}>
						<h4>{headerText}</h4>
						<InputText type='TextArea' label={<Translate value='components.customers.note'/>} name="note" value={this.props.tempNoteData["note"]} action={this.props.onChangeNote}/>
					</Col>
				</Row>
				<Row>
					{processed}
				</Row>
				<Row>
					<Col md={3}>
						{deleteButton}
					</Col>
					<Col md={3}>
						<Button onClick={this.props.close}>{<Translate value='components.customers.close'/>}</Button>
					</Col>
					<Col md={3}>
						<Button bsStyle="primary" onClick={this.props.onSave}>{<Translate value='components.customers.saveNote'/>}</Button>
					</Col>
				</Row>
			</Form>
		);
	}
}

class PressesTab extends Component {
	//Props list:
	//    Prop: type; description
	//	  editCustomerId: number; ID of the customer
	//    presses: list; list of press objects for display
	constructor(props) {
		super(props);
		this.state = {
			newPressFormVisible: false,
			editPressFormVisible: false,
			editPressId: null,
		};
		this.showEditPressForm = this.showEditPressForm.bind(this)
		this.handleSavePress = this.handleSavePress.bind(this)
		this.handleDeletePress = this.handleDeletePress.bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
	}

	showEditPressForm(dataIn) {
		this.setState({
			editPressId: dataIn['id'],
			editPressFormVisible: true,
		})
	}

	handleSavePress(dataIn) {
		var url = "customers/"+this.props.editCustomerId+"/presses/";
		dataIn['retired'] = false
		postAPI(url, dataIn)
		.then(() => {
			alert("Customer Press Info has been saved successfully");
			this.setState({
				newPressFormVisible: false,
				editPressFormVisible: false
			})
			this.props.refreshPressData()
		})
	}

	handleDeletePress() {
		var url = "customers/"+this.props.editCustomerId+"/presses/"+this.state.editPressId;
		postAPI(url, {})
		.then(() => {
			this.setState({
				newPressFormVisible: false,
				editPressFormVisible: false
			})
			this.props.refreshPressData()
		})
	}

	render() {
		var newPressForm = this.state.newPressFormVisible ?
			<PressesForm
				visible={this.state.newPressFormVisible}
				editCustomerId={this.props.editCustomerId}
				onClose={() => {this.setState({newPressFormVisible: false}); this.props.refreshPressData()}}
				onSave={this.handleSavePress}
				onDelete={this.handleDeletePress}
			/>
			:
			null;
		var editPressForm = this.state.editPressFormVisible ?
			<PressesForm
				visible={this.state.editPressFormVisible}
				editCustomerId={this.props.editCustomerId}
				editPressId={this.state.editPressId}
				onClose={() => {this.setState({editPressFormVisible: false}); this.props.refreshPressData()}}
				onSave={this.handleSavePress}
				onDelete={this.handleDeletePress}
			/>
			:
			null;
		return <div>
			<h4><Translate value='components.customers.presses' /></h4>
			<DataGrid
				columns={[
					<Translate value='components.customers.pressName' />,
					<Translate value='components.customers.pressType' />,
					<Translate value='components.customers.numberOfStations' />,
				]}
				fields={['pressName', 'pressType', 'stations']}
				onDoubleClick={this.showEditPressForm}
				data={this.props.presses}
				formatColumns={{}}
			/>
			<Button
				onClick={() => this.setState({newPressFormVisible: true})}
			>
				<Translate value='components.customers.addNewPress' />
			</Button>
			{newPressForm}
			{editPressForm}
		</div>

	}
}

const pressesFormValidationFields = {
	name: "required",
	pressType: "required",
	varnishPackage: "required",
	phMin: "required,numeric",
	phMax: "required,numeric",
	baseSystem: "required",
	viscosityRange: "required",
	// viscosityCupLookupId: "required",
}
class PressesForm extends Component {
	//Props list:
	//    Prop: type; description
	//    editPressId: number; id of the press to edit. Null if not edit form
	//	  editCustomerId: number; ID of the customer
	//    onSave: function; function triggered on save
	//    onClose: function; funciton triggered on close

	constructor(props) {
		super(props);
		const errorsTemplate = resetObject(pressesFormValidationFields)
		this.state = { 
			errors: errorsTemplate,
			tempPressData:
			{
				"id": null,
				"name": "",
				"pressType": "",
				"vacuumTransferFlag": false,
				"varnishPackage": null,
				"phMin": null,
				"phMax": null,
				"blendingSystemFlag": false,
				"viscosityRange": null,
				"viscosityCupLookupId": null,
				"specialNotes": "",
				"baseSystem": null,
				"customerId": this.props.editCustomerId,
				"modifiedBy": null,
				"modifiedDate": null
			},
			showNewStationForm: false,
			showEditStationForm: false,
			stations: [],
			selectedStation: 0,
			selectedStationData:
			{
				"id":null,
				"stationNumber":null,
				"customerPressId": this.props.editPressId,
				"dryerLookupId":null,
				"uvFlag":false,
				"bladesTypeLookupId":null,
				"meteringSystemLookupId":null
			},
			// Structure of aniloxes as follows:
			//     aniloxes = [[{},{},...,{}],[{},{},...,{}],...,[{},{},...,{}]]
			//         list of aniloxes for station[x] = aniloxes[x]
			showNewAniloxForm: false,
			showEditAniloxForm: false,
			aniloxes: [[]],
			selectedAnilox: 0,
			selectedAniloxData: 
			{
				"id":null,
				"customerPressStationId": null,
				"anilox":"",
				"volume":"",
				"matchWith":"",
				"retired":false
			},
		};
		this.getAniloxes = this.getAniloxes.bind(this)
		this.handleSelectStation = this.handleSelectStation.bind(this)
		this.handleEditStation = this.handleEditStation.bind(this)
		this.handleNewStation = this.handleNewStation.bind(this)
		this.handleSaveStation = this.handleSaveStation.bind(this)
		this.clearSelectedStationData = this.clearSelectedStationData.bind(this)
		this.handleEditAnilox = this.handleEditAnilox.bind(this)
		this.handleNewAnilox = this.handleNewAnilox.bind(this)
		this.handleChangedStationData = this.handleChangedStationData.bind(this)
		this.clearSelectedAniloxData = this.clearSelectedAniloxData.bind(this)
		this.handleChangedPressData = this.handleChangedPressData.bind(this)
		this.handleDeleteStation = this.handleDeleteStation.bind(this)
		this.updateDataLists = this.updateDataLists.bind(this)
		this.handleSaveAnilox = this.handleSaveAnilox.bind(this)
		this.handleChangedAniloxData = this.handleChangedAniloxData.bind(this)
		this.handleSelectAnilox = this.handleSelectAnilox.bind(this)
		this.handleDeleteAnilox = this.handleDeleteAnilox.bind(this)
		this.validate = this.validate.bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
	}

	componentDidMount() {
		if (this.props.editPressId != null) { // The form is in edit mode
			var url1 = "customers/"+this.props.editCustomerId+"/presses/"+this.props.editPressId; // URL to get press data
			var url2 = "customers/"+this.props.editCustomerId+"/presses/"+this.props.editPressId+"/stations"; // URL to get stations
			Promise.all([
				getAPI(url1),
				getAPI(url2)
			])
			.then(([pressData, stations]) => {
				// console.log('PressData: ',pressData,'Stations: ', stations)
				var aniloxes = [];
				pressData = pressData['data'][0];
				stations = stations['data'];

				if (stations.length > 0) {
					for (const station of stations) {
						// console.log('anilox pushed')
						aniloxes.push(this.getAniloxes(station['id'])) // Returns list of Promises
					}
				}
				Promise.all(aniloxes)// process list of promises
				.then(aniloxes => {
					this.setState({
						tempPressData: pressData,
						stations: stations,
						aniloxes: aniloxes,
					});
				});
			});
		} else { // The form is in new mode
			// Not needed at the moment, keeping around for reasons of futureproofing
		}
	}
	// #region functions
	validate() {
		let validation = {};
		validation = validateForm(this.state.tempPressData, pressesFormValidationFields);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result) {
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		} else {
			this.props.onSave(this.state.tempPressData)
		}
	}

	getAniloxes(stationId) {
		var url = "customers/"+this.props.editCustomerId+"/presses/"+this.props.editPressId+"/stations/"+stationId+"/aniloxes";
		return getAPI(url).then(res => res['data'])
	}

	handleSelectStation(selected) {
		this.setState({
			selectedStation: selected
		})
	}

	handleEditStation(dataIn) {
		// console.log(dataIn)
		this.setState({
			selectedStationData: dataIn,
			showEditStationForm: true,
		})
	}

	handleNewStation() {
		this.clearSelectedStationData()
		var stationData = this.state.selectedStationData;
		stationData['stationNumber'] = this.state.stations.length > 0 ? this.state.stations[this.state.stations.length-1]['stationNumber']+1 : 1;
		this.setState({
			selectedStationData: stationData,
			showNewStationForm: true
		})
	}

	handleSaveStation() {
		var url = "customers/"+this.props.editCustomerId+"/presses/"+this.props.editPressId+"/stations"
		var stationData = this.state.selectedStationData;
		stationData['customerPressId'] = this.props.editPressId;
		stationData['meteringSystemLookupId'] = parseInt(this.state.selectedStationData['meteringSystemLookupId'], 10)
		stationData['bladesTypeLookupId'] = parseInt(this.state.selectedStationData['bladesTypeLookupId'], 10)
		stationData['dryerLookupId'] = parseInt(this.state.selectedStationData['dryerLookupId'], 10)
		stationData['retired'] = false
		postAPI(url, stationData)
		.then(() => {this.updateDataLists()})
		.then(() => {
			this.setState({
				showNewStationForm: false,
				showEditStationForm: false
			})
		})
	}

	handleChangedStationData(event, name) {
		var value = null;
		if (event instanceof moment) {
			value = event.valueOf();
		} else if (event.target != undefined) {
			const target = event.target;
			value = target.type === 'checkbox' ? target.checked : target.value;
		} else {
			return
		}
		
		var stationData = this.state.selectedStationData;
		stationData[name] = value;
		
		this.setState({
			selectedStationData: stationData,
		});
	}

	clearSelectedStationData() {
		this.setState({
			selectedStationData: 
			{
				"id":null,
				"stationNumber":null,
				"customerPressId": this.props.editPressId,
				"dryerLookupId":null,
				"uvFlag":false,
				"bladesTypeLookupId":null,
				"meteringSystemLookupId":null
			},
		})
	}

	handleSelectAnilox(selected) {
		this.setState({
			selectedAnilox: selected
		})
	}

	handleEditAnilox(dataIn) {
		this.setState({
			selectedAniloxData: dataIn,
			showEditAniloxForm: true,
		})
	}

	handleNewAnilox() {
		this.clearSelectedAniloxData()
		this.setState({
			showNewAniloxForm: true,
		})
	}

	clearSelectedAniloxData() {
		this.setState({
			selectedAniloxData:
			{
				"id":null,
				"customerPressStationId": this.state.stations[this.state.selectedStation]['id'],
				"anilox":"",
				"volume":"",
				"matchWith":"",
				"retired":false
			}
		})
	}

	handleChangedAniloxData(event, name) {
		var value = null;
		if (event instanceof moment) {
			value = event.valueOf();
		} else if (event.target != undefined) {
			const target = event.target;
			value = target.type === 'checkbox' ? target.checked : target.value;
		} else {
			return
		}
		
		var aniloxData = this.state.selectedAniloxData;
		aniloxData[name] = value;
		
		this.setState({
			selectedAniloxData: aniloxData,
		});
	}

	handleSaveAnilox() {
		var url = "customers/"+this.props.editCustomerId+"/presses/"+this.props.editPressId+"/stations/"+this.state.stations[this.state.selectedStation]['id']+"/aniloxes"
		var aniloxData = this.state.selectedAniloxData;
		aniloxData['retired'] = false
		postAPI(url, aniloxData)
		.then(() => {this.updateDataLists()})
		.then(() => {
			this.setState({
				showNewAniloxForm: false,
				showEditAniloxForm: false
			})
		})
	}

	handleDeleteAnilox() {
		var url = "customers/"
			+this.props.editCustomerId
			+"/presses/"
			+this.props.editPressId
			+"/stations/"
			+this.state.stations[this.state.selectedStation]['id']
			+"/aniloxes/"
			+this.state.aniloxes[this.state.selectedStation][this.state.selectedAnilox]['id'];
		postAPI(url, {})
		.then(() => {this.updateDataLists()})
	}

	handleChangedPressData(event, name) {
		var value = null;
		if (event instanceof moment) {
			value = event.valueOf();
		} else if (event.target != undefined) {
			const target = event.target;
			value = target.type === 'checkbox' ? target.checked : target.value;
		} else {
			return
		}
		
		var pressData = this.state.tempPressData;
		pressData[name] = value;
		
		this.setState({
			tempPressData: pressData,
		});
	}

	handleDeleteStation() {
		var url = "customers/"+this.props.editCustomerId+"/presses/"+this.props.editPressId+"/stations/"+this.state.stations[this.state.selectedStation]['id'];
		postAPI(url, {})
		.then(() => {this.updateDataLists()})
	}

	updateDataLists() {
		var url = "customers/"+this.props.editCustomerId+"/presses/"+this.props.editPressId+"/stations"; // URL to get stations
		getAPI(url)
		.then(stations => {
			var aniloxes = [];
			stations = stations['data'];

			if (stations.length > 0) {
				for (const station of stations) {
					// console.log('anilox pushed')
					aniloxes.push(this.getAniloxes(station['id'])) // Returns list of Promises
				}
			}
			Promise.all(aniloxes)// process list of promises
			.then(aniloxes => {
				this.setState({
					stations: stations,
					aniloxes: aniloxes,
				});
			});
		})
	}

	// #endregion
	render() {
		// console.log("State at Render:",this.state)
		// #region prerender
		const headerText = this.props.editPressId != null ? 
			<Translate value='components.customers.editPress'/>
			:
			<Translate value='components.customers.addNewPress'/>
		var stations = this.props.editPressId != null ? 
			<Col md={6}>
				<DataGrid // WARNING THIS SHOULD ONLY BE AVALIBLE IF IN EDIT MODE.
					columns={[
						<Translate value='components.customers.station' />,
						<Translate value='components.customers.dryers' />,
						<Translate value='components.customers.meteringSystem' />,
						<Translate value='components.customers.bladesType' />,
						<Translate value='components.customers.uv' />,
					]}
					fields={['stationNumber', 'dryerLookupId', 'meteringSystemLookupId', 'bladesTypeLookupId', 'uvFlag']}
					onSelect={this.handleSelectStation}
					onDoubleClick={this.handleEditStation}
					data={this.state.stations}
					formatColumns={{
						1: {'idLookup': dryerTypesList},
						2: {'idLookup': meteringSystemsList},
						3: {'idLookup': bladeTypesList},
						4: {'checkbox': true,
							'disabled': true,
							'colCheckBound': 'uvFlag'
						},
					}}
				/>
				{this.state.stations.length > 0 ? <Button bsStyle='danger' onClick={this.handleDeleteStation} ><Translate value='components.customers.deleteStation'/></Button> : null}
				&nbsp;
				<Button onClick={this.handleNewStation}><Translate value='components.customers.newStation' /></Button>
			</Col>
			:
			<Col md={6}><Translate value='components.customers.createFirstStations' /></Col>;

		var aniloxes = this.props.editPressId != null && this.state.stations.length > 0 ?
			<Col md={6}>
				<DataGrid
					columns={[
						<Translate value='components.customers.anilox' />,
						<Translate value='components.customers.volume' />,
						<Translate value='components.customers.matchWith' />,
					]}
					fields={['anilox', 'volume', 'matchWith']}
					data={this.state.aniloxes[this.state.selectedStation]}
					onSelect={this.handleSelectAnilox}
					onDoubleClick={this.handleEditAnilox}
					formatColumns={{}}
				/>
				{this.state.aniloxes[this.state.selectedStation].length > 0 ? <Button bsStyle='danger' onClick={this.handleDeleteAnilox} ><Translate value='components.customers.deleteAnilox' /></Button> : null}
				&nbsp;
				<Button onClick={this.handleNewAnilox}><Translate value='components.customers.newAnilox' /></Button>
			</Col>
			: !(this.state.stations.length > 0) ? 
			null 
			:
			<Col md={6}>{this.state.stations.length > 0 ? <Translate value='components.customers.createFirstAniloxes' /> : null}</Col>;

		var editStationForm = this.state.showEditStationForm ? 
			<StationForm 
				stationData={this.state.selectedStationData}
				visible={this.state.showEditStationForm}
				onSave={this.handleSaveStation}
				onClose={() => {this.setState({showEditStationForm: false})}}
				handleChangedStationData={this.handleChangedStationData}
			/>
			:
			null;

		var newStationForm = this.state.showNewStationForm ? 
			<StationForm 
				visible={this.state.showNewStationForm}
				stationData={this.state.selectedStationData}
				onSave={this.handleSaveStation}
				onClose={() => {this.setState({showNewStationForm: false})}}
				handleChangedStationData={this.handleChangedStationData}
			/>
			:
			null;

		var editAniloxForm = this.state.showEditAniloxForm ? 
			<AniloxForm 
				aniloxData={this.state.selectedAniloxData}
				visible={this.state.showEditAniloxForm}
				onSave={this.handleSaveAnilox}
				onClose={() => {this.setState({showEditAniloxForm: false})}}
				handleChangedAniloxData={this.handleChangedAniloxData}
			/>
			:
			null;

		var newAniloxForm = this.state.showNewAniloxForm ? 
			<AniloxForm 
				visible={this.state.showNewAniloxForm}
				aniloxData={this.state.selectedAniloxData}
				onSave={this.handleSaveAnilox}
				onClose={() => {this.setState({showNewAniloxForm: false})}}
				handleChangedAniloxData={this.handleChangedAniloxData}
			/>
			:
			null;
		// #endregion
		return <Modal show={this.props.visible} bsSize='large'>
			<Modal.Header>
				<Modal.Title>{headerText}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h4><Translate value='components.customers.pressInformation' /></h4>
				<Form className='form-page' horizontal>
					<Row>
						<Col md={4}>
							<InputText 
								required 
								label={<Translate value='components.customers.pressName' />}
								name="name"
								value={this.state.tempPressData['name']}
								action={this.handleChangedPressData}
								errMsg={this.state.errors.name}
							/>
							<InputText 
								required
								label={<Translate value='components.customers.pressType' />}
								name="pressType"
								value={this.state.tempPressData['pressType']}
								action={this.handleChangedPressData}
								errMsg={this.state.errors.pressType}
							/>
							<InputText 
								type='CheckBox' 
								label={<Translate value='components.customers.vacuumTransfer' />}
								name="vacuumTransferFlag"
								value={this.state.tempPressData['vacuumTransferFlag']}
								action={this.handleChangedPressData}
							/>
							<InputText
								required
								label={<Translate value='components.customers.varnishPackage' />}
								name="varnishPackage"
								value={this.state.tempPressData['varnishPackage']}
								action={this.handleChangedPressData}
								errMsg={this.state.errors.varnishPackage}
							/>
						</Col>
						<Col md={4}>
							<InputText 
								required 
								label={<Translate value='components.customers.phMin' />}
								name="phMin"
								value={this.state.tempPressData['phMin']}
								action={this.handleChangedPressData}
								errMsg={this.state.errors.phMin}
							/>
							<InputText 
								required 
								label={<Translate value='components.customers.phMax' />}
								name="phMax"
								value={this.state.tempPressData['phMax']}
								action={this.handleChangedPressData}
								errMsg={this.state.errors.phMax}
							/>
							<InputText 
								type='CheckBox' 
								label={<Translate value='components.customers.blendingSystem' />}
								name="blendingSystemFlag"
								value={this.state.tempPressData['blendingSystemFlag']}
								action={this.handleChangedPressData}
							/>
							<InputText 
								required 
								label={<Translate value='components.customers.baseSystem' />}
								name="baseSystem"
								value={this.state.tempPressData['baseSystem']}
								action={this.handleChangedPressData}
								errMsg={this.state.errors.baseSystem}
							/>
							<InputText 
								required 
								label={<Translate value='components.customers.viscosityRange' />}
								name="viscosityRange"
								value={this.state.tempPressData['viscosityRange']}
								action={this.handleChangedPressData}
								errMsg={this.state.errors.viscosityRange}
							/>
							{false && <DataDropdown 
								required
								label={<Translate value='components.customers.viscosityCup' />} 
								data={viscosityCupsList}
								name="viscosityCupLookupId"
								value={this.state.tempPressData['viscosityCupLookupId']}
								action={this.handleChangedPressData}
								errMsg={this.state.errors.viscosityCupLookupId}
							/>}
							<InputText 
								type='Label'
								label={<Translate value='components.customers.lastUpdatedBy' />}
								value={this.state.tempPressData['modifiedBy']}
							/>
							<InputText 
								type='Label'
								label={<Translate value='components.customers.lastUpdatedOn' />}
								value={convertTimestampToDate(this.state.tempPressData['modifiedDate'])}
							/>
						</Col>
						<Col md={4}>
							<InputText 
								type='TextArea'
								label={<Translate value='components.customers.specialNotes' />}
								name="specialNotes"
								value={this.state.tempPressData['specialNotes']}
								action={this.handleChangedPressData}
							/>
						</Col>
					</Row>
					<Row>
						{stations}
						{aniloxes}
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{this.props.editPressId != null ? <Button bsStyle='danger' onClick={this.props.onDelete}><Translate value='components.customers.delete' /></Button> : null}
				<Button onClick={this.props.onClose}><Translate value='components.customers.close' /></Button>
				<Button onClick={this.validate} bsStyle='primary'><Translate value='components.customers.saveChanges' /></Button>
			</Modal.Footer>
			{editStationForm}
			{newStationForm}
			{editAniloxForm}
			{newAniloxForm}
		</Modal>
	}
}

class StationForm extends Component {
	//Props list:
	//    Prop: type; description
	//    stationData: object; data object 
	//	  editCustomerId: number; ID of the customer
	//    onClose: function; function triggered on close
	//    onSave: function; function triggered on save
	//    handleChangedStationData: funciton; function to handle changind the station data
	constructor(props) {
		super(props);
		this.state = {
			
		};
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
	}
	
	render () {
		return(
			<Modal show={this.props.visible} bsSize='small'>
				<Form className='form-page' horizontal>
					<InputText
						label={<Translate value='components.customers.station'/>}
						name="stationNumber"
						value={this.props.stationData['stationNumber']}
						action={this.props.handleChangedStationData}
					/>
					<DataDropdown
						label={<Translate value='components.customers.dryers'/>}
						value={this.props.stationData['dryerLookupId']}
						name="dryerLookupId"
						data={dryerTypesList}
						action={this.props.handleChangedStationData}
					/>
					<DataDropdown
						label={<Translate value='components.customers.meteringSystem'/>}
						value={this.props.stationData['meteringSystemLookupId']}
						name="meteringSystemLookupId"
						data={meteringSystemsList}
						action={this.props.handleChangedStationData}
					/>
					<DataDropdown
						label={<Translate value='components.customers.bladesType'/>}
						value={this.props.stationData['bladesTypeLookupId']}
						name="bladesTypeLookupId"
						data={bladeTypesList}
						action={this.props.handleChangedStationData}
					/>
					<InputText
						label={<Translate value='components.customers.uv'/>}
						value={this.props.stationData['uvFlag']}
						name="uvFlag"
						type='CheckBox'
						action={this.props.handleChangedStationData}
					/>
				</Form>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value='components.customers.cancel'/></Button>
					<Button onClick={this.props.onSave} bsStyle="primary"><Translate value='components.customers.save'/></Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

class AniloxForm extends Component {
	//Props list:
	//    Prop: type; description
	//    aniloxData: object; data object 
	//	  editCustomerId: number; ID of the customer
	//    onClose: function; function triggered on close
	//    onSave: function; function triggered on save
	constructor(props) {
		super(props);
		this.state = {
			
		};
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
		// this. = this..bind(this)
	}

	render () {
		return(
			<Modal show={this.props.visible} bsSize='small'>
				<Form className='form-page' horizontal>
					<InputText
						label={<Translate value='components.customers.anilox'/>}
						name="anilox"
						value={this.props.aniloxData['anilox']}
						action={this.props.handleChangedAniloxData}
					/>
					<InputText
						label={<Translate value='components.customers.volume'/>}
						name="volume"
						value={this.props.aniloxData['volume']}
						action={this.props.handleChangedAniloxData}
					/>
					<InputText
						label={<Translate value='components.customers.matchWith'/>}
						value={this.props.aniloxData['matchWith']}
						name="matchWith"
						action={this.props.handleChangedAniloxData}
					/>
				</Form>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value='components.customers.cancel'/></Button>
					<Button onClick={this.props.onSave} bsStyle="primary"><Translate value='components.customers.save'/></Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

class TasksTab extends Component {
	//Props list:
	//    Prop: type; description
	//	  editCustomerId: number; ID of the customer
	//    presses: list; list of press objects for display
	constructor(props) {
		super(props);
		this.state = {
			editTaskFormVisible: false,
			editTaskId: null,
		};
		this.showEditTaskForm = this.showEditTaskForm.bind(this);
		this.closeFormAndRefreshData = this.closeFormAndRefreshData.bind(this);
	}

	showEditTaskForm(dataIn) {
		this.setState({
			editTaskId: dataIn['taskId'],
			editTaskFormVisible: true,
		})
	}

	closeFormAndRefreshData() {
		this.setState({
			editTaskFormVisible: false
		});
		this.props.refreshTasksData();
	}
	

	render() {
		var editTaskForm = this.state.editTaskFormVisible ?
			<NewTaskForm
				visible={this.state.editTaskFormVisible}
				editCustomerId={this.props.editCustomerId}
				editTaskId={this.state.editTaskId}
				onClose={() => {
					this.setState({editTaskFormVisible: false});
				}}
				onSave={this.closeFormAndRefreshData}
				onDelete={this.closeFormAndRefreshData}
			/>
			:
			null;
		return <div>
			<h4><Translate value='components.labels.tasks' /></h4>
			<DataGrid
				columns={[
					<Translate value="components.labels.taskId" />,
					<Translate value="components.labels.assignedTo" />,
					<Translate value="components.labels.toDo" />,
					<Translate value="components.labels.dueDate" />,
					<Translate value="components.labels.completed" />,
				]}
				fields={['taskId', 'assignedToSalesRepName', 'toDo', 'dueDateTime', 'completedYesNo']}
				onDoubleClick={this.showEditTaskForm}
				data={this.props.tasks}
				formatColumns={{
					3: {'date': true, bgColor: 'bgColor'},
				}}
			/>
			<Button
				onClick={() => this.setState({editTaskFormVisible: true, editTaskId: null})}
			>
				<Translate value='components.buttons.newTask' />
			</Button>
			{editTaskForm}
		</div>

	}
}

class CallReportsTab extends Component {
	//Props list:
	//    Prop: type; description
	//	  editCustomerId: number; ID of the customer
	//    presses: list; list of press objects for display
	constructor(props) {
		super(props);
		this.state = {
			editCallReportFormVisible: false,
			editCallReportId: null,
		};
		this.showEditCallReportForm = this.showEditCallReportForm.bind(this);
		this.closeFormAndRefreshData = this.closeFormAndRefreshData.bind(this);
	}
	
	closeFormAndRefreshData() {
		this.setState({
			editCallReportFormVisible: false
		});
		this.props.refreshCallReportsData();
	}

	showEditCallReportForm(dataIn) {
		this.setState({
			editCallReportId: dataIn['callReportId'],
			editCallReportFormVisible: true,
		})
	}

	render() {
		var editCallReportForm = this.state.editCallReportFormVisible ?
			<NewCallReportForm
				visible={this.state.editCallReportFormVisible}
				editCustomerId={this.props.editCustomerId}
				editCallReportId={this.state.editCallReportId}
				onClose={() => {
					this.setState({editCallReportFormVisible: false});
				}}
				onSave={this.closeFormAndRefreshData}
				onDelete={this.closeFormAndRefreshData}
			/>
			:
			null;
		return <div>
			<h4><Translate value='components.labels.callReports' /></h4>
			<DataGrid
				columns={[
					<Translate value="components.labels.date" />,
					<Translate value="components.labels.type" />,
					<Translate value="components.labels.jobDesc" />,
					<Translate value="components.labels.tasksAssigned" />,
					<Translate value="components.labels.tasksCompleted" />,
				]}
				fields={['callDate', 'callReportType', 'jobDesc', 'numTasksAssigned', 'numTasksCompleted']}
				onDoubleClick={this.showEditCallReportForm}
				data={this.props.callReports}
				formatColumns={{
					0: {'date': true},
				}}
			/>
			<Button
				onClick={() => this.setState({editCallReportFormVisible: true, editCallReportId: null})}
			>
				<Translate value='components.buttons.newCallReport' />
			</Button>
			{editCallReportForm}
		</div>

	}
}

class SalesTab extends React.Component {
	constructor(props) {
		super(props);

		let customerSalesTabValidationFields = {
			
		}

		const errorsTemplate = resetObject(customerSalesTabValidationFields)
		this.state = {
			errors: errorsTemplate
		}
		this.validate = this.validate.bind(this);
		this.customerSalesTabValidationFields = customerSalesTabValidationFields;
	}

	validate() {
		// Validate Form
		let validation = {};
		var testData = this.props.salesSectionData;
		var customerSalesTabValidationFields = this.customerSalesTabValidationFields;
		
		
		validation = validateForm(testData, customerSalesTabValidationFields);
		// console.log("this.props.userData: ", this.props.userData, ", validation: ", validation);
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			this.props.onSave(testData, false);
		}
	}

	render() {

		return (
			<Form class='form-page' horizontal>
				<Row>
					<Col md={8}>
						<h4>{<Translate value='components.tabs.salesSection'/>}</h4>
						<InputText 
							type='CheckBox' 
							label={<Translate value='components.customers.underContract'/>} 
							id='underContract' 
							name='underContract' 
							value={this.props.salesSectionData['underContract']} 
							action={this.props.salesSectionDataUpdate}
							// Don't think the code is set up to handle boolean values properly. But because a default value is set, it cannot ever be null
						/>
						{
							this.props.salesSectionData['underContract'] && <InputText 
							type='DateTime' 
							label={<Translate value='components.customers.contractExpires'/>} 
							id='contractExpires' 
							name='contractExpires' 
							value={this.props.salesSectionData["contractExpires"]} 
							action={this.props.salesSectionDataUpdate}
							errMsg={this.state.errors['contractExpires']}
						/>
						}
						
						<InputText 
							required 
							label={<Translate value='components.customers.currentPrimaryInkSupplier'/>} 
							name="inkSupplier" 
							value={this.props.salesSectionData['inkSupplier']} 
							action={this.props.salesSectionDataUpdate}
							errMsg={this.state.errors['inkSupplier']}
						/>
						<InputText 
							type='CheckBox' 
							label={<Translate value='components.customers.blendingSystem'/>} 
							id='blendingSystem' 
							name='blendingSystem' 
							value={this.props.salesSectionData['blendingSystem']} 
							action={this.props.salesSectionDataUpdate}
						/>
						{
							this.props.salesSectionData['blendingSystem'] && <span>
								<InputText  
									label={<Translate value='components.customers.numberOfHeads'/>} 
									name="numHeads" 
									value={this.props.salesSectionData['numHeads']} 
									action={this.props.salesSectionDataUpdate}
									errMsg={this.state.errors['numHeads']}
								/>
								<InputText 
									type='CheckBox' 
									label={<Translate value='components.customers.customerOwned'/>} 
									id='customerOwned' 
									name='customerOwned' 
									value={this.props.salesSectionData['customerOwned']} 
									action={this.props.salesSectionDataUpdate}
								/>
								<DataDropdown 
									label={<Translate value='components.customers.condition'/>} 
									name="condition" 
									data={{'1': 'Excellent', '2': 'Fair', '3': 'Poor'}} 
									value={this.props.salesSectionData['condition']} 
									action={this.props.salesSectionDataUpdate}
									errMsg={this.state.errors['condition']}
								/>
							</span>
						}
						<InputText  
							label={<Translate value='components.customers.estimatedInkSpendFit'/>} 
							name="estimatedInkSpendFit" 
							value={this.props.salesSectionData['estimatedInkSpendFit']} 
							action={this.props.salesSectionDataUpdate}
							errMsg={this.state.errors['estimatedInkSpendFit']}
						/>
						<DataDropdown 
							label={<Translate value='components.customers.fittedBusinessCaptured'/>} 
							name="fittedBusinessCaptured" 
							data={{10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%', 60: '60%', 70: '70%', 80: '80%', 90: '90%', 100: '100%', }} 
							value={this.props.salesSectionData['fittedBusinessCaptured']} 
							action={this.props.salesSectionDataUpdate}
							errMsg={this.state.errors['fittedBusinessCaptured']}
						/>
						<DataDropdown 
							label={<Translate value='components.customers.bcmCurrentPosition'/>} 
							name="bcmCurrentPosition" 
							data={{'1': 'Acquaintance', '2': 'Professional Peer', '3': 'Trusted Advisor', '4': 'Change (Ownership/Management)'}} 
							value={this.props.salesSectionData['bcmCurrentPosition']} 
							action={this.props.salesSectionDataUpdate}
							errMsg={this.state.errors['bcmCurrentPosition']}
						/>
						<h5>{<Translate value='components.customers.otherSuppliers'/>}</h5>
						<InputText 
							label={<Translate value='components.customers.prepressPrintingPlates'/>} 
							name="prepressPrintingPlates" 
							value={this.props.salesSectionData['prepressPrintingPlates']} 
							action={this.props.salesSectionDataUpdate}
							errMsg={this.state.errors['prepressPrintingPlates']}
						/>
						<InputText 
							label={<Translate value='components.customers.aniloxRollProvider'/>} 
							name="aniloxRollProvider" 
							value={this.props.salesSectionData['aniloxRollProvider']} 
							action={this.props.salesSectionDataUpdate}
							errMsg={this.state.errors['aniloxRollProvider']}
						/>
						<InputText 
							label={<Translate value='components.customers.dryersLampsProvider'/>} 
							name="dryersLampsProvider" 
							value={this.props.salesSectionData['dryersLampsProvider']} 
							action={this.props.salesSectionDataUpdate}
							errMsg={this.state.errors['dryersLampsProvider']}
						/>
						<InputText 
							label={<Translate value='components.customers.otherRelativeSuppliers'/>} 
							name="otherRelativeSuppliers" 
							value={this.props.salesSectionData['otherRelativeSuppliers']} 
							action={this.props.salesSectionDataUpdate}
							errMsg={this.state.errors['otherRelativeSuppliers']}
						/>
					</Col>
					
				</Row>
				<Button onClick={this.validate} bsStyle="primary"><Translate value='components.customers.saveChanges'/></Button>
			</Form>
		);
	}
}


class ContractsTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			states: [],
			columns: 
			[
				<Translate value='components.customers.type' />,
				<Translate value='components.customers.startDate' />,
				<Translate value='components.customers.endDate' />,
				<Translate value='components.customers.totalAmount' />,
				<Translate value='components.customers.totalSales' />,
				<Translate value='components.customers.percentTotalContract' />,
				<Translate value='components.customers.status' />,
				<Translate value='components.customers.download' />,
			],
			fields: ['typeName', 'startDate', 'endDate', 'totalAmount', 'totalSales', 'percentOfTotalContract', 'statusName', 'pdfLocation'],
			newContractFormVisible: false,
			editContractFormVisible: false,
		    tempContractData: {
				"id": null,
				"customerId": this.props.editCustomerId,
				"type": null,
				"startDate": null,
				"endDate": null,
				"status": null,
				"statusName": null,
				"pdfLocation": null,
				"totalAmount": null,
				"percentOfTotalContract": null,
				"equipment": null,
			},
			isLoaded: false,
			pdfFile: null,
		};
		this.showNewContractForm = this.showNewContractForm.bind(this);
		this.showEditContractForm = this.showEditContractForm.bind(this);
		this.handleInputChangedTempContract = this.handleInputChangedTempContract.bind(this);
		this.handleSaveContractData = this.handleSaveContractData.bind(this);
		this.onLinkClick = this.onLinkClick.bind(this);
	}	
	componentDidMount() {
		
	}
	
	onLinkClick(event) {
		const editId = event.target.id;
		console.log("event.target: ", event.target, ", editId: ", editId);
		let pdfURL = "myinkiq-0.1.0/customers/" + editId + "/downloadContract";
		// window.location.href = pdfURL;
		window.open(pdfURL);
	}

	resetTempContract() {
		this.setState({
			tempContractData: {
				"id": null,
				"customerId": this.props.editCustomerId, 
				"type": null,
				"startDate": null,
				"endDate": null,
				"status": null,
				"statusName": null,
				"pdfLocation": null,
				"totalAmount": null,
				"percentOfTotalContract": null,
				"equipment": null,
			}	
		});
	}

	handleInputChangedTempContract(event, name) {
		const target = event.target;
		var value = null;
		
		var contractData = this.state.tempContractData;
		var pdfFile = this.state.pdfFile;
		
		switch(name) {
			case 'startDate':
			case 'endDate':
				value = event._d ? event._d.getTime() : event;
				break;
			
			case 'pdfLocation':
				pdfFile = target.files[0];
				break;
			
			default:
				value = target.type === 'checkbox' ? target.checked : target.value;
		}
		// console.log("handleInputChangedTempContract - name: ", name, ", value: ", value, ", pdfFile: ", pdfFile);
		contractData[name] = value;

		this.setState({
		  tempContractData: contractData,
		  pdfFile: pdfFile,
		});

		// console.log(this.state)
		// console.log(this.state, this.props)
	}

	showNewContractForm() {
		this.resetTempContract();
		this.setState({
			newContractFormVisible: true,
			editContractFormVisible: false,
		});
	}

	showEditContractForm(index) {
		var contractId = this.props.contractsList[index]["id"];
		
		var contract = JSON.parse(JSON.stringify(this.props.contractsList[index]));
		// console.log("this.props.contractsList: ", this.props.contractsList, ", index: ", index, ", contract: ", contract);
		this.setState({
			tempContractData: contract,
			editContractFormVisible: true,
			newContractFormVisible: false,
		});
	}

	handleSaveContractData(event, deleteFlag=false) {
		this.props.handleSaveChangedContract(this.state.tempContractData, this.state.pdfFile, deleteFlag);
		this.setState({
			editContractFormVisible: false,
			newContractFormVisible: false,
		});
		this.resetTempContract();
	}

	render() {
		var contractForm = this.state.newContractFormVisible ? 
			<ContractForm
				isEditForm={false}
				handleInputChangedTempContract={this.handleInputChangedTempContract}
				tempContractData={this.state.tempContractData}
				close={() => this.setState({newContractFormVisible: false})}
				onSave={this.handleSaveContractData}
			/>
			:
			this.state.editContractFormVisible ? 
			<ContractForm
				isEditForm={true}
				handleInputChangedTempContract={this.handleInputChangedTempContract}
				tempContractData={this.state.tempContractData}
				close={() => this.setState({editContractFormVisible: false})}
				onSave={this.handleSaveContractData}
			/>
			: 
			<Button onClick={this.showNewContractForm} bsStyle="primary">{<Translate value='components.customers.addContract'/>}</Button>;
		// console.log(states)
		return(<Form className='form-page' horizontal>
			<h4>{<Translate value='components.customers.contracts'/>}</h4>
			<DataGrid
				columns={this.state.columns}
				onSelect={this.showEditContractForm}
				fields={this.state.fields}
				data={this.props.contractsList}
				formatColumns=
				{{
					1: {'date': true},
					2: {'date': true},
					3: {'currency': true},
					4: {'currency': true},
					5: {'percentage': true},
					7: {'link': {'column': 'id', 'func': this.onLinkClick}}
				}}
			/>
			<Row>
				{contractForm}
			</Row>
		</Form>);
	}
	
}

const contractsFormValidationFields = {
	startDate: "required",
	// endDate: "required",
	// totalAmount: "required",
}

class ContractForm extends Component {
	constructor(props) {
		super(props);
		const errorsTemplate = resetObject(contractsFormValidationFields)
		this.state = { 
			errors: errorsTemplate
		}
		this.onDelete = this.onDelete.bind(this);
		this.validate = this.validate.bind(this);
	}
    componentDidMount() {
		// console.log('test')
	}

	onDelete(event) {
		this.props.onSave(event, true)
	}

	validate(event) {
		// Validate Form
		let validation = {};
		validation = validateForm(this.props.tempContractData, contractsFormValidationFields);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			this.props.onSave(event);
		}
	}

	render() {
		const deleteButton = this.props.isEditForm ? 
			<Button bsStyle="danger" onClick={this.onDelete}>{<Translate value='components.customers.deleteContract'/>}</Button>
			:
			null;

		var title = this.props.isEditForm ? 
			<Translate value='components.customers.editContract'/>
			:
			 <Translate value='components.customers.addNewContract'/>;
			 
		return(
			<Col md={6}>
				<h4>{title}</h4>
				<DataDropdown 
					label={<Translate value='components.customers.type'/>} 
					name="type" 
					data={{'1': 'Total $ Amount', '2': 'Time'}} 
					value={this.props.tempContractData["type"]} 
					action={this.props.handleInputChangedTempContract}
					errMsg={this.state.errors['type']}
				/>
				<InputText 
					required 
					type='DateTime' 
					label={<Translate value='components.customers.startDate'/>} 
					id='startDate' 
					name='startDate' 
					value={this.props.tempContractData["startDate"]} 
					action={this.props.handleInputChangedTempContract}
					errMsg={this.state.errors.startDate}
				/>
                {this.props.tempContractData["type"] == 2 && <InputText 
					required 
					type='DateTime' 
					label={<Translate value='components.customers.endDate'/>} 
					id='endDate' 
					name='endDate' 
					value={this.props.tempContractData["endDate"]} 
					action={this.props.handleInputChangedTempContract}
					errMsg={this.state.errors.endDate}
				/>}
				{
					this.props.tempContractData["type"] == 1 && <InputText 
					label={<Translate value='components.customers.totalAmount'/>} 
					name='totalAmount'
					id='totalAmount' 
					value={this.props.tempContractData["totalAmount"]}
					action={this.props.handleInputChangedTempContract}
					errMsg={this.state.errors.totalAmount}
				/>
				}
				<DataDropdown 
					label={<Translate value='components.customers.status'/>} 
					name="status" 
					data={{'1': 'Active', '2': 'Inactive'}} 
					value={this.props.tempContractData["status"]} 
					action={this.props.handleInputChangedTempContract}
					errMsg={this.state.errors['status']}
				/>
				<InputText 
					label={<Translate value='components.customers.equipment'/>} 
					type={"TextArea"} 
					id={"equipment"} 
					name={"equipment"} 
					action={this.props.handleInputChangedTempContract} 
					value={this.props.tempContractData['equipment']}
					errMsg={this.state.errors.equipment}
				/>
				<InputText 
					label={<Translate value='components.customers.PDF'/>} 
					type="File" 
					name="pdfLocation"
					action={(data, fileInfo) => {
						if(data.target != null) {
							this.props.handleInputChangedTempContract(data, "pdfLocation");
						}
					}}
				/>
				<Row>
					<Col md={3}>
						{deleteButton}
					</Col>
					<Col md={3}>
						<Button onClick={this.props.close}>{<Translate value='components.customers.close'/>}</Button>
					</Col>
					<Col md={3}>
						<Button bsStyle="primary" onClick={this.validate}>{<Translate value='components.customers.saveContract'/>}</Button>
					</Col>
				</Row>
			</Col>
		)
	}
}



		
		

	
export default CustomerPopin;
