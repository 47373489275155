import {getAPI} from './common.js';

// ========================================================== WARNING ==========================================================
//       These constants are returned as funcitons which return promises. Handle any formatting within a .then() statement

export const salesReps = () =>
    getAPI("users/salesreps")
    .then(res => res['data']);

export const countries = () =>
    getAPI("common/countries")
    .then(res => res['data']);

export const emailTemplates = () =>
    getAPI("common/emailTemplates")
    .then(res => res['data']);

export const paymentTerms = () =>
    getAPI("common/paymentterms")
    .then(res => res['data']);

export const deltaETypes = () =>
    getAPI("common/deltaetypes")
    .then(res => res['data']);

export const contactTypes = () =>
    getAPI("common/contacttypes")
    .then(res => res['data']);

export const observerAngles = () =>
    getAPI("common/observerangles")
    .then(res => res['data']);

export const illuminants = () =>
    getAPI("common/illuminants")
    .then(res => res['data']);

export const cieConstants = () =>
    getAPI("common/cieconstants")
    .then(res => res['data']);

export const devices = () =>
    getAPI("common/devices")
    .then(res => res['data']);

export const shippers = () =>
    getAPI("shippers/list")
    .then(res => res['data']);

export const orderStatuses = () =>
    getAPI("orders/statuses")
    .then(res => res['data']);

export const orderTypes = () =>
    getAPI("orders/types")
    .then(res => res['data']);

export const formulaStatuses = () =>
    getAPI("formulas/statuses")
    .then(res => res['data']);

export const componentStatuses = () =>
    getAPI("components/statuses")
    .then(res => res['data']);

export const purchaseorderStatuses = () =>
    getAPI("purchaseorders/statuses")
    .then(res => res['data']);

export const customerStatuses = () =>
    getAPI("customers/statuses")
    .then(res => res['data']);

export const userTypes = () =>
    getAPI("users/types")
    .then(res => res['data']);

export const substrates = () =>
    getAPI("formulas/substrates")
    .then(res => res['data']);

export const umCodes = () =>
    getAPI("customers/umcodes")
    .then(res => res['data']);

export const viscosityCups = () =>
    getAPI("common/viscositycups")
    .then(res => res['data']);

export const dryerTypes = () =>
    getAPI("customers/presses/dryertypes")
    .then(res => res['data']);

export const meteringSystems = () =>
    getAPI("customers/presses/meteringsystems")
    .then(res => res['data']);

export const bladeTypes = () =>
    getAPI("customers/presses/bladetypes")
    .then(res => res['data']);

export const containersTypes = () => 
	getAPI("components/vendors/containertypes")
	.then(res => res['data']);
	
export const corporates = () => 
	getAPI("customers/list")
	.then(res => res['data']);