export default {
	'customers': {
        'status': 'Status',
        // Tab titles
        'customerInfo': 'Customer Info',
        'additionalAddresses': 'Additional Addresses',
        'contracts': 'Contracts',
        'contacts': 'Contacts',
        'orders': 'Orders',
        'processNotes': 'Process Notes',
        'presses': 'Presses',
        // column titles
        'userName': 'User Name',
        'lastUsed': 'Last Used',
        'loginCount': 'Login Count',
        'phoneNumber': 'Phone Number',
        'billingAddress': 'Billing Address',
        'id': 'ID',
        'name': 'Name',
        'phone': 'Phone',
        'lastSalesOrderDate': 'Last Order Date',
        // general fields
        'addresses':'Addresses',
        'addNewAddress':'Add New Address',
        'defaultMeasurementConditions':'Default Measurement Conditions',
        'tierDiscounts': 'Tiered Volume Discounts',
        'miscInfo':'Miscellaneous Info',
        'mainAddress':'Main Address',
        'customerName': 'Customer Name',
        'address1': 'Address 1',
        'address2': 'Address 2',
        'city': 'City',
        'country': 'Country',
        'stateProvince': 'State/Province',
        'zipCode': 'Zip Code',
        'phone': 'Phone',
        'fax': 'Fax',
        'clientUsers':'Client Users',
        'salesReps': 'Sales Reps',
        'salesGoal': 'Sales Goal',
        'defaultShipper': 'Default Shipper',
        'defaultShipTime': 'Default Ship Time',
        'umCode': 'UM Code',
        'customerTerms': 'Customer Terms',
        'discountPercent': 'Discount %',
        'customerSince': 'Customer Since',
        'printHmis': 'Print HMIS?',
        'notes': 'Notes',
        'illuminant': 'Illuminant',
        'observerAngle': 'Observer Angle',
        'deltaE': 'ΔE',
        'device': 'Device',
        'colorTolerance': 'Color Tolerance',
        'setupReadCount': 'Setup Read #',
        'productionReadCount': 'Production Read #',
        'targetReadCount': 'Target Read #',
        'qcReadCount': 'QC Read #',
        'addAddress': 'Add Address',
        'editAddress': 'Edit Address',
        'saveAddress': 'Save Address',
        'newCustomer': 'New Customer',
        'deleteAddress': 'Delete Address',
        'editContact': 'Edit Contact',
        'addNewContact': 'Add New Contact',
        'type': 'Type',
        'mobileNumber': 'Mobile Number',
        'deleteContact': 'Delete Contact',
        'saveContact': 'Save Contact',
        'spanish': 'Spanish',
        'email': 'E-Mail Address',
        'addContact': 'Add Contact',
        'close': 'Close',
        'saveChanges': 'Save Changes',
        'deleteCustomer': 'Delete Customer',
        'areYouSure': 'Are you sure you want to delete this customer?',
        'delete': 'Delete',
        'cancel': 'Cancel',
        'formulas': 'Formulas',
        'formulaNumber': 'Formula #',
        'substrate': 'Substrate',
        'anilox': 'Anilox',
        'price': 'Price',
        'lastOrdered': 'Last Ordered',
        'addNewPress': 'Add New Press',
        'pressInformation': 'Press Information',
        'pressName': 'Press Name',
        'pressType': 'Press Type',
        'vacuumTransfer': 'Vacuum Transfer',
        'varnishPackage': 'Varnish Package',
        'phMin': 'pH Min',
        'phMax': 'pH Max',
        'blendingSystem': 'Blending System',
        'baseSystem': 'Base System',
        'viscosityRange': 'Viscosity Range Din 4',
        'viscosityCup': 'Viscosity Cup',
        'lastUpdatedBy': 'Last Updated By',
        'lastUpdatedOn': 'Last Updated On',
        'specialNotes': 'Special Notes',
        'station': 'Station',
        'dryers': 'Dryers',
        'meteringSystem': 'Metering System',
        'bladesType': 'Blades Type',
        'uv': 'UV',
        'deleteStation': 'Delete Station',
        'newStation': 'New Station',
        'anilox': 'Anilox',
        'volume': 'Volume',
        'matchWith': 'Match With',
        'deleteAnilox': 'Delete Anilox',
        'newAnilox': 'New Anilox',
        'numberOfStations': '# Stations',
        'save': 'Save',
        'orderStatus': 'Order Status',
        'orderType': 'Order Type',
        'orderId': 'Order Id',
        'orderedDate': 'Ordered Date',
        'orderAmount': 'Order Amount',
        'note': 'Note',
        'tier1': 'Tier 1',
        'tier2': 'Tier 2',
        'tier3': 'Tier 3',
        'tieredVolumeDiscount': 'Tiered Volume Discounts',
        'tier1Discount': 'Discount %',
        'tier2Discount': 'Discount %',
        'tier3Discount': 'Discount %',
        'tier1StartVolume': 'Start Volume',
        'tier2StartVolume': 'Start Volume',
        'tier3StartVolume': 'Start Volume',
        'tier1EndVolume': 'End Volume',
        'tier2EndVolume': 'End Volume',
        'tier3EndVolume': 'End Volume',
        'drawDownsNeeded': 'Draw Downs Needed',
        'drawDownsSent': 'Draw Downs Sent',
        'lsdNeeded': 'LSD Needed',
        'lsdSent': 'LSD Sent',
        'addNote': 'Add Note',
        'processedBy': 'Processed By',
        'processedDate': 'Processed On',
        'saveNote': 'Save Note',
        'deleteNote': 'Delete Note',
        'editNote': 'Edit Note',
        'createFirstStations': 'Please first create and save basic press data before editing stations.',
        'createFirstAniloxes': 'Please first create and save basic press data before editing aniloxes.',
        'editPress': 'Edit Press',
        'emailsRecieved': "Emails Received",
        'proccessed': "Processed",
		'lrNotes': 'LR Notes',
		'bFuelSurcharge': 'Fuel Surcharge',
		'bFreightSurcharge': 'Freight Surcharge',
		'bPlasticDrums': 'Plastic Drums',
		'bCustomizeBarcode': 'Smaller Barcode',
		'consignmentCustomer': 'Consignment Customer',
		'autoPrintCofA': 'Print C of A',
		'bSpectro1': 'Spectro1',
		'bWetSample': 'Wet Sample',
		'underContract': 'Under Contract',
		'contractExpires': 'Contract Expires',
		'currentPrimaryInkSupplier': 'Current Primary Ink Supplier',
		'blendingSystem': 'Blending System',
		'numberOfHeads': 'Number of Heads',
		'customerOwned': 'Customer Owned',
		'condition': 'Condition',
		'estimatedInkSpendFit': 'Estimated Annual Ink Spend Fit for BCM',
		'fittedBusinessCaptured': 'Percent of Fitted Business Captured',
		'bcmCurrentPosition': 'BCM Current Position',
		'prepressPrintingPlates': 'Prepress/Printing Plates',
		'aniloxRollProvider': 'Anilox Roll Provider',
		'dryersLampsProvider': 'Dryers/Lamps Provider',
		'otherRelativeSuppliers': 'Other Relative Suppliers',
		'startDate': 'Start Date',
		'endDate': 'End Date',
		'totalAmount': 'Total Amount',
		'totalSales': 'Total Sales',
		'equipment': 'Equipment',
		'percentTotalContract': '% to Total Contract',
		'editContract': 'Edit Contract',
		'addContract': 'Add Contract',
		'saveContract': 'Save Contract',
		'deleteContract': 'Delete Contract',
		'download': 'Download',
		'contract': 'Contract',
		'otherSuppliers': 'Other Suppliers',
		'defaultPrints': 'Default Prints',
		'defaultWeights': 'Default Weights',
		'bUseZahn2EZCup': 'Use Zahn2EZ Cup',
		'viscosityCupConversion': 'Viscosity Cup Conversion',
		'shipmentOutlier': 'Shipment Outlier',
		'matchesFound': 'The customer name matches the following existing ones:',
		'saveAnyway': 'Save Anyway',
		"isCorporate": "Is Corporate",
		"parentCorporate": "Parent Corporate",
    }
}
