export default {
	"tooltips": {
		"jobInterface": "Color ConneXion allows the user to login and access the Color ConneXion application.",
		"admin": "Any user with the 'Admin' security rights can set other users as Administrators of the Color ConneXion Application and control Color ConneXion related functions.",
		"setTarget": "Allows any user to set/re-set targets as appropriate in the Color ConneXion application.",
		"deleteColorConnexion": "Allows any user to delete jobs and colors as appropriate in the Color ConneXion application.",

		"email": "E-mail address belonging to this contact.  The e-mail address will be used to send notification regarding GSTS, HFi, etc....",
		"contactType": "The contact type allows certain groups of contacts to be grouped together and displayed in lists, etc.",
		"email": "E-mail is a required field since it is necessary in order to reset the user's password.",
		"missingEmail": "You must enter the e-mail address before the user is allowed to be a graphics facilitator.",
		"password": "Enter the password for the user.  The password must consist of at least 1 uppercase letter, 1 number, 1 lowercase letter, and must be at least 7 alphanumeric characters in length.",
		"email": "E-mail is a required field since it is necessary in order to reset the user's password.",
		"vendorName": "Enter the vendor name.",
		"shipperName": "Enter the shipper name.",
		"createColor": "Allows users to create colors (and perform any function included in that process).",
		"editColor": "Allows users to edit colors.",
		"jobInterface": "Job Interface allows the user to login and access the Job Interface application.",
		"admin": "Any user with the 'Admin' security rights can set other users as Administrators of the Job Interface Application and control Job Interface related functions.",
		"setTarget": "Allows any user to set/re-set targets as appropriate in the Job Interface application.",
		"deleteJobInterface": "Allows any user to delete jobs and colors as appropriate in the Job Interface application.",
		"userMaintRights": "Allows the user access to the 'User Rights' permission group.",
		"userMaintAccess": "Allows the user access to the 'Program Access' permission group.",
		"userMaintCustomer": "Allows the user to administrate the 'Customer Access' permission group.",
		"userMaint": "Allows the user to perform maintenance of any kind on the User screen.  This right is required for the user to be able to save/delete user information.  ",
		"allowSetTarget": "Allows the user to set or re-set targets in the Job Interface application.",
		"userMaintReports": "Allows the user access to the 'Report Access' permission group.",
		"maintainJobFlag": "Allows the user to create and edit jobs.",
		"deactivateFormula": "Allows the user to deactivate formulas when the formula is no longer needed.",
		"deleteFormula": "Allows the user to delete formulas when the formula is no longer needed.",
		"performReads": "Allows the user to perform reads on a color or formula and also enter meta data for the read (viscosity, pH, and dryer percentage).",
		"jobNotesFlag": "Allows the user to add notes to a job.",
		"hazardList": "Available hazard pictograms to pick from are: ",
		"bucketReport": "To print the Bucket Label, select a formula on the sales order and verify that it has been QC'd or completed.  Only QC'd or completed formulas can be printed.",
		"customer": "Double-click the customer name to open the Customer Screen.",
		"processNotesExist": "Process notes exist for this customer.  Click the exclamation point to navigate to the process notes screen.",
		"completedItem": "Items cannot be deleted from a Lab Request that have already been turned into formulas.",
		"completedItems": "A Lab Request cannot be deleted once color matches have been completed.",
		"noSendEmail": "Removes the formula from the list without sending an e-mail to the customer.",
		"sendEmail": "Sends an e-mail to the customer with the formula pricing for the selected formulas.",
		"download": "Indicates which colors to download as CxF or MIF files.",
		"readSuccess": "Read Successful!",
		"deactivated": "Reads cannot be performed on an inactive formula.",
		"previous": "Loads the prior measurement, if one exists",
		"next": "Loads the next measurement, if one exists",
		"last": "Returns the most recent measurement",
		"first": "Returns the oldest measurement",
		"duplicateCheck": "Compares the job name and description against existing jobs and notifies the user if a job already exists with the same name/description.",
		"download": "Indicates which formulas to download as CxF or MIF files.",
		"search": "Enter the term to search for and hit the 'ENTER' key.",
		"filter": "Clears the filter. ",
		"download": "Indicates which color matches to download as CxF or MIF files.",
		"instrumentsDisabledFlag": "Disables the user from changing instrument sections when performing a read",
		"visibleToCustomerFlag": "Whether a formula is visible to a customer",
		"shipmentOutlierAccessFlag": "Allows user access to the Shipment Outlier checkbox in Customer Orders",
	}
}
