import React, { Component } from 'react';
import { Row, Col, Modal, Button, Form, ButtonGroup } from 'react-bootstrap';
import DataDropdown from '../common_components/DataDropdown.js';
import InputText from '../common_components/InputText.js';
import DataGrid from '../common_components/DataGrid.js';
import Order from '../orders/Order.js';
import NewFormulaForm from '../formulas/NewFormulaForm.js';
import CustomerPopin from '../customers/CustomerPopin.js';
import {mapArrayToObject, convertTimestampToDate, convertDateToTimestamp, http_build_query, getAPI, postAPI, validateForm, resetObject, mergeObjects, lab_request_lookup_id, credit_memo_lookup_id, sales_order_lookup_id, orderTypes, getLoggedInUser, openBirtReport, constants, toCurrency, getDefaultBillOfLadingInstructions, calculateTaxRate, sendEmail, addDaysToDateTimestamp, convertISOtoShortDate, showFormValidationAlert, containerKgWeightsToOverride, quickbooksAPI} from '../common_components/common.js';
import {MyInkIQReactGA} from '../common_components/common.js';
import Config from '../Config';
import '../FormPage.css';
import config from '../Config';
const { Translate } = require('react-i18nify');
require('../Config.js');

const orderStatusesByTypes = {
	[credit_memo_lookup_id]: {
		129: 'Entered', // <Translate value='components.labels.entered' />,
		130: 'Confirmed', // <Translate value='components.labels.confirmed' />,
		131: 'Completed', // <Translate value='components.labels.completed' />,
	}, 
	[lab_request_lookup_id]: {
		124: 'Entered', // <Translate value='components.labels.entered' />,
		125: 'Confirmed', // <Translate value='components.labels.confirmed' />,
		126: 'Completed', // <Translate value='components.labels.completed' />,
	}, 
	[sales_order_lookup_id]: {
		117: 'Entered', // <Translate value='components.labels.entered' />,
		118: 'Confirmed', // <Translate value='components.labels.confirmed' />,
		119: 'Quoted', // <Translate value='components.labels.quoted' />,
		120: 'Invoiced', // <Translate value='components.labels.invoiced' />,
		121: 'Shipped/Invoiced', // <Translate value='components.labels.shippedInvoiced' />,
		122: 'Shipped', // <Translate value='components.labels.shipped' />,
		123: 'Received', // <Translate value='components.labels.received' />,
	},
}

const late = {
	"late": "Late",
	"lost": "Lost",
	"damage": "Damaged",
}

const lrType = {
	[constants.lr_type_color_match_lookup_id]: "Color Match",
	[constants.lr_type_rnd_lookup_id]: "R & D",
	[constants.lr_type_repull_lookup_id]: "Repull",
}

const orderStatusesConstants = {
	[credit_memo_lookup_id]: {
		129: 'Entered',
		130: 'Confirmed',
		131: 'Completed',
	}, 
	[lab_request_lookup_id]: {
		124: 'Entered',
		125: 'Confirmed',
		126: 'Completed',
	}, 
	[sales_order_lookup_id]: {
		117: 'Entered',
		118: 'Confirmed',
		119: 'Quoted',
		120: 'Invoiced',
		121: 'Shipped/Invoiced',
		122: 'Shipped',
		123: 'Received',
	},
}
// var indexedContainersList = {};

function calculateExtPrice(price, umOrdered, discountAmt) {
	var extPrice = price * umOrdered - discountAmt;
	return extPrice;
}


class OrderFormDeleteConfirmation extends Component {
	onDelete() {
		this.props.onConfirm();
		this.props.onClose();
	}

	render() {
		return(<Modal show={this.props.visible}>
			<Modal.Body><Translate value='components.labels.deleteOrderConfirmationPrompt' /></Modal.Body>
			<Modal.Footer>
				<Button bsStyle='danger' onClick={() => this.onDelete()}><Translate value='components.buttons.delete' /></Button>
				<Button onClick={this.props.onClose}><Translate value='components.buttons.cancel' /></Button>
			</Modal.Footer>
		</Modal>);
	}
}

const validationFields = {
	customerId: "required",
	orderTypeLookupId: "required",
	orderStatusLookupId: "required",
	salesRepActorId: "required",
	shippingAddressId: "required,positive",
	scheduledDeliveryDate: "required",
};

const salesOrderValidationFields = {
	billOfLadingLookupId: "",
	shipperId: "required",
	orderDiscountCreditAmt: "numeric",
	skidChargeAmt: "numeric",
	freightChargeAmt: "numeric",
	fuelSurchargeAmt: "numeric",
};

const creditMemoValidationFields = {
	shipperId: "required",
};

const labRequestValidationFields = {
	// inkTypeLookupId: "",
	// varnishTypeLookupId: "",
	deliveryTypeLookupId: "",
	needPrints: "numeric",
	sendPrints: "numeric",
	needLSDPrints: "numeric",
	sendLSDPrints: "numeric",
	// substrates: "required",
};

const labRequestCaptions = {
	customerId: "Customer",
	salesRepActorId: "Sales Rep",
	customerContactId: "Contact",
	consignmentFlag: "Consignment",
	consignmentBillingFlag: "Consignment Billing",
	shippingAddressId: "Shipping Address",
	notes: "Notes",
	lrnotes: "LR Notes",
	inkTypeLookupId: "Ink Type",
	varnishTypeLookupId: "O/P Varnish",
	customerPressId: "Press",
	colorTraps: "Color Traps",
	needPrints: "Need Prints",
	sendPrints: "Send Prints",
	needLSDPrints: "Need LSD Prints",
	sendLSDPrints: "Send LSD Prints",
	deliveryTypeLookupId: "Delivery Type",
	shipDate: "Ship Date",
	shipperTrackingNumber: "Shipper Tracking Number",
	scheduledDeliveryDate: "Delivery Date",
};

class OrderForm extends Component {


	constructor(props){
		super(props);
		this.reactGA = new MyInkIQReactGA();
		
		const errorsTemplate = mergeObjects(resetObject(validationFields), mergeObjects(resetObject(salesOrderValidationFields), resetObject(labRequestValidationFields)));
		this.state = {
			// bFormSubmit: false,
			// mode:	'', // Insert / Edit mode
			bShowDeleteConfirmation: false,
			bShowOrderFormulaForm: false, // Used to toggle formula form
			bShowBillOfLadingInstructionsForm: false,
			bShowCustomerPopin: false,
			bReRender: true,
			bEditMode: false,
			editOrderId:	'',
			bFormSubmitted: false,	// When the Save button is clicked
			bFormValidated: true,	//	When the form validation is successful after clicking save
			bSaveSuccessful: false,	//	When the Save operation is successful
			bShowNewFormulaForm: false,
			bReasonLateFlag: false,
			bReasonLostFlag: false,
			bReasonDamagedFlag: false,
			saveDisabled: false,
			currentDate: new Date(),
			title: 'New Sales Order',
			formData: {
				aniloxUsed:null,
				billOfLadingInstructions:"",
				billOfLadingLookupId:31, // Pre-Paid
				colorMatchConfirmationEmailSentDate:null,
				colorMatchEmailSentDate:null,
				colorTraps:null,
				confirmationDate:null,
				consignmentBillingFlag:false,
				consignmentFlag:false,
				createdDate:null,
				creditMemoEmailSentDate:null,
				customerContactId:0,
				customerId:null,
				customerPressId:0,
				deliveryTypeLookupId:37, // Sent by Fed Ex
				drawDownLookupId:0,
				freightChargeAmt:0,
				fuelSurchargeAmt:0,
				amountClaimed:0,
				amountReceived:0,
				latecomments:"",
				id:0,
				inkTypeLookupId:0,
				invoiceDate:0,
				invoiceEmailSentDate:null,
				model:"com.phototype.myinkiq.model.vos.dtos::SalesOrdersDto",
				name:null,
				needLSDPrints:0,
				needPrints:0,
				notes:"",
				orderConfirmationEmailSentDate:null,
				orderDate:0,
				orderDiscountCreditAmt:0,
				orderInvoiceNotes:"",
				orderPackingSlipNotes:null,
				orderPreferencesNote:"",
				orderStatusLookupId: this.props.orderTypeLookupId == lab_request_lookup_id ? 124: 117, // Entered
				orderTypeLookupId: this.props.orderTypeLookupId ? this.props.orderTypeLookupId :  sales_order_lookup_id,
				poNumber:"",
				quoteEmailSentDate:null,
				retired:false,
				salesRepActorId:0,
				scheduledDeliveryDate:0,
				sendLSDPrints:0,
				sendPrints:0,
				shipDate:0,
				newDeliveryDate:"",
				shipperId:0,
				shipperTrackingNumber:"",
				shippingAddressId:0,
				shippingConfirmationEmailSentDate:null,
				skidChargeAmt:0,
				skidCount:0,
				statusDate:0,
				statusName:"Entered",
				totalOrderAmt:0,
				varnishTypeLookupId:0,
				taxAmount: null,
				grandTotal: null,
				lrnotes: "",
				certificateOfAnalysisEmailSentDate:null,
				bLateLostDamaged: false,
				lateLostDamagedReason: null,
				lateLostDamagedAmountClaimed: null,
				lateLostDamagedAmountReceived: null,
				lateLostDamagedAmountComment: null,
				lateLostDamagedDeliveryDate: "",
				lrTypeLookupId: constants.lr_type_color_match_lookup_id,
				bShipmentOutlier: false,
				umCodeLookupId: constants.default_um_code,
			},
			errors: errorsTemplate,
			initialData: {},
			initialSubstrates: [],
			initialOrderFormulas: [],
			modifiedData: {},
			orderFormulas: [],
			orderSubstrates: [],
			
			activeCustomers: [],
			salesRepList: [],
			contactList: [],
			shippingAddressList: [],
			billOfLadingTypesList: [],
			shipperList: [],
			pressesList: [],
			inkTypesList: [],
			varnishesList: [],
			deliveryTypesList: [],
			substratesList: [],
			substrates: [],
			comments: [],
			labTechsList: [],
			containerTotals: [],

			customerFormulasList: [],
			customerFormulaPricing: [],
			customerDefaultKey: 5,
			containersList: [],
			customerInfo: null,
			hasUnprocessedNotes: false,
			initStatusId: null,
			initStatusName: null,

			errMsgcustomerId: '',
			errMsgorderTypeLookupId: '',
			errMsgorderStatusLookupId: '',
			errMsgsalesRepActorId: '', // Selected Sales Rep
			errMsgshippingAddressId: '',
			errMsgbillOfLadingLookupId: '',
			errMsgshipperId: '',
			errMsgscheduledDeliveryDate: '',

			errMsgsubstrates: '',
			errMsgdeliveryTypeLookupId: '',

			billingAddress: '', 
			email: '',
			
			editOrderFormula: null,
			selectedOrderFormulaIndex: null,
			deletedOrderFormulas: [],
			orderStatusesByTypes: orderStatusesByTypes,
			
			labRequestFormulaIndex: null, // Index of the 
			salesOrderFormulaReferenceId: null, // Index of the 
			formulaLabRequestId: null, // Whether or not to show the formula form after saving a lab request
			reportDetails: [],
			deletedReportDetailIDs: [],
			taxRate: null,
			companyInfo: null,
			customerContactEmailsList: [],
			editOrderId: this.props.editOrderId,
			bShipDateChanged: false,
			bFreightChargeRequired: false,
			bFuelChargeRequired: false,
			bPlasticDrumsCustomer: false,
			bConsignmentCustomer: false,
			bCustomerSelected: false, // to keep track of whether a customer is selected via dropdown
		};
		
		this.onAddOrderFormula = this.onAddOrderFormula.bind(this);
		this.onRemoveOrderFormula = this.onRemoveOrderFormula.bind(this);
		this.onSelectOrderFormula = this.onSelectOrderFormula.bind(this);
		this.onEditOrderFormula = this.onEditOrderFormula.bind(this);
		this.onSaveOrderFormula = this.onSaveOrderFormula.bind(this);
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.handleSaveClicked = this.handleSaveClicked.bind(this);
		this.onDelete = this.onDelete.bind(this);
		
		// Common functions
		this.calculateTotalOrderAmount = this.calculateTotalOrderAmount.bind(this);
		this.getCustomerData = this.getCustomerData.bind(this);
		this.getLabRequestData = this.getLabRequestData.bind(this);
		this.chkQCDateChecked = this.chkQCDateChecked.bind(this);
		this.chkCompletedDateChecked = this.chkCompletedDateChecked.bind(this);
		this.chkStartDateChecked = this.chkStartDateChecked.bind(this);
		this.onLabRequestStatusChanged = this.onLabRequestStatusChanged.bind(this);
		this.saveFormData = this.saveFormData.bind(this);
		this.getOrderData = this.getOrderData.bind(this);
		this.initData = this.initData.bind(this);
		this.sendLREmail = this.sendLREmail.bind(this);
		// this.calculateTaxRate = this.calculateTaxRate.bind(this);
		this.getQboInvoiceInfo = this.getQboInvoiceInfo.bind(this);
	}
	
		
	componentWillReceiveProps(nextProps) {
	}

	componentDidUpdate() {
	}
	
	async getCustomerData(customerId, realChange = false) {
		const loggedInUser = getLoggedInUser();
		const selectedCustomer = this.state.activeCustomers.filter(obj => obj != undefined && obj.id == customerId)[0];
		
		var formData = this.state.formData;
		
		// Setting Scheduled Delivery Date to a default ship days later than the current date
		// And the time is set to 5:00 PM
		if(!this.state.editOrderId) {
			/*
			formData['scheduledDeliveryDate'] = convertDateToTimestamp(
				convertTimestampToDate(this.state.currentDate) + " 17:00") + 
			+ (selectedCustomer && selectedCustomer['defaultShipTime'] ? 
				parseInt(selectedCustomer['defaultShipTime']) * 86400000 : 0 );
			*/
			// Samee - You don't need to change the delivery date upon selecting a customer.
			
			const timeStr = formData['orderTypeLookupId'] == lab_request_lookup_id ? " 10:30" : " 17:00";
			const currentTimestamp = convertDateToTimestamp(
				convertTimestampToDate(this.state.currentDate) + timeStr
			);
			let numDays = parseInt(selectedCustomer['defaultShipTime']);
			
			switch(formData.orderTypeLookupId) {
				case sales_order_lookup_id:
					formData.billOfLadingLookupId = 31; // Pre-Paid
					break;
				
				case lab_request_lookup_id:
					numDays = 1;
					formData.deliveryTypeLookupId = 37; // Sent By Fed-Ex
					break;
			}
			formData['scheduledDeliveryDate'] = addDaysToDateTimestamp(currentTimestamp, numDays, true, timeStr);
		}
		await getAPI("customers/" + customerId).then(resCustomer => {
			getAPI("customers/" + customerId + "/contacts/list").then(resCustomerContactList => {
				getAPI("customers/" + customerId + "/shippingaddresses").then(resCustomerShippingAddress => {
					getAPI("customers/" + customerId + "/salesreps").then(resCustomerSalesRep => {
						let salesRepList = resCustomerSalesRep['data'];
						getAPI("customers/" + customerId + "/comments").then(resComments => {
							getAPI("customers/" + customerId + "/presses/list").then(resPressesList => {
								getAPI("customers/" + customerId + 
									"/notes/unprocessed").then(resNotesList => {
									getAPI("salesorders/customers/" + customerId + "/contacts?emailType=Shipment%20Confirmation").then(resEmails => { 
										
										// console.log('TEST:', )
										
										const billingAddress = resCustomerShippingAddress['data'].filter(obj => obj.billingAddressFlag == true).length > 0 ? resCustomerShippingAddress['data'].filter(obj => obj.billingAddressFlag == true).map(obj => {
											return obj.address1 
											+ (obj.address2 ? " " + obj.address2 : "")
											+ (obj.city ? " " + obj.city : "")
											+ (obj.stateCode ? ", " + obj.stateCode : "")
											+ (obj.zipCode ? " " + obj.zipCode : "");
										}).join(", ")
										:
										resCustomer['data'].map(obj => {
											return obj.address1 
											+ (obj.address2 ? " " + obj.address2 : "")
											+ (obj.city ? ", " + obj.city : "")
											// + (obj.stateId ? ", " + obj.stateId : "") no state code in customer data
											+ (obj.zipCode ? " " + obj.zipCode : "");
										}).join(", ");
										// console.log("resCustomerShippingAddress['data']: ", resCustomerShippingAddress['data'], ", resCustomer['data']: ", resCustomer['data']);
							
		
										const email = resEmails['data'].map(obj => {
											return obj.emailAddress;
										}).join(", ");
										
										if(!formData['taxAmount']) {
											formData = calculateTaxRate(formData, this.state.taxRate,
												resCustomer['data'][0].taxExempt, formData['totalOrderAmt']);
										}

										// adding default values from customer data NOT NEEDED FOR THESE I THINK - michael
										// if(realChange) {
										// 	formData.shipperId = resCustomer['data'][0].defaultShipperId
										// }
										// if(realChange) {
										// 	formData.shippingAddressId = resCustomer['data'][0].addressId
										// }
										if(realChange) {
											const mincustrepid = salesRepList.reduce((min, p) => p.id < min ? p.id : min, salesRepList[0].id);
											// Samee - What's with this?!
											// formData.salesRepActorId = salesRepList.filter(obj => obj.id == mincustrepid)[0].actorId
										}
										const unProcessedNotes = resNotesList['data'];
										// console.log("resNotesList['data']: ", resNotesList['data']);
										
										
										
										
										if(!this.state.editOrderId) {
											// Setting Default Sales Rep ID
											if(salesRepList[0])
												formData.salesRepActorId = salesRepList[0].actorId;
											
											// Setting Default Shipping Address ID
											if(resCustomerShippingAddress['data'][0])
												formData.shippingAddressId = resCustomerShippingAddress['data'][0].addressId;

											if(resCustomer['data'][0]) {
												// Setting Default Shipper Id
												formData.shipperId = resCustomer['data'][0].defaultShipperId;
												// Setting Customer Preferences / Notes
												formData.orderPreferencesNote = resCustomer['data'][0].notes;
												formData.lrnotes = resCustomer['data'][0].lrnotes;
												
												// Drawdown and LSD Prints
												formData.needPrints = resCustomer['data'][0].drawDownsNeeded;
												formData.sendPrints = resCustomer['data'][0].drawDownsSent;
												formData.needLSDPrints = resCustomer['data'][0].lsdNeeded;
												formData.sendLSDPrints = resCustomer['data'][0].lsdSent;
												
												formData.umCodeLookupId = resCustomer['data'][0].umCodeLookupId;

											}
										} else {
											if(this.state.bCustomerSelected) {
												if(resCustomer['data'][0]) {
													// Setting Customer Preferences / Notes
													formData.orderPreferencesNote = resCustomer['data'][0].notes;
													formData.lrnotes = resCustomer['data'][0].lrnotes;
												}
											}
											
											// console.log("salesRepList before: ", salesRepList);
											const orderSalesRepId = formData.salesRepActorId;
											let rowsSalesReps = salesRepList.filter(row => row.actorId == orderSalesRepId);
											// console.log("rowsSalesReps: ", rowsSalesReps, ", orderSalesRepId: ", orderSalesRepId);
											if(rowsSalesReps.length == 0) {
												// add sales rep
												salesRepList.push({id: -1, customerId: formData.customerId, actorId: orderSalesRepId, displayName: formData.salesRepActorName});
											}
											// console.log("salesRepList after: ", salesRepList);
										}
										
										
										let bFuelChargeRequired = this.state.bFuelChargeRequired;
										let bFreightChargeRequired = this.state.bFreightChargeRequired;
										let bPlasticDrumsCustomer = this.state.bPlasticDrumsCustomer;
										let bConsignmentCustomer = this.state.bConsignmentCustomer;
										
										
										// Only applicable for Sales Orders
										if(this.state.formData.orderTypeLookupId == sales_order_lookup_id) {
											if(resCustomer['data'][0]) {
												bFuelChargeRequired = resCustomer['data'][0].bfuelSurcharge;
												bFreightChargeRequired = resCustomer['data'][0].bfreightSurcharge;
												bPlasticDrumsCustomer = resCustomer['data'][0].bplasticDrums;
												bConsignmentCustomer = resCustomer['data'][0].bconsignment;
											}
										}
										
										
										// Fuel / Freight charge need NOT show up if the logged in user is a customer who is placing a new order
										const bCustomerPlacingOrder = loggedInUser.userTypeId == constants.customer_type_id && !this.state.editOrderId;
										bFuelChargeRequired = bFuelChargeRequired && !bCustomerPlacingOrder;
										bFreightChargeRequired = bFreightChargeRequired && !bCustomerPlacingOrder;

										// salesOrderValidationFields["fuelSurchargeAmt"] = bFuelChargeRequired ? "required,numeric,positive" : "numeric";

										// salesOrderValidationFields["freightChargeAmt"] = bFreightChargeRequired ? "required,numeric,positive" : "numeric";

										this.setState({
											contactList: resCustomerContactList['data'],
											shippingAddressList: resCustomerShippingAddress['data'],
											salesRepList: salesRepList,
											comments: resComments['data'],
											pressesList: resPressesList['data'],
											formData: formData,
											customerInfo: resCustomer['data'][0],
											billingAddress: billingAddress,
											email: email,
											hasUnprocessedNotes: unProcessedNotes.length > 0,
											bFuelChargeRequired: bFuelChargeRequired,
											bFreightChargeRequired: bFreightChargeRequired,
											bPlasticDrumsCustomer: bPlasticDrumsCustomer,
											bConsignmentCustomer: bConsignmentCustomer,
											bCustomerSelected: false,
										});
									});
								});
							});
						});
					});
				});
			});
		});
	}
	
	
	getQboInvoiceInfo() {
		let qboInvoiceInfo ={};
		const formData = this.state.formData;
		// console.log("getQboInvoiceInfo() - formData: ", formData, ", this.state.customerInfo: ", this.state.customerInfo);
		const qboCustomerReferenceNum = this.state.customerInfo != null ? this.state.customerInfo.qboCustomerRefId : 0;
		if(qboCustomerReferenceNum > 0) {
			let qboLineItemInfo = [];
			this.state.orderFormulas.forEach(function(row, i){
				let unitPrice = row.price;
				let extPrice = row.umOrdered * unitPrice - row.discountAmt;
				if(row.discountAmt != 0)
					unitPrice = extPrice / row.umOrdered;
				
				let salesItemLineDetail = { 
					"Qty": row.umOrdered, 
					"UnitPrice": unitPrice,
					"DiscountAmt": row.discountAmt,
				};
				
				
				
				if(constants.qboFormulaTypes[row.formulaTypeLookupId] != null) {
					const qboFormulaType = constants.qboFormulaTypes[row.formulaTypeLookupId];
					salesItemLineDetail['ItemRef'] = {
						"value": qboFormulaType['Id'],
						"name": qboFormulaType['Name']
					};
					if(qboFormulaType['IncomeAccountRef'] != null) {
						salesItemLineDetail['ItemAccountRef'] = qboFormulaType['IncomeAccountRef'];
					}
					if(qboFormulaType['ClassRef'] != null)
						salesItemLineDetail['ClassRef'] = qboFormulaType['ClassRef'];
				}
					
				qboLineItemInfo.push({ 
					"Description": "(" + row.formulaNumber + ") " + row.formula, 
					"DetailType": "SalesItemLineDetail", 
					"Amount": extPrice, 
					"SalesItemLineDetail": salesItemLineDetail
				});
			});
			
			// Adding fuel charge if any
			if(formData.fuelSurchargeAmt > 0) {
				qboLineItemInfo.push({ 
					"Description": "FUEL SURCHARGE", 
					"DetailType": "SalesItemLineDetail", 
					"Amount": formData.fuelSurchargeAmt, 
					"SalesItemLineDetail": {
						"ItemRef": {
							"value": "29",
							"name": "FSC"
						},
						"ItemAccountRef": {
							"value": "214",
							"name": "4113 Sales:Sales -Freight"
						},
						"UnitPrice": formData.fuelSurchargeAmt,
						"Qty": 1,
					}
				});
			}
			
			// Adding freight charge if any
			if(formData.freightChargeAmt > 0) {
				qboLineItemInfo.push({ 
					"Description": "FREIGHT CHARGE", 
					"DetailType": "SalesItemLineDetail", 
					"Amount": formData.freightChargeAmt, 
					"SalesItemLineDetail": {
						"ItemRef": {
							"value": "31",
							"name": "FRT"
						},
						"ItemAccountRef": {
							"value": "214",
							"name": "4113 Sales:Sales -Freight"
						},
						"UnitPrice": formData.freightChargeAmt,
						"Qty": 1,
					}
				});
			}
			
			// Adding Skid charge if any
			
			let customFields = [{
				"DefinitionId": "1",
				"Name": "PO Number",
				"Type": "StringType",
				"StringValue": formData.poNumber
			}];
			
			if(formData.salesRepActorId > 0) {
				let rowsSalesReps = this.state.salesRepList.filter(row => row.actorId == formData.salesRepActorId)[0];
				if(rowsSalesReps != null) {
					customFields.push({
					  "DefinitionId": "2",
					  "Name": "Sales Rep",
					  "Type": "StringType",
					  "StringValue": rowsSalesReps.displayName
					 });
				}
			}
			
			qboInvoiceInfo = {
				Line: qboLineItemInfo,
				CustomerRef: {"value": qboCustomerReferenceNum},
				DocNumber: formData.id,
				"TrackingNum": this.state.formData.shipperTrackingNumber,
				"CustomField": customFields,
				"ShipDate": convertTimestampToDate(formData.shipDate, false, false, true),
				"DueDate": convertTimestampToDate(formData.scheduledDeliveryDate, false, false, true),
				// "TotalAmt": this.state.formData.totalOrderAmt,
				"TotalAmt": this.state.formData.grandTotal,
				"Balance": this.state.formData.grandTotal
			};
			// console.log("this.state.customerInfo: ", this.state.customerInfo, ", arrQboTerms: ", arrQboTerms);
			if(this.state.customerInfo.customerTermsId > 0) {
				const arrQboTerms = constants.qboTerms[this.state.customerInfo.customerTermsId]
				qboInvoiceInfo['SalesTermRef'] = {
				 "value": arrQboTerms[0],
				 "name": arrQboTerms[1]
				};
			}
			
			
			
			if(this.state.billingAddress != null) {
				// qboInvoiceInfo['BillAddr'] = this.state.billingAddress;
			}
			
			if(formData.shippingAddressId > 0) {
				
				const rowShippingAddress = this.state.shippingAddressList.filter(obj => obj != undefined && obj.addressId == formData.shippingAddressId)[0];
				const strShippingAddress = rowShippingAddress != undefined ? rowShippingAddress.address1 + (rowShippingAddress.address2 != null ? ' ' + rowShippingAddress.address2 : "") : '';
				
				// if(strShippingAddress != '')
					//qboInvoiceInfo['ShipAddr'] = strShippingAddress;
			}
		}	
		// console.log("invoice request: ", qboInvoiceInfo);
		return qboInvoiceInfo;
		
	}
	
	getLabRequestData() 
	{
		getAPI("common/substrates").then(resSubstratesList => {
			getAPI("salesorders/inktypes/list").then(resInkTypesList => {
				getAPI("salesorders/varnishes/list").then(resVarnishesList => {
					getAPI("salesorders/deliverytypes/list").then(resDeliveryTypesList => {
						getAPI("users/labtechsnrnds").then(resLabTechs => {
							this.setState({
								substratesList: resSubstratesList['data'],
								inkTypesList: resInkTypesList['data'],
								varnishesList: resVarnishesList['data'],
								deliveryTypesList: resDeliveryTypesList['data'],
								labTechsList: resLabTechs['data'],
							});
						});
					});
				});
			});
		});
	}
	
	onLabRequestStatusChanged(orderStatusLookupId, bStatusChanged) {
		var formData = this.state.formData;
		if(orderStatusLookupId == constants.order_status_entered
		|| orderStatusLookupId == constants.lab_request_status_entered
		|| orderStatusLookupId == constants.credit_memo_status_entered) {
			formData['orderStatusLookupId'] = orderStatusLookupId;
			formData['statusName'] = orderStatusesConstants[this.state.formData.orderTypeLookupId][orderStatusLookupId];
			this.setState({
				formData: formData,
			});
			return;
		}
			
		
		let emailType = "";
		const objReportGroupNamesByStatusIDs = {
			[constants.order_status_invoiced]: "Invoice",
			[constants.order_status_shipped_invoiced]: "Invoice",
			[constants.order_status_confirmed]: "Order Confirmation",
			[constants.order_status_quoted]: "Quote",
			[constants.order_status_shipped]: "Shipment Confirmation",
			[constants.credit_memo_status_confirmed]: "Credit Memo Completion",
			[constants.lab_request_status_confirmed]: "Lab Request Confirmation",
			[constants.lab_request_status_completed]: "Lab Request Completion",
		};
		if(objReportGroupNamesByStatusIDs[orderStatusLookupId] != undefined) {
			emailType = objReportGroupNamesByStatusIDs[orderStatusLookupId];
			if(this.state.formData.lrTypeLookupId == constants.lr_type_rnd_lookup_id) {
				emailType = emailType.replace("Lab Request", "Lab Request R&D");
			} else if(this.state.formData.lrTypeLookupId == constants.lr_type_repull_lookup_id) {
				emailType = emailType.replace("Lab Request", "Lab Request Repull");
			}
		}
		
		getAPI("salesorders/customers/" + this.state.formData.customerId + "/contacts?emailType=" + emailType).then(resCustomerContactEmails => {
			
			
			var bShowAlert = false;
			
			if(bStatusChanged) {
				// Samee - Alert warning in case no customer contact exists
				// Applies ONLY to US
				// console.log("this.state.customerContactEmailsList: ", this.state.customerContactEmailsList, ", orderStatusLookupId: ", orderStatusLookupId, ", formData['customerId']: ", formData['customerId'], ", Config.env: ", Config.env, ", this.state.initStatusId: ", this.state.initStatusId);
				if(Config.env != "mex") {
					// If it's not an internal customer
					if(formData['customerId'] != constants.internal_customer_id) {
						// If the status is other than invoiced or shipped/invoiced
						if(orderStatusLookupId == 120 || orderStatusLookupId == 121) {
							var bCustomerHasContact = resCustomerContactEmails['data'].length > 0;
							if(!bCustomerHasContact) {
								bShowAlert = true;
							}
						}
					}
				}
			}
			if(bShowAlert) {
				formData['orderStatusLookupId'] = this.state.initStatusId;
				formData['statusName'] = orderStatusesConstants[this.state.formData.orderTypeLookupId][this.state.initStatusId];
				alert("This customer does not have a contact to receive the invoice");
				this.setState({
					orderStatusesByTypes: orderStatusesByTypes,
					customerContactEmailsList: resCustomerContactEmails['data'],
					formData: formData,
				});
				// console.log("this.state.formData.orderStatusLookupId: ", this.state.formData.orderStatusLookupId);
				
			} else {
				formData['orderStatusLookupId'] = orderStatusLookupId;
				formData['statusName'] = orderStatusesConstants[this.state.formData.orderTypeLookupId][orderStatusLookupId];
				this.setState({
					customerContactEmailsList: resCustomerContactEmails['data'],
					formData: formData,
				});
			}
		});		
	}

	getOrderData(){
		const loggedInUser = getLoggedInUser();
		const editOrderId = this.state.editOrderId;
		var formData = this.state.formData;
		getAPI("formulas/containers/list").then(resContainersList => {
			getAPI("salesorders/billofladingtypes").then(resBillOfLadingTypesList => {
			
				getAPI("shippers/list").then(resShippersList => {
					getAPI("common/current/date").then(resCurrentDate => {
						getAPI("companies").then(resCompanies => {
						
						
					
							const containersList = resContainersList['data'];
							const currentDate = resCurrentDate['data'][0]['currentDate'];
							formData['shipDate'] = currentDate;
							formData['orderDate'] = currentDate;
							formData['invoiceDate'] = currentDate;
							formData['statusDate'] = currentDate;
							//formData['lateLostDamagedDeliveryDate'] = currentDate;
							let numDays = 0;
							if(formData['orderTypeLookupId'] == lab_request_lookup_id) {
								numDays = 1;
							}
							const currentTimestamp = convertDateToTimestamp(
								convertTimestampToDate(this.state.currentDate) // + " 17:00"
							);
							const timeStr = formData['orderTypeLookupId'] == lab_request_lookup_id ? " 10:30" : " 17:00";
							/*
							if(formData['orderTypeLookupId'] == lab_request_lookup_id) {
								const currentTimestamp = convertDateToTimestamp(
									convertTimestampToDate(this.state.currentDate) + " 10:00"
								);
							}
							*/
							const selectedCustomer = this.state.activeCustomers.filter(obj => obj != undefined && obj.id == formData['customerId'])[0];
							if(selectedCustomer != undefined) {
								numDays = parseInt(selectedCustomer['defaultShipTime']);
							}
							// formData['scheduledDeliveryDate'] = addDaysToDateTimestamp(currentDate, numDays, true);
							formData['scheduledDeliveryDate'] = addDaysToDateTimestamp(currentTimestamp, numDays, true, timeStr);

							this.setState({
								shipperList: resShippersList['data'],
								billOfLadingTypesList: resBillOfLadingTypesList['data'],
								formData: formData,
								currentDate: currentDate,
								containersList: containersList,
								taxRate: resCompanies['data'][0]['taxRate'],
								companyInfo: resCompanies['data'][0],
							});
						
							if(formData['orderTypeLookupId'] == lab_request_lookup_id)
								this.getLabRequestData();
					
							if(this.state.editOrderId != null)
							{
								getAPI("salesorders/" + editOrderId).then(resOrder => {
									let initialData = JSON.parse(JSON.stringify(resOrder['data'][0]));
									formData = resOrder['data'][0];
									
									// console.log("initialData in edit mode: ", initialData);
									const customerId = formData['customerId'];
									const title = 'Order ID: ' + formData['id'];
						
									if(formData['orderTypeLookupId'] == lab_request_lookup_id)
										this.getLabRequestData();
									
									let salesRepList = this.state.salesRepList;
									/*
									console.log("salesRepList: ", salesRepList);
									const orderSalesRepId = formData.salesRepActorId;
									let rowsSalesReps = salesRepList.filter(row => row.actorId == orderSalesRepId);
									console.log("rowsSalesReps: ", rowsSalesReps);
									if(rowsSalesReps.length == 0) {
										// add sales rep
										salesRepList.push({id: -1, customerId: formData.customerId, actorId: orderSalesRepId, displayName: formData.salesRepActorName});
									}
									*/
									getAPI("salesorders/" + editOrderId + "/formulas/list").then(resOrderFormulas => {
										// We need to calculate and store the ext Price and containerName for the fetched formulas
										// console.log("getOrderData() - resOrderFormulas['data']: ", resOrderFormulas['data']);
										let objContainerTotals = {};
										var orderFormulas = [];
										for(const i in resOrderFormulas['data'])
										{
											var formulaRow = resOrderFormulas['data'][i];
											formulaRow['extPrice'] = calculateExtPrice(formulaRow['price'], formulaRow['umOrdered'], formulaRow['discountAmt']);
										
											if(formulaRow['containerId']) {
												formulaRow['containerName'] = containersList.filter(obj => obj.id == formulaRow['containerId'])[0]['name'];
												const containerName = formulaRow['containerName'];
												if(objContainerTotals[containerName] == undefined) objContainerTotals[containerName] = 0;
												objContainerTotals[containerName] += formulaRow['quantity'];
											}
										
											formulaRow['qcFlag'] = formulaRow['qcDate'] != null;
											formulaRow['formulaWithNumber'] = (formulaRow['customerFormulaName'] ? formulaRow['customerFormulaName'] : formulaRow['formula']) + (formulaRow['formulaNumber'] ? " (" + formulaRow['formulaNumber'] + ")" : "");
											//If status is entered, should be disabled.  Otherwise, VIP, Admin, and lab tech should be able to uncheck
											if (formData['orderStatusLookupId'] == constants.order_status_entered 
												|| formData['orderStatusLookupId'] == constants.lab_request_status_entered
												|| formData['orderStatusLookupId'] == constants.credit_memo_status_entered) {
													formulaRow['qcDisabled'] = true;
											}
											else if (loggedInUser.userTypeId == constants.vip_type_id 
												|| loggedInUser.userTypeId == constants.finance_type_id) {
													formulaRow['qcDisabled'] = false;
											}
											//lab tech and admin should be able to uncheck/check only in confirmed status
											else if ((formData['orderStatusLookupId'] == constants.order_status_confirmed
												|| formData['orderStatusLookupId'] == constants.credit_memo_status_confirmed
												|| formData['orderStatusLookupId'] == constants.lab_request_status_confirmed)
												&&
												(loggedInUser.userTypeId == constants.admin_type_id
												|| loggedInUser.userTypeId == constants.labtech_type_id)) {
													formulaRow['qcDisabled'] = false;
											}
											//disabled if not of the user types and the status is not "entered"
											else {
												formulaRow['qcDisabled'] = true;
											}
											if(formData['orderTypeLookupId'] == sales_order_lookup_id || 	formData['orderTypeLookupId'] == credit_memo_lookup_id) {
												//If status is entered, should be disabled.  Otherwise, VIP, Admin, and lab tech should be able to uncheck
												if (formData['orderStatusLookupId'] == constants.order_status_entered 
													|| formData['orderStatusLookupId'] == constants.lab_request_status_entered
													|| formData['orderStatusLookupId'] == constants.credit_memo_status_entered) {
														formulaRow['completedDisabled'] = true;
												}
												else if (loggedInUser.userTypeId == constants.vip_type_id 
													|| loggedInUser.userTypeId == constants.finance_type_id) {
														formulaRow['completedDisabled'] = false;
												}
												else if ((formData['orderStatusLookupId'] == constants.order_status_confirmed
													|| formData['orderStatusLookupId'] == constants.credit_memo_status_confirmed
													|| formData['orderStatusLookupId'] == constants.lab_request_status_confirmed)
													&&
													(loggedInUser.userTypeId == constants.admin_type_id
													|| loggedInUser.userTypeId == constants.labtech_type_id)) {
														formulaRow['qcDisabled'] = false;
												}
												//disabled if not of the user types and the status is not "entered"
												else {
													formulaRow['completedDisabled'] = true;
												}
											} else if(formData['orderTypeLookupId'] == lab_request_lookup_id ) {
												formulaRow['completedDisabled'] = (
													formData['orderStatusLookupId'] == constants.lab_request_status_entered
												) || (
													loggedInUser.userTypeId != constants.vip_type_id 
													&& loggedInUser.userTypeId != constants.admin_type_id
													&& loggedInUser.userTypeId != constants.labtech_type_id
													&& loggedInUser.userTypeId != constants.rnd_type_id
												) || formulaRow['startDate'] == null;
											}
											// Samee - In any case, the completed checkbox should always be disabled in case 
											// the sales order had not been confirmed
											if(formData['orderTypeLookupId'] == sales_order_lookup_id && formData['confirmationDate'] == null) {
												 // Samee - disabling this for now
												// formulaRow['completedDisabled'] = true;
											}
											orderFormulas.push(formulaRow);
										}
										// console.log("Component mounted and got formulas.", orderFormulas, ", objContainerTotals: ", objContainerTotals);
										let containerTotals = [];
										Object.keys(objContainerTotals).forEach(function(containerName) {
											const containerQuantity = objContainerTotals[containerName];
											containerTotals.push({container: containerName, quantity: containerQuantity});
										});
										getAPI("salesorders/" + editOrderId + "/substrates").then(resOrderSubstrates => {
											const substrates = resOrderSubstrates['data'].map(obj => ({value: obj.substrateTypeLookupId, label: obj.name}));
											// Setting dates
											let initialSubstrates = JSON.parse(JSON.stringify(substrates));
											this.setState({
												title: title,
												formData: formData,
												salesRepList: salesRepList,
												initialData: initialData,
												initialSubstrates: initialSubstrates,
												orderFormulas: orderFormulas,
												initialOrderFormulas: JSON.parse(JSON.stringify(orderFormulas)),
												orderSubstrates: resOrderSubstrates['data'],
												substrates: substrates,
												initStatusId: formData['orderStatusLookupId'],
												initStatusName: orderStatusesConstants[formData['orderTypeLookupId']][formData['orderStatusLookupId']],
												containerTotals: containerTotals,
											});
											this.getCustomerData(customerId, true);
											this.onLabRequestStatusChanged(
												formData.orderStatusLookupId
											);
											// console.log("componentDidMount() - qboInvoiceInfo: ", this.getQboInvoiceInfo() , ", formData: ", formData, ", customerInfo: ", this.state.customerInfo);
										});
										
									});
								});								
							}
						});
					});
				});
			});
		});			
	}

	async initData() {
		var customerId = '';
		var formData = this.state.formData;
		
		
		// Getting Customers list to select from
		const loggedInUser = getLoggedInUser();
		let bVIPOrSalesManager = loggedInUser.userTypeId == constants.vip_type_id || loggedInUser.userTypeId == constants.sales_manager_type_id;
		
		let activeCustomers = [];
		
		// Get a list of all active customers
		await getAPI("customers/list").then(resCustomers => {
			activeCustomers = resCustomers['data'].filter(obj => obj.status == 'Active');
		});
		
		
		if(!bVIPOrSalesManager) {
			// Check if there are any user customers specific to the logged in user
			await getAPI("users/" + loggedInUser.id + "/customers").then(resUserCustomers => {
				const userCustomers = resUserCustomers['data'];
				// If so, filter the customer list accordingly 
				if(userCustomers.length) {
					activeCustomers = userCustomers.map(userCustomer => activeCustomers.filter(activeCustomer => activeCustomer.id == userCustomer.customerId)[0]);
				}
			});
		}
		
		this.setState({
			activeCustomers: activeCustomers,
		});
		
		if (activeCustomers.length == 1){
			var formData
			var customerId=activeCustomers[0].id
			formData['customerId'] = customerId;
			this.setState({
				formData: formData,
			});
			this.getCustomerData(customerId, true);
		}

		this.getOrderData();	
	}
	
	componentDidMount() {
		this.initData();	
	}
	
	handleInputChanged(event, name) { // (id, value, type) {
	
		const target = event.target;
		let value = '';
		let fieldTitle = labRequestCaptions[name] != undefined ? labRequestCaptions[name] : name;
		let oldFieldValue = ''
		let newFieldValue = '';
		let modifiedData = this.state.modifiedData;

		var formData = this.state.formData;
		switch(name) 
		{
		
			case 'customerId':
				
				value = event.value;
				const customerId = value;
				formData['customerId'] = customerId;
				
				let oldRow = null, newRow = null;
				if(this.state.initStatusId == constants.lab_request_status_confirmed) {
					oldRow = this.state.activeCustomers.filter(obj => obj != undefined && obj.id == this.state.initialData.customerId)[0];
					oldFieldValue = oldRow != undefined ? oldRow['name'] : '';
					newRow = this.state.activeCustomers.filter(obj => obj != undefined && obj.id == value)[0];
					newFieldValue = newRow != undefined ? newRow['name'] : '';
					// console.log("this.state.initialData.customerId: ", this.state.initialData.customerId, ", oldRow: ", oldRow, ", newRow: ", newRow);
					modifiedData[fieldTitle] = {'old': oldFieldValue, 'new': newFieldValue};
				}
				this.setState({
				  formData: formData,
				  modifiedData: modifiedData,
				  bCustomerSelected: true, // Indicating that a customer was manually selected via dropdown
				});
				
				this.getCustomerData(customerId, true);
				break;
			
			case 'substrates':
				value = event;
				// console.log("event: ", event);
				if(this.state.initStatusId == constants.lab_request_status_confirmed) {
					oldFieldValue = '';
					this.state.initialSubstrates.forEach(function(substrate) {
						if(oldFieldValue != "") oldFieldValue += ", ";
						oldFieldValue += substrate.label;
					});
					newFieldValue = '';
					value.forEach(function(substrate) {
						if(newFieldValue != "") newFieldValue += ", ";
						newFieldValue += substrate.label;
					});
					modifiedData[fieldTitle] = {'old': oldFieldValue, 'new': newFieldValue};
				}
				this.setState({
				  substrates: value,
				  modifiedData: modifiedData,
				});
				
				break;

			case 'bLateLostDamaged':
				formData['lateLostDamagedReason'] = "";
				formData['bLateLostDamaged'] = event.target.checked;
				this.setState({
					bReasonLateFlag : false,
					bReasonLostFlag : false,
					bReasonDamagedFlag : false,
					formData : formData,
				});
				
				break;

			case 'lateLostDamagedReason':
				formData['lateLostDamagedReason'] = event.target.value;
				this.setState({
                    formData : formData,
				});
				if(this.state.formData.lateLostDamagedReason == "late"){
					this.setState({
						bReasonLateFlag : true,
						bReasonLostFlag : false,
						bReasonDamagedFlag : false,
					});
			    }
			
			    else if(this.state.formData.lateLostDamagedReason == "lost"){
					this.setState({
						bReasonLostFlag : true,
						bReasonLateFlag : false,
						bReasonDamagedFlag : false,
					});
			    }		
			    else if(this.state.formData.lateLostDamagedReason == "damage"){	
					this.setState({
						bReasonDamagedFlag : true,
						bReasonLateFlag : false,
						bReasonLostFlag : false,
					});			
				}
				
				else {
					this.setState({
						bReasonLateFlag : false,
						bReasonLostFlag : false,
						bReasonDamagedFlag : false,
					});
				}
				
				break;
			
			case 'lateLostDamagedDeliveryDate':
				value = event._d ? event._d.getTime() : event;
				formData[name] = value;
				this.setState({
				  formData: formData,
				});
				break;

				
			
			case 'orderDate':
			case 'scheduledDeliveryDate':
			case 'shipDate':
			case 'statusDate':
			case 'invoiceDate':
				// console.log("event._d: ", event._d, ", event: ", event);
				value = event._d ? event._d.getTime() : event;
				formData[name] = value;
				
				// Readjust Scheduled Delivery Date
				// when ship date is changed
				if(name == 'shipDate') {
					
					if(this.state.initStatusId == constants.lab_request_status_confirmed) {
						oldFieldValue = isNaN(this.state.initialData.shipDate) || this.state.initialData.shipDate == 0  ? this.state.initialData.shipDate: convertTimestampToDate(this.state.initialData.shipDate);
						newFieldValue = isNaN(value) || value == 0  ? value: convertTimestampToDate(value);
						modifiedData[fieldTitle] = {'old': oldFieldValue, 'new': newFieldValue};
					}
		
					if(formData['customerId']) {
						const selectedCustomer = this.state.activeCustomers.filter(obj => obj != undefined && obj.id == formData['customerId'])[0];
						if(selectedCustomer != undefined) {
							const numDays = parseInt(selectedCustomer['defaultShipTime']);
							const timeStr = formData['orderTypeLookupId'] == lab_request_lookup_id ? " 10:30" : " 17:00";
							formData['scheduledDeliveryDate'] = addDaysToDateTimestamp(value, numDays, true, timeStr);
						}
					}
				}
				
				if(name == 'scheduledDeliveryDate') {
					if(this.state.initStatusId == constants.lab_request_status_confirmed) {
						oldFieldValue = isNaN(this.state.initialData.scheduledDeliveryDate) || this.state.initialData.scheduledDeliveryDate == 0  ? this.state.initialData.scheduledDeliveryDate: convertTimestampToDate(this.state.initialData.scheduledDeliveryDate, true);
						newFieldValue = isNaN(value) || value == 0  ? value: convertTimestampToDate(value, true);
						modifiedData[fieldTitle] = {'old': oldFieldValue, 'new': newFieldValue};
					}
				}
				
				
				// In case ship date is changed in edit mode, set the invoice date to the new ship date
				let bShipDateChanged = false;
				if(this.props.editOrderId && name == 'shipDate') {
					formData['invoiceDate'] = value;
					
					
					// Mark Ship Date Changed flag
					bShipDateChanged = true;
				}
				this.setState({
				  formData: formData,
				  bShipDateChanged: bShipDateChanged,
				  modifiedData: modifiedData,
				});
				break;
			
			case 'orderStatusLookupId':
				// Samee - ToDos 
				value = event.value;
				formData['statusName'] = orderStatusesConstants[this.state.formData.orderTypeLookupId] && orderStatusesConstants[this.state.formData.orderTypeLookupId][value] ? orderStatusesConstants[this.state.formData.orderTypeLookupId][value] : "";
				// event.preventDefault();
				this.onLabRequestStatusChanged(value, true);
				
				// console.log("this.state.currentDate when status changed: ", this.state.currentDate);
				// Samee - statusDate SHOULD be set upon changing the status
				formData['statusDate'] = this.state.currentDate; // (new Date()).getTime();
				
				// Samee - DO NOT change the state of the order status dropdown at this point. Change it in onLabRequestStatusChanged()
				return;
				
				break;
				
			default:
				var dateStringName = 'orderDateString';
				var dateStringValue = this.state.dateStringName;
				var orderFormulas = this.state.orderFormulas;
				var formData = this.state.formData;
				value = target.type === 'checkbox' ? target.checked : target.value;

				// Handle Date conversion
				switch(name) {
			
					
					case 'orderTypeLookupId':
						// Reset the formula List if the order type is changed
						if(value == lab_request_lookup_id)
							this.getLabRequestData();
						
						orderFormulas = [];
						
						if(!this.state.editOrderId) {
							
							let numDays = 0;
							let timeStr = " 17:00";
							const selectedCustomer = this.state.activeCustomers.filter(obj => obj != undefined && obj.id == formData['customerId'])[0];
							if(selectedCustomer != undefined) {
								numDays = parseInt(selectedCustomer['defaultShipTime']);
							}
							
							
							switch(value) {
								case "" + sales_order_lookup_id:
									// console.log("sales order selected");
									formData.orderStatusLookupId = 117;
									break;
									
								case "" + lab_request_lookup_id:
									// console.log("lab request selected");
									formData.orderStatusLookupId = 124;
									numDays = 1;
									timeStr = " 10:30"
									break;
									
								case "" + credit_memo_lookup_id:
									// console.log("credit memo selected");
									formData.orderStatusLookupId = 129;
									break;
								
								default:
									// console.log("no order type selected! value: ", value);
									break;
							}
							const currentTimestamp = convertDateToTimestamp(
								convertTimestampToDate(this.state.currentDate) + timeStr
							);
							formData['scheduledDeliveryDate'] = addDaysToDateTimestamp(currentTimestamp, numDays, true, timeStr);
							// console.log("value upon changing order type: ", value, ", formData.orderStatusLookupId: ", formData.orderStatusLookupId);
						}
						break;
					
					/*
					case 'orderDiscountCreditAmt':
						formData['totalOrderAmt'] = this.calculateTotalOrderAmount(this.state.orderFormulas, value, this.state.formData.skidChargeAmt, this.state.formData.freightChargeAmt, this.state.formData.fuelSurchargeAmt);
						break;
					case 'skidChargeAmt':
						formData['totalOrderAmt'] = this.calculateTotalOrderAmount(this.state.orderFormulas, this.state.formData.orderDiscountCreditAmt, value, this.state.formData.freightChargeAmt, this.state.formData.fuelSurchargeAmt);
						break;
					case 'freightChargeAmt':
						formData['totalOrderAmt'] = this.calculateTotalOrderAmount(this.state.orderFormulas, this.state.formData.orderDiscountCreditAmt, this.state.formData.skidChargeAmt, value, this.state.formData.fuelSurchargeAmt);
						break;
					case 'fuelSurchargeAmt':
						formData['totalOrderAmt'] = this.calculateTotalOrderAmount(this.state.orderFormulas, this.state.formData.orderDiscountCreditAmt, this.state.formData.skidChargeAmt, this.state.formData.freightChargeAmt, value);
						break;
					
					*/
						
					case 'orderDiscountCreditAmt':
					case 'skidChargeAmt':
					case 'freightChargeAmt':
					case 'fuelSurchargeAmt':
						const orderDiscountCreditAmt = name == 'orderDiscountCreditAmt' ? value 
							: this.state.formData.orderDiscountCreditAmt;
						const skidChargeAmt = name == 'skidChargeAmt' ? value 
							: this.state.formData.skidChargeAmt;
						const freightChargeAmt = name == 'freightChargeAmt' ? value 
							: this.state.formData.freightChargeAmt;
						const fuelSurchargeAmt = name == 'fuelSurchargeAmt' ? value 
							: this.state.formData.fuelSurchargeAmt;
						
						formData['totalOrderAmt'] = this.calculateTotalOrderAmount(
							this.state.orderFormulas, orderDiscountCreditAmt, skidChargeAmt, 
							freightChargeAmt, fuelSurchargeAmt);
							
						formData = calculateTaxRate(formData, this.state.taxRate,
									this.state.customerInfo.taxExempt, formData['totalOrderAmt']);
						
						break;
						
					
						
					case 'consignmentFlag':
						orderFormulas.forEach(function(formula) {
							formula.price = 0;
							formula.extPrice = 0;
						});
						this.setState({
							orderFormulas: orderFormulas,
							consignmentFlag: true,
						})
						formData['totalOrderAmt'] = this.calculateTotalOrderAmount(this.state.orderFormulas, this.state.formData.orderDiscountCreditAmt, this.state.formData.skidChargeAmt, this.state.formData.freightChargeAmt, this.state.formData.fuelSurchargeAmt);
						formData = calculateTaxRate(formData, this.state.taxRate,
									this.state.customerInfo.taxExempt, formData['totalOrderAmt']);
						break;
		
					// default:
						// value = target.value;
			
				}
			
			
			if(this.state.initStatusId == constants.lab_request_status_confirmed) {
					console.log("this.state.initStatusId == constants.lab_request_status_confirmed: true!");
				oldFieldValue = this.state.initialData[name];
				newFieldValue = value;
				let oldRow = null, newRow = null;
				switch(name) {
					
					case 'salesRepActorId':
						oldRow = this.state.salesRepList.filter(obj => obj != undefined && obj.actorId == this.state.initialData.salesRepActorId)[0];
						oldFieldValue = oldRow != undefined ? oldRow['displayName'] : '-';
						
						newRow = this.state.salesRepList.filter(obj => obj != undefined && obj.actorId == value)[0];
						newFieldValue = newRow != undefined ? newRow['displayName'] : '';
						break;
					
					case 'shippingAddressId':
						oldRow = this.state.shippingAddressList.filter(obj => obj != undefined && obj.addressId == this.state.initialData.shippingAddressId)[0];
						oldFieldValue = oldRow != undefined ? oldRow.address1 + (oldRow.address2 != null ? ' ' + oldRow.address2 : "") : '-';
						
						newRow = this.state.shippingAddressList.filter(obj => obj != undefined && obj.addressId == value)[0];
						newFieldValue = newRow != undefined ? newRow.address1 + (newRow.address2 != null ? ' ' + newRow.address2 : "") : '-';
						break;
					
					case 'customerContactId':
						oldRow = this.state.contactList.filter(obj => obj != undefined && obj.id == this.state.initialData.customerContactId)[0];
						oldFieldValue = oldRow != undefined ? oldRow['name'] : '-';
						
						newRow = this.state.contactList.filter(obj => obj != undefined && obj.id == value)[0];
						newFieldValue = newRow != undefined ? newRow['name'] : '-';
						break;
					
					case 'inkTypeLookupId':
						oldRow = this.state.inkTypesList.filter(obj => obj != undefined && obj.id == this.state.initialData.inkTypeLookupId)[0];
						oldFieldValue = oldRow != undefined ? oldRow['value'] : '-';
						
						newRow = this.state.inkTypesList.filter(obj => obj != undefined && obj.id == value)[0];
						newFieldValue = newRow != undefined ? newRow['value'] : '-';
						break;
					
					case 'varnishTypeLookupId':
						oldRow = this.state.varnishesList.filter(obj => obj != undefined && obj.id == this.state.initialData.varnishTypeLookupId)[0];
						oldFieldValue = oldRow != undefined ? oldRow['value'] : '-';
						
						newRow = this.state.varnishesList.filter(obj => obj != undefined && obj.id == value)[0];
						newFieldValue = newRow != undefined ? newRow['value'] : '-';
						break;
					
					case 'customerPressId':
						oldRow = this.state.pressesList.filter(obj => obj != undefined && obj.id == this.state.initialData.customerPressId)[0];
						oldFieldValue = oldRow != undefined ? oldRow['pressName'] : '-';
						
						newRow = this.state.pressesList.filter(obj => obj != undefined && obj.id == value)[0];
						newFieldValue = newRow != undefined ? newRow['pressName'] : '-';
						break;
					
					case 'deliveryTypeLookupId':
						oldRow = this.state.deliveryTypesList.filter(obj => obj != undefined && obj.id == this.state.initialData.deliveryTypeLookupId)[0];
						oldFieldValue = oldRow != undefined ? oldRow['value'] : '-';
						
						newRow = this.state.deliveryTypesList.filter(obj => obj != undefined && obj.id == value)[0];
						newFieldValue = newRow != undefined ? newRow['value'] : '-';
						break;
				}
				modifiedData[fieldTitle] = {'old': "" + oldFieldValue, 'new': "" + newFieldValue};
			}
			if(name != 'orderStatusLookupId') {
				formData[name] = value;
				// console.log("formData: ", formData);
				this.setState({
				  formData: formData,
				  orderFormulas: orderFormulas,
				  bReRender: false,
				  modifiedData: modifiedData,
				});
			}
			break;
		
		}
		// console.log("value: ", value, ", modifiedData['" + fieldTitle + "']: ", modifiedData[fieldTitle]);
	}
	
	chkQCDateChecked(event) {
		const checked = event.target.checked;
		const value = event.target.value;
		let orderFormulas = this.state.orderFormulas;
		// console.log("QC date checked - value: ", value);
		const rowIndex = orderFormulas.findIndex((row) => {
			return row.id == value;
		});
		if(rowIndex > -1) {
			orderFormulas[rowIndex]['qcDate'] = checked ? (new Date).getTime() : null;
			orderFormulas[rowIndex]['qcFlag'] = checked;
			// console.log("order formula changed for rowindex: " + rowIndex);
			orderFormulas[rowIndex]['bHasChanged'] = true;
			if(checked) {
				/*
				let rowCombinedBatchNumber = orderFormulas[rowIndex]['combinedBatchNumber'];

				for(let i = 0; i < orderFormulas.length; i++) {
					var obj = orderFormulas[i];
					if(obj.id != value && obj.combinedBatchNumber == rowCombinedBatchNumber) {
						// console.log("match found - id: ", obj.id, ", combinedBatchNumber: ", obj.combinedBatchNumber);
						orderFormulas[i]['qcDate'] = checked ? (new Date).getTime() : null;
						orderFormulas[i]['qcFlag'] = checked;
					}
				}
				*/
				openBirtReport('BCM_Bucket_Label', {
					customer: this.state.formData.customerId,
					formula: orderFormulas[rowIndex]['formulaId'],
					orderFormula: orderFormulas[rowIndex]['id'],
					numberOfCopies: orderFormulas[rowIndex]['quantity'],
					salesOrder: this.state.formData.id
				});
			}
		}
			
		this.setState({
			orderFormulas: orderFormulas
		});
	}
	
	chkCompletedDateChecked(event) {
		const checked = event.target.checked;
		const value = event.target.value;
		let orderFormulas = this.state.orderFormulas;
		let labRequestFormulaIndex = this.state.labRequestFormulaIndex;
		let salesOrderFormulaReferenceId = this.state.salesOrderFormulaReferenceId;
		let showNewFormulaForm = false;
		const orderId = this.state.formData.id;
		let formData = this.state.formData;
		const rowIndex = orderFormulas.findIndex((row) => {
			return row.id == value;
		});
		if(rowIndex > -1)
		{
			orderFormulas[rowIndex]['completedDate'] = checked ? (new Date).getTime() : null;
			orderFormulas[rowIndex]['completedFlag'] = checked;
			orderFormulas[rowIndex]['bHasChanged'] = true;
			if(this.state.formData.orderTypeLookupId == lab_request_lookup_id && this.state.formData.lrTypeLookupId != constants.lr_type_repull_lookup_id) {
				//Open the formula form if one is completed on a lab request. 
				if(checked) {
						
					labRequestFormulaIndex = rowIndex;
					salesOrderFormulaReferenceId = orderFormulas[rowIndex]['id'];
					showNewFormulaForm = true;
				
				}
				//Save the form data because the formula will need the latest data from the lab request.
				this.saveFormData(formData);
			}
		}
		this.setState({
			orderFormulas: orderFormulas,
			labRequestFormulaIndex: labRequestFormulaIndex,
			salesOrderFormulaReferenceId: salesOrderFormulaReferenceId,
			bShowNewFormulaForm: showNewFormulaForm,
			formulaLabRequestId: orderId,
		});
		
	}

	chkStartDateChecked(event) {
		const checked = event.target.checked;
		const value = event.target.value;
		let orderFormulas = this.state.orderFormulas;
		//let labRequestFormulaIndex = this.state.labRequestFormulaIndex;
		const orderId = this.state.formData.id;
		
		let formData = this.state.formData;
		const rowIndex = orderFormulas.findIndex((row) => {
			return row.id == value;
		});
		if(rowIndex > -1)
		{
			orderFormulas[rowIndex]['startDate'] = checked ? (new Date).getTime() : null;
			orderFormulas[rowIndex]['startFlag'] = checked;
			orderFormulas[rowIndex]['completedDisabled'] = !checked;
			let orderFormula = orderFormulas[rowIndex];
			orderFormula['orderId']=orderId;
			postAPI("salesorders/" + orderId + "/formulas/", orderFormula).then(resOrderFormula => {
			});
		}
		this.setState({
			orderFormulas: orderFormulas,
			//labRequestFormulaIndex: labRequestFormulaIndex,
			//bShowNewFormulaForm: showNewFormulaForm,
			formulaLabRequestId: orderId,
		});
		
	}
	
	onAddOrderFormula(event) {
		event.preventDefault();
		
		this.setState({
			editOrderFormula: null,
			selectedOrderFormulaIndex: null,
			bShowOrderFormulaForm: true,
		});
	}
	
	onSelectOrderFormula(rowIndex) {
		this.setState({
			selectedOrderFormulaIndex: rowIndex,
		});
	}
	
	onEditOrderFormula(editRow) {
		this.setState({
			editOrderFormula: editRow,
			bShowOrderFormulaForm: true,
		});
	}
	
	
	
	onRemoveOrderFormula(event) {
		event.preventDefault();
		
		let selectedOrderFormulaIndex = this.state.selectedOrderFormulaIndex;
		if(selectedOrderFormulaIndex != null) {
			var orderFormulas = this.state.orderFormulas;		
			var deletedOrderFormulas = this.state.deletedOrderFormulas;

			const orderFormula = this.state.orderFormulas[selectedOrderFormulaIndex];
			if(orderFormula.id > 0)
				deletedOrderFormulas.push(orderFormula);
		
			orderFormulas.splice(selectedOrderFormulaIndex, 1);
			const numFormulas = orderFormulas.length;
			if(numFormulas == 0)
				selectedOrderFormulaIndex = null;
			else if(selectedOrderFormulaIndex == numFormulas)
				selectedOrderFormulaIndex--;
			
			let formData = this.state.formData
			let totalOrderAmt = this.calculateTotalOrderAmount(orderFormulas, 
				this.state.formData.orderDiscountCreditAmt, this.state.formData.skidChargeAmt, 
				this.state.formData.freightChargeAmt, this.state.formData.fuelSurchargeAmt);
			formData['totalOrderAmt'] = totalOrderAmt;
			formData = calculateTaxRate(formData, this.state.taxRate,
			this.state.customerInfo.taxExempt, totalOrderAmt);
			// console.log("new selectedOrderFormulaIndex: ", selectedOrderFormulaIndex, ", totalOrderAmt: ", totalOrderAmt);
			this.setState({
				orderFormulas: orderFormulas,
				deletedOrderFormulas: deletedOrderFormulas,
				selectedOrderFormulaIndex: selectedOrderFormulaIndex,
				formData: formData,
			});
		}
	}
	
	
	
	onSaveOrderFormula(orderFormula) {
		var orderFormulas = this.state.orderFormulas;
		var formData = this.state.formData;
		var totalOrderAmt = this.state.formData.totalOrderAmt;
		let labRequestFormulaIndex = null;
		
		orderFormula.bHasChanged = true;
		orderFormula.bBatchPrinted = false;
		if(this.state.selectedOrderFormulaIndex != null)
		{
			//////// THIS WAS EDIT MODE
			orderFormulas[this.state.selectedOrderFormulaIndex] = orderFormula;	
			labRequestFormulaIndex = this.state.selectedOrderFormulaIndex;
		}
		else
		{
			///////// THIS WAS INSERT MODE
			labRequestFormulaIndex = orderFormulas.length;
			orderFormula['sequenceNumber'] = labRequestFormulaIndex + 1;
			orderFormulas.push(orderFormula);
		}
		
		totalOrderAmt = this.calculateTotalOrderAmount(orderFormulas, this.state.formData.orderDiscountCreditAmt, this.state.formData.skidChargeAmt, this.state.formData.freightChargeAmt, this.state.formData.fuelSurchargeAmt);
		formData['totalOrderAmt'] = totalOrderAmt;
		formData = calculateTaxRate(formData, this.state.taxRate,
									this.state.customerInfo.taxExempt, totalOrderAmt);
		// console.log("orderFormula['completedDate']: ", orderFormula['completedDate']);
		this.setState({
			orderFormulas: orderFormulas,
			formData: formData,
			bShowOrderFormulaForm: false,
			labRequestFormulaIndex: orderFormula['completedDate'] ? labRequestFormulaIndex : null,
		});
	}
	
	calculateTotalOrderAmount(orderFormulas, orderDiscountCreditAmt, skidChargeAmt, freightChargeAmt, fuelSurchargeAmt) {
		var totalOrderAmt = 0;
		for(const i in orderFormulas) {
			totalOrderAmt += orderFormulas[i]['extPrice'];
		}
		skidChargeAmt = skidChargeAmt == '' || skidChargeAmt == null ? 0.0 : parseFloat(skidChargeAmt);
		freightChargeAmt = freightChargeAmt == '' || freightChargeAmt == null ? 0.0 : parseFloat(freightChargeAmt);
		fuelSurchargeAmt = fuelSurchargeAmt  == '' || fuelSurchargeAmt == null ? 0.0 : parseFloat(fuelSurchargeAmt);
		orderDiscountCreditAmt = orderDiscountCreditAmt == '' || orderDiscountCreditAmt == null ? 0.0 : parseFloat(orderDiscountCreditAmt);
		totalOrderAmt = totalOrderAmt + skidChargeAmt + freightChargeAmt + fuelSurchargeAmt - orderDiscountCreditAmt;
		return totalOrderAmt;
	}
	
	/*
	calculateTaxRate(formData) {
		// console.log("calculateTaxRate() - this.state.customerInfo: ", this.state.customerInfo);
		if(this.state.taxRate && !this.state.customerInfo.taxExempt) {
			formData['taxAmount'] = (formData['totalOrderAmt'] * this.state.taxRate) / 100;
			formData['grandTotal'] = formData['totalOrderAmt'] + 
				formData['taxAmount'];
		}
		else {
			formData['taxAmount'] = null;
			formData['grandTotal'] = formData['totalOrderAmt'];
		}
		return formData;
	}
	*/
	
	
	
	async handleSaveClicked(event) {
		this.setState({
			saveDisabled: true,
		});
		event.preventDefault();
		
		const loggedInUser = getLoggedInUser();
		var bFormValidated = true;
		var bSaveSuccessful = false;
		
		
		// PERFORM VALIDATION CHECKS HERE
		
		let validation = validateForm(this.state.formData, validationFields);

		if(this.state.formData.orderTypeLookupId == lab_request_lookup_id) {
			
			const labRequestValidation = validateForm(this.state.formData, labRequestValidationFields);
			validation.errors = mergeObjects(validation.errors, labRequestValidation.errors);
			validation.result = validation.result && labRequestValidation.result;
			
			// Checking for substrates
			if(this.state.formData.lrTypeLookupId != constants.lr_type_repull_lookup_id) {
				if(this.state.substrates.length == 0) {
					validation.errors.substrates = "Please select one or more substrates";
					validation.result = false;
				}
			}
		}
		else if(this.state.formData.orderTypeLookupId == sales_order_lookup_id) {
			
			const salesOrderValidation = validateForm(this.state.formData, salesOrderValidationFields);
			validation.errors = mergeObjects(validation.errors, salesOrderValidation.errors);
			validation.result = validation.result && salesOrderValidation.result;
			// console.log("this.state.formData: ", this.state.formData, ", salesOrderValidation: ", salesOrderValidation, ", salesOrderValidationFields: ", salesOrderValidationFields, ", validation for sales Orders: ", validation);
		}
		else {
			const creditMemoValidation = validateForm(this.state.formData, creditMemoValidationFields);
			validation.errors = mergeObjects(validation.errors, creditMemoValidation.errors);
			validation.result = validation.result && creditMemoValidation.result;
			// console.log("validation for credit memo: ", validation);
		}
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors,
				saveDisabled: false,
			});
		}
		else
		{
			// IF FORM IS VALIDATED PERFORM SAVE OPERATION HERE
			
			// Remove a few fields
			// var strOrderParams = http_build_query(this.state.formData);
			const formData = this.state.formData;
			
			// In case Order Status is other than Entered
			/*
			if(formData.orderStatusLookupId != 117) {
				for (var i = 0; i < this.state.orderFormulas.length; i++) {
					if (this.state.orderFormulas[i].qcDate != null) {
						this.state.orderFormulas[i].umMade = this.state.orderFormulas[i].umOrdered;
					}					
				}
			}
			*/

			// console.log("this.state.orderFormulas: ", this.state.orderFormulas);
			
			/////////////////////// SEND EMAILS ///////////////////////
			/////////////////////// DO NOT SEND EMAILS FOR DEV! /////////////////////
			// console.log("Config.env: ", Config.env);

			if(formData.id > 0) {
				const qboCustomerReferenceNum = this.state.customerInfo.qboCustomerRefId;
				let qboInvoiceInfo = this.getQboInvoiceInfo();
				/*
				let qboInvoiceInfo ={};
				
				
				if(qboCustomerReferenceNum > 0) {
					let qboLineItemInfo = [];
					this.state.orderFormulas.forEach(function(row, i){
						let unitPrice = row.price;
						let extPrice = row.umOrdered * unitPrice - row.discountAmt;
						if(row.discountAmt != 0)
							unitPrice = extPrice / row.umOrdered;
						
						let salesItemLineDetail = { 
							"Qty": row.umOrdered, 
							"UnitPrice": unitPrice,
							"DiscountAmt": row.discountAmt,
						};
						
						
						
						if(constants.qboFormulaTypes[row.formulaTypeLookupId] != null) {
							const qboFormulaType = constants.qboFormulaTypes[row.formulaTypeLookupId];
							salesItemLineDetail['ItemRef'] = {
								"value": qboFormulaType['Id'],
								"name": qboFormulaType['Name']
							};
							if(qboFormulaType['IncomeAccountRef'] != null) {
								salesItemLineDetail['ItemAccountRef'] = qboFormulaType['IncomeAccountRef'];
							}
							if(qboFormulaType['ClassRef'] != null)
								salesItemLineDetail['ClassRef'] = qboFormulaType['ClassRef'];
						}
							
						qboLineItemInfo.push({ 
							"Description": row.formula, 
							"DetailType": "SalesItemLineDetail", 
							"Amount": extPrice, 
							"SalesItemLineDetail": salesItemLineDetail
						});
					});
					
					// Adding fuel charge if any
					if(formData.fuelSurchargeAmt > 0) {
						qboLineItemInfo.push({ 
							"Description": "FUEL SURCHARGE", 
							"DetailType": "SalesItemLineDetail", 
							"Amount": formData.fuelSurchargeAmt, 
							"SalesItemLineDetail": {
								"ItemRef": {
									"value": "29",
									"name": "FSC"
								},
							    "ItemAccountRef": {
									"value": "214",
									"name": "4113 Sales:Sales -Freight"
								},
								"UnitPrice": formData.fuelSurchargeAmt,
								"Qty": 1,
							}
						});
					}
					
					// Adding freight charge if any
					if(formData.freightChargeAmt > 0) {
						qboLineItemInfo.push({ 
							"Description": "FREIGHT CHARGE", 
							"DetailType": "SalesItemLineDetail", 
							"Amount": formData.freightChargeAmt, 
							"SalesItemLineDetail": {
								"ItemRef": {
									"value": "31",
									"name": "FRT"
								},
							    "ItemAccountRef": {
									"value": "214",
									"name": "4113 Sales:Sales -Freight"
								},
								"UnitPrice": formData.freightChargeAmt,
								"Qty": 1,
							}
						});
					}
					
					// Adding Skid charge if any
					
					let customFields = [{
						"DefinitionId": "1",
						"Name": "PO Number",
						"Type": "StringType",
						"StringValue": formData.poNumber
					}];
					
					if(formData.salesRepActorId > 0) {
						let rowsSalesReps = this.state.salesRepList.filter(row => row.actorId == formData.salesRepActorId)[0];
						if(rowsSalesReps != null) {
							customFields.push({
							  "DefinitionId": "2",
							  "Name": "Sales Rep",
							  "Type": "StringType",
							  "StringValue": rowsSalesReps.displayName
							 });
						}
					}
					
					qboInvoiceInfo = {
						Line: qboLineItemInfo,
						CustomerRef: {"value": qboCustomerReferenceNum},
						DocNumber: formData.id,
						"TrackingNum": this.state.formData.shipperTrackingNumber,
						"CustomField": customFields,
						"ShipDate": convertTimestampToDate(formData.shipDate, false, false, true),
						"DueDate": convertTimestampToDate(formData.scheduledDeliveryDate, false, false, true),
						// "TotalAmt": this.state.formData.totalOrderAmt,
						"TotalAmt": this.state.formData.grandTotal,
						"Balance": this.state.formData.grandTotal
					};
					// console.log("this.state.customerInfo: ", this.state.customerInfo);
					if(this.state.customerInfo.customerTermsId > 0) {
						const arrQboTerms = constants.qboTerms[this.state.customerInfo.customerTermsId]
						qboInvoiceInfo['SalesTermRef'] = {
						 "value": arrQboTerms[0],
						 "name": arrQboTerms[1]
						};
					}
					
					
					
					if(this.state.billingAddress != null) {
						// qboInvoiceInfo['BillAddr'] = this.state.billingAddress;
					}
					
					if(formData.shippingAddressId > 0) {
						
						const rowShippingAddress = this.state.shippingAddressList.filter(obj => obj != undefined && obj.addressId == formData.shippingAddressId)[0];
						const strShippingAddress = rowShippingAddress != undefined ? rowShippingAddress.address1 + (rowShippingAddress.address2 != null ? ' ' + rowShippingAddress.address2 : "") : '';
						
						// if(strShippingAddress != '')
							//qboInvoiceInfo['ShipAddr'] = strShippingAddress;
					}
					
					// console.log("invoice request: ", qboInvoiceInfo);
					
				}
				*/
				
				/////// SALES ORDERS EMAILS
				if(formData.orderTypeLookupId == constants.sales_order_lookup_id) {
				
					//request email template for invoice from REST Service.
					if((	
						formData.orderStatusLookupId == constants.order_status_invoiced
						||	formData.orderStatusLookupId == constants.order_status_shipped_invoiced
						)
						&& this.state.initStatusId != constants.order_status_invoiced
						&& this.state.initStatusId != constants.order_status_shipped_invoiced
					) 
					{ 
						if(formData.invoiceEmailSentDate == null)
						{
						
							const contactLocale = this.state.customerContactEmailsList.length > 0 
								&& this.state.customerContactEmailsList[0].spanishFlag 
								? "es_us" : "en_us";
									
							//Get Invoice E-mail template							
							let emailParams = "name=Invoice&locale=" + contactLocale; 
						
							await getAPI("emails/list?" + emailParams).then(resEmailTemplate => {
								// console.log("resEmailTemplate: ", resEmailTemplate);
								const invoiceEmailTemplate = resEmailTemplate.data[0];
							
								let logoLocation = Config.baseURL + "images/companies/" 
									+ this.state.companyInfo.id;
								logoLocation = this.state.companyInfo.logoLocation;
								
								let parms = "&salesOrder=" + formData.id + "&customer=" 
								+ formData.customerId + "&company=" + this.state.companyInfo.name  
								+ "&env=" + Config.env + "&logo=" + logoLocation;
							
								let body:String = invoiceEmailTemplate.body + "<BR/><BR/>" 
								+ invoiceEmailTemplate.signature + "<BR/><BR/>" + 
								(invoiceEmailTemplate.privacyDisclosure != null 
								? invoiceEmailTemplate.privacyDisclosure : "");
							
								let queryString = "parms=" + encodeURIComponent(parms)
								+ "&title=Invoice&body=" + encodeURIComponent(body) 
								+ "&orderId=" + formData.id + "&name=" + this.state.companyInfo.name 
								+ "&reportName=BCM_Invoice.rptdesign&cid=" 
								+ formData.customerId;
								
								getAPI("emails/" + invoiceEmailTemplate.emailTemplateId 
									+ "/buildAndSend?" + queryString ).then(resEmailSent => {
									// console.log("resEmailSent: ", resEmailSent);
									if(!resEmailSent.success) {
										alert("Error sending invoice email");
									}
									else {
										// formData.invoiceEmailSentDate = (new Date()).getTime();
									}
								});
							});
						}
						
						
						// QBO - Check and save invoice
						if(qboCustomerReferenceNum > 0) {

							quickbooksAPI({
								endpoint: "/v3/company/realmId/invoice?minorversion=65",
								method: "POST",
								contentType: "application/json",
								data: encodeURIComponent(JSON.stringify(qboInvoiceInfo)),
							}).then(invoice => {
								// console.log("created invoice: ", invoice);
								if(invoice && invoice.error == null) {
									// alert("Invoice successfully added to Quickbooks!");
								} else {
									// alert(invoice.error + " - Please send the following msg: " + JSON.stringify(qboInvoiceInfo));
								}
							});
						} else {
							alert("The customer does not exist in QBO or is not linked with MyInkIQ!");
						}
						
					}
					//request email template for Shipping Confirmation from REST Service.
					if (formData.shippingConfirmationEmailSentDate == null
						&& !formData.consignmentBillingFlag
						&& (
							formData.orderStatusLookupId == constants.order_status_shipped 
							|| formData.orderStatusLookupId == 
								constants.order_status_shipped_invoiced
						)
						&& this.state.initStatusId != constants.order_status_shipped
						&& this.state.initStatusId != constants.order_status_shipped_invoiced
					) {
						
						const contactLocale = this.state.customerContactEmailsList.length > 0 
							&& this.state.customerContactEmailsList[0].spanishFlag 
							? "es_us" : "en_us";
							
						let emailParams = "name=Shipment Confirmation&locale=" + contactLocale; 
					
						await getAPI("emails/list?" + emailParams).then(resEmailTemplate => {
							// console.log("resEmailTemplate: ", resEmailTemplate);
							const shippingConfirmationEmailTemplate = resEmailTemplate.data[0];
							
							getAPI("emails/" + shippingConfirmationEmailTemplate.emailTemplateId 
							+ "/customers/" + formData.customerId).then(
								resShippingConfirmationCustomers => {
								
								let parms = "&salesOrder=" + formData.id + "&customer=" 
								+ formData.customerId + "&company=" + this.state.companyInfo.name  
								+ "&env=" + Config.env + "&logo=" + this.state.companyInfo.logoLocation;
								//Create email object
								let emailsDto = {};
								emailsDto.customerId = formData.customerId;
								emailsDto.emailType = "Shipment Confirmation";
								emailsDto.user = loggedInUser;
								emailsDto.bccAddresses = loggedInUser.emailAddress;
								emailsDto.orderId = formData.id;
				
								var body = shippingConfirmationEmailTemplate.body;
								//substitute the place holders for real values
								// 0 - Shipper, 1 - Tracking #
								let shipperName = "";
								let shipperRow = this.state.shipperList.filter(obj => 
									obj.id == formData.shipperId)[0];
								if(shipperRow != undefined)
									shipperName = shipperRow.name;
								
								body = body.replace("{0}", shipperName + "");
								body = body.replace("{1}", formData.shipperTrackingNumber.trim());
								body = body.replace("{2}", 
									convertTimestampToDate(formData.shipDate));
				
								emailsDto.body = body + "<BR/><BR/>" 
								+ shippingConfirmationEmailTemplate.signature + "<BR/><BR/>" 
								+ (shippingConfirmationEmailTemplate.privacyDisclosure != null ? 
									shippingConfirmationEmailTemplate.privacyDisclosure : '');
								emailsDto.ccAddresses = shippingConfirmationEmailTemplate.ccEmail;
								emailsDto.fromEmail = shippingConfirmationEmailTemplate.fromEmail;
								emailsDto.fromName = shippingConfirmationEmailTemplate.fromName;
								emailsDto.replyTo = shippingConfirmationEmailTemplate.fromEmail;
								emailsDto.subject = shippingConfirmationEmailTemplate.subject;
								emailsDto.toAddresses = shippingConfirmationEmailTemplate.toEmail;
								emailsDto.companyName = this.state.companyInfo.name;
								emailsDto.reportName = "BCM_Sales_Order.rptdesign";
								emailsDto.reportParams = parms;
								sendEmail(emailsDto, resShippingConfirmationCustomers, shippingConfirmationEmailTemplate.emailTemplateId);
							});
						});
					}
					
					
					
                    //request email template for Certificate of Analysis from REST Service. - Praphulla
					if(formData.certificateOfAnalysisEmailSentDate == null
						&& (	
							formData.orderStatusLookupId == constants.order_status_shipped
						||	formData.orderStatusLookupId == constants.order_status_shipped_invoiced
						)
						&& this.state.initStatusId != constants.order_status_shipped
						&& this.state.initStatusId != constants.order_status_shipped_invoiced
					) {
					
						const contactLocale = this.state.customerContactEmailsList.length > 0 
							&& this.state.customerContactEmailsList[0].spanishFlag 
							? "es_us" : "en_us";
								
						//Get certificate of analysis E-mail template							
						let emailParams = "name=Certificate of Analysis&locale=" + contactLocale; 
					
						await getAPI("emails/list?" + emailParams).then(resEmailTemplate => {
							// console.log("resEmailTemplate: ", resEmailTemplate);
							const certificateOfAnalysisEmailTemplate = resEmailTemplate.data[0];
						
							let logoLocation = Config.baseURL + "images/companies/" 
								+ this.state.companyInfo.id;
							logoLocation = this.state.companyInfo.logoLocation;

							let strOrderFormulaIds = "";
							for(var oFormCount = 0; oFormCount < this.state.orderFormulas.length; oFormCount++) {
								// console.log("this.state.orderFormulas[oFormCount].formulaTypeLookupId: ", this.state.orderFormulas[oFormCount].formulaTypeLookupId);
								// Display CofA only if Formula Type is not 'Other'
								if(this.state.orderFormulas[oFormCount].formulaTypeLookupId == constants.formula_type_other) {}else {
									strOrderFormulaIds += (strOrderFormulaIds != "" ? "," : "") + this.state.orderFormulas[oFormCount].id;
								}
							}
							// Do not include any attachments if there are no order formula IDs passed
							let CofAReportName = strOrderFormulaIds != "" ? "BCM_CofA.rptdesign" : "";
							
							let parms = "&type=F&salesOrder=" + formData.id + "&customer=" 
							+ formData.customerId + "&company=" + this.state.companyInfo.name  
							+ "&env=" + Config.env + "&logo=" + logoLocation;
						
							let body:String = certificateOfAnalysisEmailTemplate.body + "<BR/><BR/>" 
							+ certificateOfAnalysisEmailTemplate.signature + "<BR/><BR/>" + 
							(certificateOfAnalysisEmailTemplate.privacyDisclosure != null 
							? certificateOfAnalysisEmailTemplate.privacyDisclosure : "");
						
							body = body.replace("{0}", formData.id + "");
						
							let queryString = "parms=" + encodeURIComponent(parms)
							+ "&title=Certificate Of Analysis&body=" + encodeURIComponent(body) 
							+ "&orderId=" + formData.id + "&name=" + this.state.companyInfo.name 
							+ "&reportName=" + CofAReportName + "&cid=" 
							+ formData.customerId + "&orderFormulaIds=" + strOrderFormulaIds;
							
							getAPI("emails/" + certificateOfAnalysisEmailTemplate.emailTemplateId 
								+ "/buildAndSend?" + queryString ).then(resEmailSent => {
								// console.log("resEmailSent: ", resEmailSent);
								if(resEmailSent == null) {
									console.log("No CofA emails were sent because no contacts were found");
								} else {
									if(!resEmailSent.success) {
										alert("Error sending Certifiacte of Analysis email");
									}
									else {
										// formData.certificateOfAnalysisEmailSentDate = (new Date()).getTime();
									}
								}
							});
						});
					}
					//praphulla end
					
					
					
					//request email template for Quote from REST Service.
					if (formData.quoteEmailSentDate==null
						&& !formData.consignmentBillingFlag
						&& formData.orderStatusLookupId == constants.order_status_quoted
						&& this.state.initStatusId != constants.order_status_quoted
					) {
						const contactLocale = this.state.customerContactEmailsList.length > 0 
							&& this.state.customerContactEmailsList[0].spanishFlag 
							? "es_us" : "en_us";
						let emailParams = "name=Quote&locale=" + contactLocale; 
					
						await getAPI("emails/list?" + emailParams).then(resEmailTemplate => {
							// console.log("resEmailTemplate: ", resEmailTemplate);
							const quoteEmailTemplate = resEmailTemplate.data[0];
							
							let logoLocation = Config.baseURL + "images/companies/" 
								+ this.state.companyInfo.id;
							logoLocation = this.state.companyInfo.logoLocation;
							
							let parms = "&salesOrder=" + formData.id + "&customer=" 
							+ formData.customerId + "&company=" + this.state.companyInfo.name  
							+ "&env=" + Config.env + "&logo=" + logoLocation;
							
							let body = quoteEmailTemplate.body + "<BR/><BR/>" 
							+ quoteEmailTemplate.signature + "<BR/><BR/>" 
							+ (quoteEmailTemplate.privacyDisclosure !=null ? 
							quoteEmailTemplate.privacyDisclosure:"");
							
							let queryString = "parms=" + encodeURIComponent(parms)
							+ "&title=Quote&body=" + encodeURIComponent(body) 
							+ "&orderId=" + formData.id + "&name=" + this.state.companyInfo.name 
							+ "&reportName=BCM_Sales_Order.rptdesign&cid=" 
							+ formData.customerId;
							
							getAPI("emails/" + quoteEmailTemplate.emailTemplateId 
								+ "/buildAndSend?" + queryString ).then(resEmailSent => {
								// console.log("resEmailSent: ", resEmailSent);
								if(!resEmailSent.success) {
									alert("Error sending invoice email");
								}
								else {
									// formData.quoteEmailSentDate = (new Date()).getTime();
								}
							});
							
						});
					
					}
					
					//request email template for Order Confirmation from REST Service.
					if(!formData.consignmentBillingFlag
						&& formData.orderStatusLookupId == constants.order_status_confirmed
						&& this.state.initStatusId != constants.order_status_confirmed
						&& formData.orderConfirmationEmailSentDate == null) {
						
						//display batches report 
						
						
						const contactLocale = this.state.customerContactEmailsList.length > 0 
							&& this.state.customerContactEmailsList[0].spanishFlag 
							? "es_us" : "en_us";
						let emailParams = "name=Order Confirmation&locale=" + contactLocale; 
					
						await getAPI("emails/list?" + emailParams).then(resEmailTemplate => {
							// console.log("resEmailTemplate: ", resEmailTemplate);
							const orderConfirmationEmailTemplate = resEmailTemplate.data[0];
							
							let logoLocation = Config.baseURL + "images/companies/" 
								+ this.state.companyInfo.id;
							logoLocation = this.state.companyInfo.logoLocation;
							
							let parms = "&salesOrder=" + formData.id + "&customer=" 
							+ formData.customerId + "&company=" + this.state.companyInfo.name  
							+ "&env=" + Config.env + "&logo=" + logoLocation;
							
							let body = orderConfirmationEmailTemplate.body + "<BR/><BR/>" 
							+ orderConfirmationEmailTemplate.signature + "<BR/><BR/>" 
							+ (orderConfirmationEmailTemplate.privacyDisclosure !=null ? 
							orderConfirmationEmailTemplate.privacyDisclosure:"");
							// Samee - ToDos: Add ship date to the body
							const formattedShipDate = isNaN(formData.shipDate) || formData.shipDate == 0  ? formData.shipDate: convertTimestampToDate(formData.shipDate);
							body = body.replace("{1}", formattedShipDate);
							
							let queryString = "parms=" + encodeURIComponent(parms)
							+ "&title=Order Confirmation&body=" + encodeURIComponent(body) 
							+ "&orderId=" + formData.id + "&name=" + this.state.companyInfo.name 
							+ "&reportName=BCM_Sales_Order.rptdesign&cid=" 
							+ formData.customerId;
							// console.log("queryString: ", queryString);
							getAPI("emails/" + orderConfirmationEmailTemplate.emailTemplateId 
								+ "/buildAndSend?" + queryString ).then(resEmailSent => {
								// console.log("resEmailSent: ", resEmailSent);
								if(!resEmailSent.success) {
									alert("Error sending order confirmation email");
								}
								else {
									// formData.orderConfirmationEmailSentDate = (new Date()).getTime();
								}
							});
						});
					}
				}
				
				if(formData.orderTypeLookupId == constants.credit_memo_lookup_id) {
					if(formData.orderStatusLookupId == constants.credit_memo_status_confirmed 
						&& this.state.initStatusId != constants.credit_memo_status_confirmed
					) {
						if(formData.creditMemoEmailSentDate == null) {
							
							const contactLocale = this.state.customerContactEmailsList.length > 0 
								&& this.state.customerContactEmailsList[0].spanishFlag 
								? "es_us" : "en_us";
							let emailParams = "name=Credit Memo Confirmation&locale=" + contactLocale; 
						
							await getAPI("emails/list?" + emailParams).then(resEmailTemplate => {
								// console.log("resEmailTemplate: ", resEmailTemplate);
								const creditMemoEmailTemplate = resEmailTemplate.data[0];
								
								let logoLocation = Config.baseURL + "images/companies/" 
									+ this.state.companyInfo.id;
								logoLocation = this.state.companyInfo.logoLocation;
								
								let parms = "&salesOrder=" + formData.id + "&customer=" 
								+ formData.customerId + "&company=" + this.state.companyInfo.name  
								+ "&env=" + Config.env + "&logo=" + logoLocation;
								
								let body = creditMemoEmailTemplate.body + "<BR/><BR/>" 
								+ creditMemoEmailTemplate.signature + "<BR/><BR/>" 
								+ (creditMemoEmailTemplate.privacyDisclosure != null 
									? creditMemoEmailTemplate.privacyDisclosure : "");
								
								
								let queryString = "parms=" + encodeURIComponent(parms)
								+ "&title=Credit Memo&body=" + encodeURIComponent(body) 
								+ "&orderId=" + formData.id + "&name=" + this.state.companyInfo.name 
								+ "&reportName=BCM_Credit_Memo.rptdesign&cid=" + formData.customerId;
								
								// console.log("queryString: ", queryString);
								getAPI("emails/" + creditMemoEmailTemplate.emailTemplateId 
									+ "/buildAndSend?" + queryString ).then(resEmailSent => {
									// console.log("resEmailSent: ", resEmailSent);
									if(!resEmailSent.success) {
										alert("Error sending order confirmation email");
									}
									else {
										// formData.creditMemoEmailSentDate = (new Date()).getTime();
									}
								});
							});
						
						}
						
						// QBO - Check and save credit memo
						if(qboCustomerReferenceNum > 0) {
							quickbooksAPI({
								endpoint: "/v3/company/realmId/creditmemo?minorversion=40",
								method: "POST",
								contentType: "application/json",
								data: encodeURIComponent(JSON.stringify(qboInvoiceInfo)),
							}).then(invoice => {
								console.log("created credit memo: ", invoice);
								if(invoice && invoice.error == null) {
									// alert("Credit Memo successfully added to quickbooks sandbox!");
								} else {
									// alert(invoice.error + " - Please  send the following msg: " + JSON.stringify(qboInvoiceInfo));
								}
							});
						} else {
							alert("The customer does not exist in QBO or is not linked with MyInkIQ!");
						}
					}
				}
				//LAB REQUEST EMAILS
				if(formData.orderTypeLookupId == constants.lab_request_lookup_id) {
					if(formData.colorMatchEmailSentDate == null
						&& formData.orderStatusLookupId == constants.lab_request_status_completed 
						&& this.state.initStatusId != constants.lab_request_status_completed) {
						
						let name2 = formData.lrTypeLookupId == constants.lr_type_repull_lookup_id ? "Repull - " : 
							(formData.lrTypeLookupId == constants.lr_type_rnd_lookup_id ? "RnD - " : "Color Match - ");
						if (formData.deliveryTypeLookupId == constants.delivery_type_no_draw_downs) 
						{
							name2 += "No Draw Downs";//  + formData.deliveryTypeLookupId;
						}
						else {
							const rowDeliveryType = this.state.deliveryTypesList.filter(row => row.id == formData.deliveryTypeLookupId)[0];
							const deliveryTypeName = rowDeliveryType != undefined ? rowDeliveryType.value : "";
								
							name2 += "Draw Downs " + deliveryTypeName;
						}
						const contactLocale = this.state.customerContactEmailsList.length > 0 
							&& this.state.customerContactEmailsList[0].spanishFlag 
							? "es_us" : "en_us";
						let emailParams = "name=" + name2 + "&locale=" + contactLocale; 
						// console.log("emailParams: ", emailParams);
						await getAPI("emails/list?" + emailParams).then(resEmailTemplate => {
							// console.log("resEmailTemplate: ", resEmailTemplate);
							const colorMatchEmailTemplate = resEmailTemplate.data[0];
							
							getAPI("salesorders/" + formData.id + 
								"/formulas/list").then(resSalesOrderFormulas => {
								
								getAPI("emails/" + colorMatchEmailTemplate.emailTemplateId + 
								"/customers/" + formData.customerId).then(resColorMatchCustomers => 
								{
									//Create email object
									let emailsDto = {};
									emailsDto.customerId = formData.customerId;
									emailsDto.emailType = "Color Match";
									emailsDto.user = loggedInUser;
									emailsDto.bccAddresses = colorMatchEmailTemplate.bccEmail;
									emailsDto.orderId = formData.id;
									
									let body = colorMatchEmailTemplate.body + "<BR/><BR/>";
									//replace {0} with Tracking # for Fed Ex
									if (body != null)
										body = body.replace("{0}", "<a href='https://www.fedex.com/apps/fedextrack/?tracknumbers=" 
										+ formData.shipperTrackingNumber.trim() + "'>" 
										+ formData.shipperTrackingNumber.trim() + "</a>"); 
									body += "<HTML><BODY><TABLE align='left' ><TR><TD align='left' >&nbsp;</TD><TD align='left' ><STRONG>Formula Number</STRONG></TD><TD>&nbsp;</TD><TD align='left' ><STRONG>Formula Name</STRONG></TD></TR>";
									//loop through all color match items and insert into e-mail body.
									let i = 0;
									/*
									var soItem:SalesOrderFormulasDto;
									for (i=0; i< dgSOItems.dataProvider.length; i++) {
										soItem = dgSOItems.dataProvider.getItemAt(i) as SalesOrderFormulasDto;
										body += "<TR><TD>" + (i+1) + ".</TD><TD>" + soItem.formulaNumber + "</TD><TD>&nbsp;</TD><TD>" + soItem.customerDisplayNameWithoutFormulaNumber + "</TD></TR>";
									}
									*/
									resSalesOrderFormulas['data'].forEach((soItem, i) => {
										body += "<TR><TD>" + (i + 1) + ".</TD><TD>" 
										+ soItem.formulaNumber + "</TD><TD>&nbsp;</TD><TD>" 
										+ soItem.formula 
										+ "</TD></TR>";
									});
									body += "<TR><TD colspan='3'><BR/>";
									body += colorMatchEmailTemplate.signature + "<BR/><BR/>" 
									+ (colorMatchEmailTemplate.privacyDisclosure !=null 
									? colorMatchEmailTemplate.privacyDisclosure:"") 
									+ "</TD></TR></TABLE></BODY></HTML>";
									
									
									let logoLocation = Config.baseURL + "images/companies/" 
										+ this.state.companyInfo.id;
									logoLocation = this.state.companyInfo.logoLocation;
									
									let parms = "&salesOrder=" + formData.id + "&customer=" 
									+ formData.customerId + "&company=" 
									+ this.state.companyInfo.name  
									+ "&env=" + Config.env + "&logo=" + logoLocation;
									
									let queryString = "parms=" + encodeURIComponent(parms) 
									+ "&title=Lab Request&body=" + encodeURIComponent(body) 
									+ "&name=" + this.state.companyInfo.name + "&reportName=BCM_Lab_Request.rptdesign&cid=" 
									+ formData.customerId + "&orderId=" + formData.id + "&emailContactId=" + (this.state.formData.customerContactId == null ? 0 : this.state.formData.customerContactId);
		
									// console.log("colormatch/RnD saved - queryString: ", queryString, ", colorMatchEmailTemplate: ", colorMatchEmailTemplate);
									getAPI("emails/" + colorMatchEmailTemplate.emailTemplateId 
										+ "/buildAndSend?" + queryString ).then(resEmailSent => {
										// console.log("resEmailSent: ", resEmailSent);
										if(!resEmailSent.success) {
											alert("Error sending order confirmation email");
										}
										else {
											// formData.colorMatchEmailSentDate = (new Date()).getTime();
										}
									});
									
								});
							});
						});
						
					}
					
					// Samee - This applies ONLY to color match type
					console.log("before sending email - formData.lrTypeLookupId: ", formData.lrTypeLookupId);
					// Samee - Should LR Confirmation be sent for R&D?
					if(	
						// formData.lrTypeLookupId == constants.lr_type_color_match_lookup_id && 
						formData.colorMatchConfirmationEmailSentDate == null
						&& formData.orderStatusLookupId == constants.lab_request_status_confirmed
						&& this.state.initStatusId != constants.lab_request_status_confirmed) {
						
						const contactLocale = this.state.customerContactEmailsList.length > 0 
							&& this.state.customerContactEmailsList[0].spanishFlag 
							? "es_us" : "en_us";
						
						let emailParams = "name=" + (
							formData.lrTypeLookupId == constants.lr_type_repull_lookup_id ? 
							"Repull LR Confirmation" 
							: (
								formData.lrTypeLookupId == constants.lr_type_rnd_lookup_id ? 
								"RnD LR Confirmation" 
								: "Lab Request Confirmation"
							)
						) + "&locale=" + contactLocale; 
						
					
						await getAPI("emails/list?" + emailParams).then(resEmailTemplate => {
							// console.log("resEmailTemplate: ", resEmailTemplate);
							const colorMatchEmailTemplate = resEmailTemplate.data[0];
							
							getAPI("emails/" + colorMatchEmailTemplate.emailTemplateId + 
							"/customers/" + formData.customerId).then(resColorMatchCustomers => {
								let body = colorMatchEmailTemplate.body + "<BR/><BR/>";
								// Samee - ToDos: Add ship date to the body
								const formattedShipDate = isNaN(formData.shipDate) || formData.shipDate == 0  ? formData.shipDate: convertTimestampToDate(formData.shipDate);
								body = body.replace("{1}", formattedShipDate);
								body += colorMatchEmailTemplate.signature + "<BR/><BR/>" 
								+ (colorMatchEmailTemplate.privacyDisclosure != null 
								? colorMatchEmailTemplate.privacyDisclosure:"");
								
								
								let logoLocation = Config.baseURL + "images/companies/" 
									+ this.state.companyInfo.id;
								logoLocation = this.state.companyInfo.logoLocation;

					
								let parms = "&salesOrder=" + formData.id + "&customer=" 
									+ formData.customerId + "&company=" 
									+ this.state.companyInfo.name  
									+ "&env=" + Config.env + "&logo=" + logoLocation;
								
								

								let queryString = "parms=" + encodeURIComponent(parms)
								+ "&title=Color Match&body=" + encodeURIComponent(body) 
								+ "&orderId=" + formData.id 
								+  "&name=" + this.state.companyInfo.name   
								+ "&reportName=BCM_Lab_Request.rptdesign&cid=" 
								+ formData.customerId + "&emailContactId=" + (this.state.formData.customerContactId == null ? 0 : this.state.formData.customerContactId);
								

								// console.log("colormatch/RnD confirmed - queryString: ", queryString, ", colorMatchEmailTemplate: ", colorMatchEmailTemplate);
								getAPI("emails/" + colorMatchEmailTemplate.emailTemplateId 
									+ "/buildAndSend?" + queryString ).then(resEmailSent => {
									// console.log("resEmailSent: ", resEmailSent);
									if(!resEmailSent.success) {
										alert("Error sending order confirmation email");
									}
									else {
										// formData.colorMatchConfirmationEmailSentDate = (new Date()).getTime();
									}
								});
							});

						});
					}
					
					// Check & Send Confirmed LR Modifications
					// console.log("this.state.initStatusId: ", this.state.initStatusId, ", formData.orderStatusLookupId: ", formData.orderStatusLookupId, ", this.state.modifiedData: ", this.state.modifiedData);
					if(formData.orderStatusLookupId == constants.lab_request_status_confirmed && this.state.initStatusId == constants.lab_request_status_confirmed) {
						let modifiedData = this.state.modifiedData;
						const initialOrderFormulas = this.state.initialOrderFormulas;
						modifiedData['colorChanges'] = [];
						// Tracking changes in LabRequest Colors
						const fieldsToCompare = {'colorMatchName': 'Color Match Name', 'anilox': 'Anilox', 'matchToPMS': 'Match to PMS', 'matchToGCMI': 'Match to  GCMI', 'matchToLiquidSample': 'Match to Liquid Sample', 'matchToPrintSample': 'Match to Print Sample', 'matchToOther': 'Match to Other', 'formulaNote': 'Notes', 'btransparent': 'Transparent', 'bopaque': 'Opaque'};
						this.state.orderFormulas.forEach(function(row, i){
							var initialRow = initialOrderFormulas.filter(obj => obj.id == row.id)[0];
							let colorChanges = {};
							
							if(initialRow != undefined) {
								Object.keys(fieldsToCompare).forEach(function(field) {
									if(initialRow[field] != row[field])
										colorChanges[fieldsToCompare[field]] = {'old': "" + initialRow[field], 'new': "" + row[field]};
								});
							} else {
								Object.keys(fieldsToCompare).forEach(function(field) {
									colorChanges[fieldsToCompare[field]] = {'old': "", 'new': "" + row[field]};
								});
							}
							modifiedData['colorChanges'].push(colorChanges);
						});
						// console.log("modifiedData: ", modifiedData);
						if(Object.keys(this.state.modifiedData).length > 0) {
							postAPI("emails/" + formData.id + "/sendLRModifiedNotification?bRnd=" + (formData.lrTypeLookupId == constants.lr_type_rnd_lookup_id ? '1' : ''), modifiedData).then(res => {
								// console.log("res in sendLRModifiedNotification: ", res);
							});
						}
					}
				}
				/*
				if((this.state.initStatusId  != constants.order_status_confirmed 
					&& this.state.formData.orderStatusLookupId == constants.order_status_confirmed)
					|| (this.state.initStatusId != constants.lab_request_status_confirmed
						&&  this.state.formData.orderStatusLookupId == constants.lab_request_status_confirmed)
					|| (this.state.initStatusId != constants.credit_memo_status_confirmed
						&&  this.state.formData.orderStatusLookupId == constants.credit_memo_status_confirmed)
						) {
						// Samee - disabling this
						// formData.confirmationDate = (new Date).getTime();
					} */
					if((this.state.formData.orderStatusLookupId == constants.order_status_confirmed)
					|| (this.state.formData.orderStatusLookupId == constants.lab_request_status_confirmed)
					|| (this.state.formData.orderStatusLookupId == constants.credit_memo_status_confirmed)
						) {
						// Samee - set confirmationDate
						// formData.confirmationDate = (new Date).getTime();
					}
			} else {
				
			}
			
			this.saveFormData(formData);
		}
	}
	
	async sendLREmail(orderId) {
		const formData = this.state.formData;
		// Samee - This one email has to be sent upon entering a LR - R&D
		const contactLocale = this.state.customerContactEmailsList.length > 0 
			&& this.state.customerContactEmailsList[0].spanishFlag 
			? "es_us" : "en_us";
		
		// let emailParams = "name=" + (formData.lrTypeLookupId == constants.lr_type_rnd_lookup_id ? "RnD LR Entered" 
		// : "Lab Request R&D Entered") + "&locale=" + contactLocale; 
		let emailParams = "name=" + encodeURIComponent("Lab Request R&D Entered") + "&locale=" + contactLocale; 
	
		await getAPI("emails/list?" + emailParams).then(resEmailTemplate => {
			// console.log("resEmailTemplate: ", resEmailTemplate);
			const colorMatchEmailTemplate = resEmailTemplate.data[0];
			
			getAPI("emails/" + colorMatchEmailTemplate.emailTemplateId + 
			"/customers/" + formData.customerId).then(resColorMatchCustomers => {
				let body = colorMatchEmailTemplate.body + "<BR/><BR/>";
				body += colorMatchEmailTemplate.signature + "<BR/><BR/>" 
				+ (colorMatchEmailTemplate.privacyDisclosure != null 
				? colorMatchEmailTemplate.privacyDisclosure:"");
				
				
				let logoLocation = Config.baseURL + "images/companies/" 
					+ this.state.companyInfo.id;
				logoLocation = this.state.companyInfo.logoLocation;

	
				let parms = "&salesOrder=" + orderId + "&customer=" 
					+ formData.customerId + "&company=" 
					+ this.state.companyInfo.name  
					+ "&env=" + Config.env + "&logo=" + logoLocation;

				let queryString = "parms=" + encodeURIComponent(parms)
				+ "&title=Color Match&body=" + encodeURIComponent(body) 
				+ "&orderId=" + orderId 
				+  "&name=" + this.state.companyInfo.name   
				+ "&reportName=BCM_Lab_Request.rptdesign&cid=" 
				+ formData.customerId;
				

				// console.log("colormatch/RnD confirmed - queryString: ", queryString, ", colorMatchEmailTemplate: ", colorMatchEmailTemplate, ", orderId: ", orderId);
				
				getAPI("emails/" + colorMatchEmailTemplate.emailTemplateId 
					+ "/buildAndSend?" + queryString ).then(resEmailSent => {
					// console.log("resEmailSent: ", resEmailSent);
					if(!resEmailSent.success) {
						alert("Error sending order entered email");
					}
					else {
						// formData.colorMatchConfirmationEmailSentDate = (new Date()).getTime();
					}
				});
			});

		});
	}
	
	saveFormData(formData) {
		var enteredToConfirmed = false;
		if(this.state.initStatusId == constants.order_status_entered && this.state.formData.orderStatusLookupId == constants.order_status_confirmed) {
			enteredToConfirmed = true;
		}
		postAPI("salesorders", formData).then(resOrder => {
			if(resOrder['success']) {
				this.reactGA.gaEvent("Order", "Save", "Order saved successfully.");
				
				var arrModifiedOrderFormulas = [];
				const salesOrderId = resOrder['data'][0]['id'];
				
				// if(formData.id == 0 && formData.lrTypeLookupId == constants.lr_type_rnd_lookup_id) {
				// Samee - So that the email gets sent if a switch is made from color match to RnD
				if(formData.orderStatusLookupId == 124 && formData.lrTypeLookupId == constants.lr_type_rnd_lookup_id) {
					console.log("salesOrderId: ", salesOrderId);
					this.sendLREmail(salesOrderId);
				}

				var addOrderFormulaPromises = [];
				
				
				for(const i in this.state.orderFormulas) {
					let orderFormula = this.state.orderFormulas[i];
					orderFormula['model'] = 'com.phototype.myinkiq.model.vos.dtos::SalesOrderFormulasDto';
					orderFormula['orderId'] = salesOrderId;
					if(orderFormula['qcDisabled'])
						delete orderFormula['qcDisabled'];
					
					if(orderFormula['completedDisabled'])
						delete orderFormula['completedDisabled'];

					
					// if(enteredToConfirmed) {
						// arrModifiedOrderFormulas.push(orderFormula['id']);
					// } 
					
					if(orderFormula.bHasChanged != undefined && orderFormula.bHasChanged)
					{
						delete(orderFormula.bHasChanged);
						addOrderFormulaPromises.push(postAPI("salesorders/" + salesOrderId + "/formulas", orderFormula).then(resOrderFormula => {
							// console.log("salesorders/" + salesOrderId + "/formulas - resOrderFormula: ", resOrderFormula);
							if(!enteredToConfirmed)
								arrModifiedOrderFormulas.push(resOrderFormula['data'][0]['id']);
						}));
					}
					
				}
			
				var deleteOrderFormulaPromises = [];
				for(const i in this.state.deletedOrderFormulas) {
					let orderFormula = this.state.deletedOrderFormulas[i];
					deleteOrderFormulaPromises.push(postAPI("salesorders/" + salesOrderId + "/formulas/" + orderFormula.id, orderFormula).then(resOrderFormula => {
					}));
				}
			
				const orderSubstrates = this.state.orderSubstrates;
				const substrates = this.state.substrates;
				var addOrderSubstratePromises = [];
				for(const i in substrates) {
					const substrate = substrates[i];
					// Determine whether to insert / edit substrate based on existing id
					const substrateRow = orderSubstrates.filter(obj => obj.substrateTypeLookupId == substrate.value)[0];
					const id = substrateRow ? substrateRow['id'] : 0;
					let orderSubstrate = {"id": id,"name":substrate.label,"salesOrderId":salesOrderId,"substrateTypeLookupId":substrate.value,"sequenceNumber": i + 1};
					orderSubstrate['model'] = 'com.phototype.myinkiq.model.vos.dtos::SalesOrderSubstratesDto';
					addOrderSubstratePromises.push(postAPI("salesorders/" + salesOrderId + "/substrates", orderSubstrate).then(resOrderSubstrate => {
					}));
				}
			
				var deleteOrderSubstratePromises = [];
				for(const i in orderSubstrates) {
					// Determining which substrate to delete based on non-existent substrate
					const orderSubstrate = orderSubstrates[i];
					const substrateRow = substrates.filter(obj => obj.value == orderSubstrate.substrateTypeLookupId)[0];
					if(!substrateRow)
					{
						deleteOrderSubstratePromises.push(postAPI("salesorders/" + salesOrderId + "/substrates/" + orderSubstrate.id, orderSubstrate).then(resOrderSubstrate => {
						}));
					}
				}
				
				var addReportDetailPromises = []
				this.state.reportDetails.forEach(detail => {
					delete detail.bModified;
					addReportDetailPromises.push(postAPI("salesorders/" + salesOrderId + 
						"/reports/details", detail).then(resDetail => {
					}));
				});
				
				var deleteReportDetailPromises = [];
				this.state.deletedReportDetailIDs.forEach(detail => {
					deleteReportDetailPromises.push(postAPI("salesorders/" + salesOrderId + 
						"/reports/details/" + detail.id, detail).then(resDetail => {
					}));
				});
				
				if(this.state.formData.orderTypeLookupId == lab_request_lookup_id) {
					if(this.state.labRequestFormulaIndex != null) {
						this.setState({
							formulaLabRequestId: salesOrderId,
						});
					}
				}
				Promise.all(addOrderFormulaPromises)
				.then(() => {
					// console.log("arrModifiedOrderFormulas: ", arrModifiedOrderFormulas);
					Promise.all(deleteOrderFormulaPromises)
					.then(() => {
						Promise.all(addOrderSubstratePromises)
						.then(() => {
							Promise.all(deleteOrderSubstratePromises)
							.then(() => {
								Promise.all(addReportDetailPromises)
								.then(() => {
									Promise.all(deleteReportDetailPromises)
									.then(() => {
										// alert(<Translate value="components.labels.salesOrderSaved" />);
										if(this.state.formData['orderTypeLookupId'] != lab_request_lookup_id){
											alert("Sales Order has been saved successfully");
											
											if (this.state.formData.orderStatusLookupId == 118){
												
												if(arrModifiedOrderFormulas.length > 0 || enteredToConfirmed) {
													// Open Batch Tickets
													// Samee - Let's just pass batch ticket as a comma separated list of orderFormulaIds
													var modifiedOrderFormulaIds = arrModifiedOrderFormulas.join(',');
													// console.log("modifiedOrderFormulaIds: ", modifiedOrderFormulaIds);
													openBirtReport('BCM_Batch_Ticket', {
														customer: this.state.formData.customerId,
														// formula: 0, // formulaId, // We're no longer passing in the formula so that all formulas get fetched
														salesOrder: this.state.formData.id,
														batchticket: modifiedOrderFormulaIds,
													});
													
													
													// Open Sales Order
													openBirtReport('BCM_Sales_Order', {
															customer: this.state.formData.customerId,
															salesOrder: this.state.formData.id,
														}
													);
													
													getAPI("salesorders/" + this.state.formData.id + "/setBatchPrintedFlags").then(res => {
														// console.log("res upon setBatchPrintedFlags: ", res);
													});
												}
											}
										}
										
										const errorsTemplate = mergeObjects(resetObject(validationFields), mergeObjects(resetObject(salesOrderValidationFields), resetObject(labRequestValidationFields)));
										var formData = this.state.formData;
										formData.id = salesOrderId;
										this.setState({								
											editOrderId: salesOrderId,
											formData: formData,
											errors: errorsTemplate,						
											saveDisabled: false,
										});										
										this.props.onSave();
										if (this.state.formData.statusName === 'Completed')
											this.props.onClose();
										else this.initData();										
									});
								});
							});
						});
					});
				});
			}
		});
	}
	onDelete() {
		postAPI("salesorders/" + this.state.formData.id, this.state.formData).then(resOrder => {
			// alert(<Translate value="components.labels.salesOrderDeleted" />);
			if (resOrder.error != undefined) {
				alert("Error saving Order: " + resOrder.error['error']);
				return false;
			}
			alert("Sales Order has been deleted successfully");
			this.reactGA.gaEvent("Order", "Delete", "Order deleted successfully.");
			if(this.state.formData.orderTypeLookupId != lab_request_lookup_id) {
				let qboOrderType = "invoice";
				let qboEntityType = "Invoice";
				if(this.state.formData.orderTypeLookupId == credit_memo_lookup_id) {
					qboOrderType = "creditmemo";
					qboEntityType = "CreditMemo";
				}
				
				// Looking up the QBO invoice
				quickbooksAPI({
					endpoint: "/v3/company/realmId/query?query=",
					query: "select * from " + qboEntityType + " where DocNumber = '" + this.state.formData.id + "'",
				}).then(invoices => {
					// console.log("invoices: ", invoices);
					if(invoices['QueryResponse'][qboEntityType] != null) {
						const lastIndex = invoices['QueryResponse'][qboEntityType].length - 1;
						const invoiceToDelete = invoices['QueryResponse'][qboEntityType][lastIndex];
						
						// Deleting from QBO
						
						const objData = {
						  "SyncToken": invoiceToDelete['SyncToken'], 
						  "Id": invoiceToDelete['Id'] // replace this with the id of the invoice to delete
						};
						quickbooksAPI({
							endpoint: "/v3/company/realmId/" + qboOrderType + "?operation=delete&minorversion=65",
							method: "POST",
							contentType: "application/json",
							data: encodeURIComponent(JSON.stringify(objData)),
						}).then(invoice => {
							console.log("deleted invoice: ", invoice);
							if(invoice && invoice.error == null) {
								// alert(qboEntityType + " successfully deleted from to quickbooks sandbox!");
							} else {
								// alert(invoice.error + " - Please  send the following msg: " + JSON.stringify(objData));
							}
						});
					}
				});
			}
			
			
			this.props.onSave();
		});
	}
	
	

	render() {
		const loggedInUser = getLoggedInUser();
		var contactListMapped = mapArrayToObject(this.state.contactList, 'id', 'name');
		// console.log("this.state.activeCustomers in render(): ", this.state.activeCustomers);
		var activeCustomersMapped = this.state.activeCustomers.filter(row => row != undefined).map((obj, key) => ({value: obj.id, label: obj.name}));
		let shippingAddressList = this.state.shippingAddressList;
		const customerInfo = this.state.customerInfo;
		
		/*
		// Let's have it the same way as the flex app.
		if(customerInfo) {
			// Adding the Primary Address
			shippingAddressList.unshift({
				customerId: customerInfo['id'],
				addressId: 	customerInfo['addressId'],
				address1: customerInfo['address1'],
				address2: customerInfo['address2'],
				city: customerInfo['city'],
				stateId: customerInfo['stateId'],
				countryId: customerInfo['countryId'],
				phoneNumber: customerInfo['phoneNumber'],
				faxNumber: customerInfo['faxNumber'],
				zipCode: customerInfo['zipCode'],
			});
		}
		*/
		// var shippingAddressListMapped = mapArrayToObject(shippingAddressList, 'addressId', 'address1,address2');
		const shippingAddressListMapped = shippingAddressList.map(obj => [obj.addressId, obj.address1 + (obj.address2 != null ? ' ' + obj.address2 : "")] );
		// var salesRepListMapped = mapArrayToObject(this.state.salesRepList, 'actorId', 'displayName');
		const salesRepListMapped = this.state.salesRepList.map(obj => [obj.actorId, obj.displayName]);
		// console.log("this.state.shipperList: ", this.state.shipperList);
		var shipperListMapped = mapArrayToObject(this.state.shipperList, 'id', 'name');
		
		var customerFormulasListMapped = this.state.customerFormulasList.map((obj, key) => ({id: obj.id, label: obj.name}));
		const customerName = this.state.formData.customerId ? 
			activeCustomersMapped.filter(obj => obj.value == this.state.formData.customerId)[0] : null;
			
		
		const billOfLadingTypes = mapArrayToObject(this.state.billOfLadingTypesList, 'id', 'value');
		
		const substratesList = this.state.substratesList.map(obj => ({value: obj.id, label: obj.name}));
		const inkTypesList = mapArrayToObject(this.state.inkTypesList, 'id', 'value');
		const varnishesList = mapArrayToObject(this.state.varnishesList, 'id', 'value');
		const pressesList = mapArrayToObject(this.state.pressesList, 'id', 'pressName');
		const deliveryTypesList = mapArrayToObject(this.state.deliveryTypesList, 'id', 'value');
		
		const orderDateFormatted = isNaN(this.state.formData.orderDate) || this.state.formData.orderDate == 0 ? this.state.formData.orderDate : 
		convertTimestampToDate(this.state.formData.orderDate);
		
		const shipDateFormatted = isNaN(this.state.formData.shipDate) || this.state.formData.shipDate == 0  ? this.state.formData.shipDate: 
		convertTimestampToDate(this.state.formData.shipDate);

		//const newDeliveryDateFormatted = isNaN(this.state.formData.lateLostDamagedDeliveryDate) || this.state.formData.lateLostDamagedDeliveryDate == 0  ? this.state.formData.lateLostDamagedDeliveryDate: 
		//convertTimestampToDate(this.state.formData.lateLostDamagedDeliveryDate);
		
		const invoiceDateFormatted = isNaN(this.state.formData.invoiceDate) || this.state.formData.invoiceDate == 0 ? this.state.formData.invoiceDate:
		 convertTimestampToDate(this.state.formData.invoiceDate);
		
		const scheduledDeliveryDateFormatted = isNaN(this.state.formData.scheduledDeliveryDate) || this.state.formData.scheduledDeliveryDate == 0 ? this.state.formData.scheduledDeliveryDate:
		 convertTimestampToDate(this.state.formData.scheduledDeliveryDate, true);
		// const scheduledDeliveryDateFormatted = isNaN(this.state.formData.scheduledDeliveryDate) ? this.state.formData.scheduledDeliveryDate: moment().format("MM/DD/YYYY h:mm:ss a");
		
		// Birt Report variables
		const selectedOrderFormulaIndex = this.state.selectedOrderFormulaIndex;
		const formulaId = selectedOrderFormulaIndex != null ?
			 this.state.orderFormulas[selectedOrderFormulaIndex].formulaId : null;
		const orderFormulaId = selectedOrderFormulaIndex != null ?
			 this.state.orderFormulas[selectedOrderFormulaIndex].id : null;
		const orderFormulaQty = selectedOrderFormulaIndex != null ?
			 this.state.orderFormulas[selectedOrderFormulaIndex].quantity : null;
		const qcFlag = selectedOrderFormulaIndex != null ?
			 this.state.orderFormulas[selectedOrderFormulaIndex].qcFlag : false;


		
		if(this.state.bFormSubmitted && this.state.bFormValidated && this.state.bSaveSuccessful)
		{
		
			return <Order />;
		}
		
		
		
		const orderStatuses = this.state.formData.orderTypeLookupId ? this.state.orderStatusesByTypes[this.state.formData.orderTypeLookupId] : this.state.orderStatusesByTypes[sales_order_lookup_id];
		const orderStatusesMapped = Object.keys(orderStatuses).map((key) => ({value: key, label: orderStatuses[key]}));
		const orderStatusMapped = orderStatusesMapped.filter(row => row.value == this.state.formData.orderStatusLookupId)[0];
		
		let weightSymbol = Config.weightSymbol;
		if(this.state.customerInfo) {
			if(this.state.customerInfo.umCodeLookupId == constants.um_code_kg) {
				weightSymbol = 'KG';
			} else if(this.state.customerInfo.umCodeLookupId == constants.um_code_lb) {
				weightSymbol = 'LB';
			} 
		}
		
		var formulasColumns = [
			<Translate value="components.labels.formulaNumber" />, 
			<Translate value="components.labels.formula" />, 
			<Translate value="components.labels.note" />, 
			<Translate value="components.labels.container" />, 
			<Translate value="components.labels.quantity" />, 
			<Translate value={"components.labels." + weightSymbol + "sOrdered"} />, 
			<Translate value="components.labels.unitPrice" />, 
			<Translate value="components.labels.discount" />, 
			<Translate value="components.labels.extPrice" />,
		];
		var formulasFields = ["formulaNumber", "formulaWithNumber", "formulaNote", "containerName", "quantity", "umOrdered", "price", "discountAmt", "extPrice"];
		let formatColumns = {
			4: {showTotal: true},
			5: {showTotal: true},
			6: {showTotal: true, currency: true},
			7: {showTotal: true, currency: true},
			8: {showTotal: true, currency: true},
		};
		
		if(this.state.editOrderId) {
			formulasColumns.push("QC'd");
			formulasColumns.push(<Translate value="components.labels.completed" />);
			formulasFields.push("id");
			formulasFields.push("id");

			formatColumns[9] = {checkbox: {}, colCheckBound: 'qcDate', 
				colDisabledBound: 'qcDisabled', 
				callbackCheckbox: this.chkQCDateChecked
			};
			formatColumns[10] = {checkbox: {}, colCheckBound: 'completedDate', 
				colDisabledBound: 'completedDisabled', 
				callbackCheckbox: this.chkCompletedDateChecked
			};
		}
		var orderFormulas = this.state.orderFormulas;
		orderFormulas = orderFormulas.map(row => {
			const labTechRow = this.state.labTechsList.filter(labTech => labTech.id == row.matchedByUserId)[0];
			row.matchedByUser = labTechRow ? labTechRow.name : "";
			const arrMatchTo = [];
			if(row.matchToGCMI)
				arrMatchTo.push("GCMI " + row.matchToGCMI);
			if(row.matchToLiquidSample)
				arrMatchTo.push("Liquid Sample");
			if(row.matchToPrintSample)
				arrMatchTo.push("Print Sample");
			if(row.matchToPMS)
				arrMatchTo.push("PMS " + row.matchToPMS);
			if(row.matchToOther)
				arrMatchTo.push("Other: " + row.matchToOther);
			row.matchTo = arrMatchTo.join(", ");
			return row;
		});
		/*
		var orderFormulas = [];
		for(const i in this.state.orderFormulas) {
			var orderFormula = this.state.orderFormulas[i];
			// orderFormula['containerName'] = orderFormula['containerId'] ? this.state.containersList.filter(obj => obj.id == orderFormula['containerId'])[0]['name'] : null;
			// orderFormula['formulaWithNumber'] = (orderFormula['customerFormulaName'] != null ? orderFormula['customerFormulaName'] : orderFormula['formula']) + (orderFormula['formulaNumber'] ? " (" + orderFormula['formulaNumber'] + ")" : "");
			orderFormulas.push(orderFormula);
		}*/
		
		const orderTypeLookupId = this.state.formData.orderTypeLookupId ? 
			parseInt(this.state.formData.orderTypeLookupId) : null;
		const editOrderFormula = JSON.parse(JSON.stringify(this.state.editOrderFormula));

		const orderFormulaForm = this.state.bShowOrderFormulaForm ? (<OrderFormulaForm
			visible={this.state.bShowOrderFormulaForm}
			onClose={() => this.setState({bShowOrderFormulaForm: false})}
			onSave={this.onSaveOrderFormula}
			customerId={this.state.formData.customerId}
			bWetSample={this.state.customerInfo ? this.state.customerInfo.bWetSample : false}
			orderTypeLookupId={orderTypeLookupId}
			formulaRow={editOrderFormula} 
			containersList={this.state.containersList} 
			editOrderId={this.state.editOrderId} 
			labRequestFormulaIndex={this.state.labRequestFormulaIndex}
			umCodeLookupId={this.state.customerInfo ? this.state.customerInfo.umCodeLookupId : constants.um_code_lb}
			orderCreatedDate={this.state.formData.createdDate}
			consignmentFlag={this.state.formData.consignmentFlag}
			labTechsList={this.state.labTechsList}
			lrTypeId={this.state.formData.lrTypeLookupId}
			/>)
		: '';
		
		const customerPopin = this.state.bShowCustomerPopin ? (<CustomerPopin
			visible={this.state.bShowCustomerPopin}
			onClose={() => this.setState({bShowCustomerPopin: false})}
			editCustomerId={this.state.formData.customerId} 
			defaultKey={this.state.customerDefaultKey}
			/>)
		: '';
		
		

		const contentBillOfLading = this.state.formData.orderTypeLookupId == sales_order_lookup_id ? <div>
			{this.state.editOrderId && 
			<Button
				bsSize='sm'
				block
				onClick={() => this.setState({bShowBillOfLadingInstructionsForm: true})}
			>
				<Translate value='components.buttons.billOfLadingInstructions' />
			</Button>}
			<DataDropdown
				label={<Translate value="components.labels.billOfLading" />}
				id={"billOfLadingLookupId"}
				name={"billOfLadingLookupId"}
				action={this.handleInputChanged}
				data={billOfLadingTypes} required
				value={this.state.formData.billOfLadingLookupId}
				errMsg={this.state.errors.billOfLadingLookupId}
			/>
		</div>
		: '';

		var lastRowInForm = '';
		let rowContainerTotals = '';
		
		const bFuelChargeRequired = salesOrderValidationFields['fuelSurchargeAmt'].indexOf("required") >= 0;
		const bFreightChargeRequired = salesOrderValidationFields['freightChargeAmt'].indexOf("required") >= 0;
		const bSpectro1 = customerInfo != null && customerInfo.bSpectro1;
		
		const bReasonDamagedFlag = this.state.formData.lateLostDamagedReason == "damage";
		const bReasonLostFlag = this.state.formData.lateLostDamagedReason == "lost";
		const bReasonLateFlag = this.state.formData.lateLostDamagedReason == "late";
		
		if(this.state.formData.orderTypeLookupId == lab_request_lookup_id)
		{
			lastRowInForm = <Row>
				{this.state.formData.lrTypeLookupId != constants.lr_type_repull_lookup_id && <Col md={4}>
					<InputText 
						label={<Translate value="components.labels.substrates" />} id={"substrates"} 
						name={"substrates"} action={this.handleInputChanged} type={"MultiSelect"} 
						data={substratesList} value={this.state.substrates} required
						errMsg={this.state.errors.substrates}
					/>
					<InputText 
						label={<Translate value="components.labels.notes" />} id={"notes"} 
						name={"notes"} action={this.handleInputChanged} type={"TextArea"} 
						value={this.state.formData.notes}  
					/>
					<InputText 
						label={<Translate value="components.customers.lrNotes" />} id={"lrnotes"} 
						name={"lrnotes"} action={this.handleInputChanged} type={"TextArea"} 
						value={this.state.formData.lrnotes}  
					/>
					{
						bSpectro1 && <span><label style={{color: 'red', backgroundColor: '#ffeeee', fontWeight: 'bold'}}><Translate value="components.labels.useSpectro1" /></label><br /></span>
					}
				</Col> }
				{this.state.formData.lrTypeLookupId != constants.lr_type_repull_lookup_id && <Col md={4}>
					<DataDropdown label={<Translate value="components.labels.inkType" />} 
						id={"inkTypeLookupId"} name={"inkTypeLookupId"} 
						action={this.handleInputChanged} data={inkTypesList} 
						value={this.state.formData.inkTypeLookupId} 
					/>
					<DataDropdown label={<Translate value="components.labels.opVarnish" />} 
						id={"varnishTypeLookupId"} name={"varnishTypeLookupId"} 
						action={this.handleInputChanged} data={varnishesList} 
						value={this.state.formData.varnishTypeLookupId} 
					/>
					<DataDropdown label={<Translate value="components.labels.press" />} id={"customerPressId"} name={"customerPressId"} action={this.handleInputChanged} data={pressesList} value={this.state.formData.customerPressId} />

					<InputText label={<Translate value="components.labels.colorTraps" />} id={"colorTraps"} name={"colorTraps"} action={this.handleInputChanged} value={this.state.formData.colorTraps} />
					<InputText label={<Translate value="components.labels.needPrints" />} 
						id={"needPrints"} name={"needPrints"} action={this.handleInputChanged} 
						value={this.state.formData.needPrints} required
						errMsg={this.state.errors.needPrints}
					/>
					<InputText label={<Translate value="components.labels.sendPrints" />} 
						id={"sendPrints"} name={"sendPrints"} action={this.handleInputChanged} 
						value={this.state.formData.sendPrints} required
						errMsg={this.state.errors.sendPrints}
					/>
					<InputText label={<Translate value="components.labels.needLSDPrints" />} 
						id={"needLSDPrints"} name={"needLSDPrints"} action={this.handleInputChanged} 
						value={this.state.formData.needLSDPrints} 
						errMsg={this.state.errors.needLSDPrints}
					/>
					<InputText label={<Translate value="components.labels.sendLSDPrints" />} 
						id={"sendLSDPrints"} name={"sendLSDPrints"} action={this.handleInputChanged} 
						value={this.state.formData.sendLSDPrints} 
						errMsg={this.state.errors.sendLSDPrints}
					/>
				</Col>}
				<Col md={4}>
					<DataDropdown label={<Translate value="components.labels.deliveryType" />} 
						id={"deliveryTypeLookupId"} name={"deliveryTypeLookupId"} 
						action={this.handleInputChanged} data={deliveryTypesList} 
						value={this.state.formData.deliveryTypeLookupId} required
						errMsg={this.state.errors.deliveryTypeLookupId} 
					/>
					<InputText label={<Translate value="components.labels.shipDate" />} type={"DateTime"} id={"shipDate"} name={"shipDate"} action={this.handleInputChanged} value={shipDateFormatted} />
					<InputText label={<Translate value="components.labels.trackingNumber" />} id={"shipperTrackingNumber"} name={"shipperTrackingNumber"} action={this.handleInputChanged} value={this.state.formData.shipperTrackingNumber} />
					<InputText 
						label={<Translate value="components.labels.mustDeliverByDate" />} 
						type={"DateTimestamp"} id={"scheduledDeliveryDate"} 
						name={"scheduledDeliveryDate"} action={this.handleInputChanged} 
						value={scheduledDeliveryDateFormatted} required
						errMsg={this.state.errors.scheduledDeliveryDate}
					/>


				</Col> 
			</Row>;
			
			
			if(this.state.formData.lrTypeLookupId == constants.lr_type_repull_lookup_id) {
				formulasColumns = [
					<Translate value="components.labels.formulaName" />, 
					<Translate value="components.labels.numDrawDowns" />, 
					<Translate value="components.labels.formulaNumber" />, 
					<Translate value="components.labels.completedBy" />, 
					<Translate value="components.labels.note" />
				];
				formulasFields = ["colorMatchName", "anilox", "formulaNumber", "matchedByUser", "formulaNote"];
			} else {
				formulasColumns = [
					<Translate value="components.labels.colorName" />, 
					<Translate value="components.labels.anilox" />, 
					<Translate value="components.labels.matchTo" />, 
					<Translate value="components.labels.matchedBy" />, 
					<Translate value="components.labels.note" />
				];
				formulasFields = ["colorMatchName", "anilox", "matchTo", "matchedByUser", "formulaNote"];
			}
			
			formatColumns = {};
			if(this.state.editOrderId) {
				formulasColumns.push(<Translate value="components.labels.started" />);
				formulasColumns.push(<Translate value="components.labels.completed" />);
				formulasFields.push("id");
				formulasFields.push("id");
				formatColumns = {
					5: {checkbox: {}, colCheckBound: 'startDate', 
						colDisabledBound: 'startDisabled', 
						callbackCheckbox: this.chkStartDateChecked},
					6: {checkbox: {}, colCheckBound: 'completedDate', 
						colDisabledBound: 'completedDisabled', 
						callbackCheckbox: this.chkCompletedDateChecked},
				};
			}
		}
		else
		{	
			
			rowContainerTotals = <span>
			<Row>
				<Col md={12}>
					<DataGrid 
						columns={["Container", "Total"]} 
						fields={["container", "quantity"]}  
						data={this.state.containerTotals} 
						formatColumns={{1: {showTotal: true}}}
					/>
				</Col> 
			</Row>
			<Row>
				<Col md={12}>
					<hr />
				</Col> 
			</Row>
			</span>;
			
			lastRowInForm = <Row>
				<Col md={4}>
					<InputText label={<Translate value="components.labels.orderPreferences" />} id={"orderPreferencesNote"} name={"orderPreferencesNote"} action={this.handleInputChanged} type={"TextArea"} value={this.state.formData.orderPreferencesNote} />
					<InputText label={<Translate value="components.labels.invoiceNotes" />} id={"orderInvoiceNotes"} name={"orderInvoiceNotes"} action={this.handleInputChanged} type={"TextArea"}  value={this.state.formData.orderInvoiceNotes} />
					<InputText label={<Translate value="components.labels.customerNotes" />} id={"notes"} name={"notes"} action={this.handleInputChanged} type={"TextArea"} value={this.state.formData.notes}  />
						{
							this.state.bFuelChargeRequired && <span><label style={{color: 'red', backgroundColor: '#ffeeee', fontWeight: 'bold'}}><Translate value="components.labels.fuelChargeToBeAdded" /></label><br /></span>
						}
						{
							this.state.bFreightChargeRequired && <span><label style={{color: 'red', backgroundColor: '#ffeeee', fontWeight: 'bold'}}><Translate value="components.labels.freightChargeToBeAdded" /></label><br /></span>
						}
						{
							this.state.bPlasticDrumsCustomer && <span><label style={{color: 'blue', backgroundColor: '#eeeeff', fontWeight: 'bold'}}>{<Translate value="components.labels.plasticDrumsToBeAdded" />}</label><br /></span>
						}
						{
							this.state.bConsignmentCustomer && <span><label style={{color: 'blue', backgroundColor: '#eeeeff', fontWeight: 'bold'}}>{<Translate value="components.customers.consignmentCustomer" />}</label><br /></span>
						}
						
				</Col> 
				<Col md={4}>
					{contentBillOfLading}
					<DataDropdown 
						label={<Translate value="components.labels.shipper" />} 
						id={"shipperId"} name={"shipperId"} required
						action={this.handleInputChanged} data={this.state.shipperList} colValue='id' colText='name' 
						value={this.state.formData.shipperId} errMsg={this.state.errors.shipperId} 
						errMsg={this.state.errors.shipperId}
					/>
					<InputText label={<Translate value="components.labels.invoiceDate" />} 
						type={"DateTime"} id={"invoiceDate"} name={"invoiceDate"} 
						action={this.handleInputChanged} value={invoiceDateFormatted}
						bgColor={(this.state.bShipDateChanged ? "#FFAAAA" : null)}
					/>
					<InputText label={<Translate value="components.labels.shipDate" />} type={"DateTime"} id={"shipDate"} name={"shipDate"} action={this.handleInputChanged} value={shipDateFormatted} />
					<InputText label={<Translate value="components.labels.trackingNumber" />} id={"shipperTrackingNumber"} name={"shipperTrackingNumber"} action={this.handleInputChanged} value={this.state.formData.shipperTrackingNumber} />
					<InputText label={<Translate value="components.labels.mustDeliverByDate" />} 
						type={"DateTimestamp"} id={"scheduledDeliveryDate"} 
						name={"scheduledDeliveryDate"} action={this.handleInputChanged} 
						value={scheduledDeliveryDateFormatted} required
						errMsg={this.state.errors.scheduledDeliveryDate}
					/>
					<InputText 
						label={<Translate value="components.labels.isLate" />} 
						id={"bLateLostDamaged"} 
						name={"bLateLostDamaged"} 
						action={this.handleInputChanged}  
						type={"CheckBox"} 
						value={this.state.formData.bLateLostDamaged} 
					/>
					 {this.state.formData.bLateLostDamaged && <DataDropdown 
						label={<Translate value="components.labels.reason" />} 
						name="lateLostDamagedReason"
						data={late} 
						value={this.state.formData.lateLostDamagedReason} 
						action={this.handleInputChanged}
					/>}

                    {((bReasonDamagedFlag || bReasonLostFlag) && this.state.formData.bLateLostDamaged) && <InputText 
					    label={<Translate value="components.labels.amountClaimed" />} 
						id={"amountClaimed"} 
						name={"lateLostDamagedAmountClaimed"} 
						action={this.handleInputChanged} 
						value={this.state.formData.lateLostDamagedAmountClaimed} 
					/>}

                    {((bReasonDamagedFlag || bReasonLostFlag) && this.state.formData.bLateLostDamaged) && <InputText 
					    label={<Translate value="components.labels.amountReceived" />} 
						id={"amountReceived"} 
						name={"lateLostDamagedAmountReceived"} 
						action={this.handleInputChanged} 
						value={this.state.formData.lateLostDamagedAmountReceived} 
					/>}

                    {((bReasonDamagedFlag || bReasonLostFlag || bReasonLateFlag) && this.state.formData.bLateLostDamaged) && <InputText 
					    type='TextArea' 
					    label={<Translate value="components.labels.latecomments" />} 
						id={"latecomments"} 
						name={"lateLostDamagedComment"} 
						action={this.handleInputChanged} 	
						value={this.state.formData.lateLostDamagedComment} 
					/>}

                   {((bReasonDamagedFlag || bReasonLostFlag || bReasonLateFlag) && this.state.formData.bLateLostDamaged) && <InputText 
						label={<Translate value="components.labels.newDeliveryDate" />} 
						type={"DateTime"} 
						id={"newDeliveryDate"} 
						name={"lateLostDamagedDeliveryDate"} 
						action={this.handleInputChanged} 
						value={this.state.formData.lateLostDamagedDeliveryDate} 
					/>}

				</Col>
				<Col md={4}>
					<InputText label={<Translate value="components.labels.discount" />} 
						id={"orderDiscountCreditAmt"} name={"orderDiscountCreditAmt"} 
						action={this.handleInputChanged} 
						value={this.state.formData.orderDiscountCreditAmt} 
						errMsg={this.state.errors.orderDiscountCreditAmt}
					/>
					<InputText label={<Translate value="components.labels.skidCharge" />} 
						id={"skidChargeAmt"} name={"skidChargeAmt"} action={this.handleInputChanged} 
						value={this.state.formData.skidChargeAmt} 
						errMsg={this.state.errors.skidChargeAmt}
					/>
					<InputText label={<Translate value="components.labels.freightCharge" />} 
						required={bFreightChargeRequired}
						id={"freightChargeAmt"} name={"freightChargeAmt"} 
						action={this.handleInputChanged} 
						value={this.state.formData.freightChargeAmt} 
						errMsg={this.state.errors.freightChargeAmt}
					/>
					<InputText label={<Translate value="components.labels.fuelCharge" />} 
						required={bFuelChargeRequired}
						id={"fuelSurchargeAmt"} name={"fuelSurchargeAmt"} 
						action={this.handleInputChanged} 
						value={this.state.formData.fuelSurchargeAmt} 
						errMsg={this.state.errors.fuelSurchargeAmt}
					/>
					<InputText type='Label' label={<Translate value="components.labels.total" />} value={toCurrency(this.state.formData.totalOrderAmt)} />
					{this.state.formData.taxAmount && <span>
						<InputText type='Label' 
							label={<Translate value="components.labels.iva" />} 
							value={toCurrency(this.state.formData.taxAmount)} />
						<InputText type='Label' 
							label={<Translate value="components.labels.grandTotal" />} 
							value={toCurrency(this.state.formData.grandTotal)} />
					</span>}
				</Col> 
			</Row>;
		}
		
		var formulaFormContent = this.state.bShowNewFormulaForm ? 
		<NewFormulaForm
			visible={this.state.bShowNewFormulaForm}
			onClose={() => {
				this.setState({bShowNewFormulaForm: false});
				this.initData();
			}}
			customer={this.state.formData.customerId}
			formulaLabRequestId={this.state.formulaLabRequestId}
			labRequestFormulaIndex={this.state.labRequestFormulaIndex}
			salesOrderFormulaReferenceId={this.state.salesOrderFormulaReferenceId}
			onSave={(newFormulaId) => {
				// console.log("newFormulaId: ", newFormulaId, ", salesOrderFormulaReferenceId: ", this.state.formData.salesOrderFormulaReferenceId, ", labRequestFormulaIndex: ", this.state.labRequestFormulaIndex, ", orderId: ", this.state.formData.id, ", orderFormulas: ", this.state.orderFormulas);
				
				const rowIndex = this.state.labRequestFormulaIndex;
				const existingFormulaId = this.state.orderFormulas[rowIndex]['formulaId'];
				const existingOrderFormulaId = this.state.orderFormulas[rowIndex]['id'];
				

				const salesOrderId = this.state.formData.id;
				// if(existingFormulaId == null) {
					let existingOrderFormula = this.state.orderFormulas[rowIndex];
					existingOrderFormula['formulaId'] = newFormulaId;
					existingOrderFormula['model'] = 'com.phototype.myinkiq.model.vos.dtos::SalesOrderFormulasDto';
					existingOrderFormula['orderId'] = salesOrderId;
					existingOrderFormula['id'] = existingOrderFormulaId;
					if(existingOrderFormula['qcDisabled'])
						delete existingOrderFormula['qcDisabled'];
					
					if(existingOrderFormula['completedDisabled'])
						delete existingOrderFormula['completedDisabled']
					
					// existingOrderFormula = {orderId: salesOrderId, id: existingOrderFormulaId, formulaId: newFormulaId};
					postAPI("salesorders/" + salesOrderId + "/formulas", existingOrderFormula).then(resOrderFormula => {});
				// }
				
			}}
		/> : "";
		
		const billOfLadingInstructionsFormContent = this.state.bShowBillOfLadingInstructionsForm ? 
			<BillOfLadingInstructionsForm
				visible={this.state.bShowBillOfLadingInstructionsForm}
				onClose={() => this.setState({bShowBillOfLadingInstructionsForm: false})}
				salesOrderId={this.state.formData.id}
				orderFormulas={this.state.orderFormulas}
				skidCount={this.state.formData.skidCount}
				billOfLadingInstructions={this.state.formData.billOfLadingInstructions}
				scheduledDeliveryDate={this.state.formData.scheduledDeliveryDate}
				containersList={this.state.containersList}
				umCodeLookupId={this.state.customerInfo ? this.state.customerInfo.umCodeLookupId : constants.um_code_lb}
				onUpdate={(state) => {
					let formData = this.state.formData;
					formData.skidCount = state.formData.skidCount;
					formData.billOfLadingInstructions = state.formData.billOfLadingInstructions;
					const reportDetails = state.editableReportSummary; // .filter(obj => obj.bModified == true);
					const deletedReportDetailIDs = state.deletedReportDetailIDs;
					// console.log("state: ", state, ", reportDetails: ", reportDetails, ", deletedReportDetailIDs: ", deletedReportDetailIDs, ", formData: ", formData);
					this.setState({
						formData: formData,
						reportDetails: reportDetails,
						deletedReportDetailIDs: deletedReportDetailIDs,
						bShowBillOfLadingInstructionsForm: false,
					});
				}}
			/> : "";
		// console.log("this.state.formData.salesRepActorId: ", this.state.formData.salesRepActorId);
		const content =
		<Modal show={this.props.visible} bsSize="large">
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className='form-page' horizontal horizontal>
					<Row>
						<Col md={4}>
							<InputText 
								label={<Translate value="components.labels.customer" />} 
								id={"customerId"} name={"customerId"} 
								action={this.handleInputChanged} required
								errMsg={this.state.errors.customerId} type={"SingleSelect"} 
								value={customerName} data={activeCustomersMapped} />
							<Row>
								<Col md={4}></Col>
								<Col md={8}>
									{loggedInUser.userTypeId != constants.customer_type_id && this.state.hasUnprocessedNotes && this.state.formData.customerId > 0 && 
									<Button
										bsSize='sm'
										block
										onClick={() => {
											this.setState({customerDefaultKey: 5, bShowCustomerPopin: true});
										}}
									>
										<Translate value='components.customers.processNotes' />
									</Button>}
								</Col>
							</Row>
							<DataDropdown 
								label={<Translate value="components.labels.orderType" />} 
								id={"orderTypeLookupId"} name={"orderTypeLookupId"} 
								action={this.handleInputChanged} required
								errMsg={this.state.errors.orderTypeLookupId} data={orderTypes} 
								value={this.state.formData.orderTypeLookupId} 
							/>
							{this.state.formData.orderTypeLookupId != lab_request_lookup_id && 
							<InputText label={<Translate value="components.labels.poNumber" />} 
								id={"poNumber"} name={"poNumber"} action={this.handleInputChanged} 
								value={this.state.formData.poNumber} 
							/>}
							{this.state.formData.orderTypeLookupId != lab_request_lookup_id && <InputText type='DateTime' label={<Translate value="components.labels.orderDate" />} id={"orderDate"} name={"orderDate"} action={this.handleInputChanged} value={orderDateFormatted} />}
							
							<InputText label={<Translate value="components.labels.status" />} 
								id={"orderStatusLookupId"} name={"orderStatusLookupId"} action={this.handleInputChanged} 
								data={orderStatusesMapped} type={"SingleSelect"} 
								value={orderStatusMapped} errMsg={this.state.errors.orderStatusLookupId} 
								disabled = {loggedInUser.userTypeId == constants.customer_type_id || !this.state.editOrderId}
							/>
							{this.state.formData.orderTypeLookupId == lab_request_lookup_id &&<
								DataDropdown label={<Translate value="components.labels.lrType" />} 
							    name={"lrTypeLookupId"} 
								data={lrType}
								action={this.handleInputChanged} 
								value={this.state.formData.lrTypeLookupId} 
							/>}
						</Col>
						<Col md={4}>
							<DataDropdown label={<Translate value="components.labels.salesRep" />} 
								id={"salesRepActorId"} name={"salesRepActorId"} 
								action={this.handleInputChanged} required
								errMsg={this.state.errors.salesRepActorId} data={salesRepListMapped} 
								value={this.state.formData.salesRepActorId} 
							/>
							<DataDropdown label={<Translate value="components.labels.contact" />} id={"customerContactId"} name={"customerContactId"} action={this.handleInputChanged} data={contactListMapped} value={this.state.formData.customerContactId} />
							{loggedInUser.userTypeId != constants.customer_type_id && 
							<div>
								<InputText label={<Translate value="components.labels.consignment" />} id={"consignmentFlag"} name={"consignmentFlag"} action={this.handleInputChanged} type={"CheckBox"} value={this.state.formData.consignmentFlag} />
								<InputText label={<Translate value="components.labels.consignmentBilling" />} id={"consignmentBillingFlag"} name={"consignmentBillingFlag"} action={this.handleInputChanged} type={"CheckBox"} value={this.state.formData.consignmentBillingFlag} />
							</div>
							}
						</Col>
						<Col md={4}>
							<DataDropdown 
								label={<Translate value="components.labels.shippingAddress" />} 
								id={"shippingAddressId"} name={"shippingAddressId"} 
								action={this.handleInputChanged} data={shippingAddressListMapped} 
								value={this.state.formData.shippingAddressId} required
								errMsg={this.state.errors.shippingAddressId}  
							/>
							<InputText type={"Label"} label={<Translate value="components.labels.billingAddress" />} id={"billingAddress"} name={"billingAddress"} value={this.state.billingAddress} />
							<InputText type={"Label"} label={<Translate value="components.labels.email" />} id={"email"} name={"email"} value={this.state.email} />
							{loggedInUser.userTypeId != constants.customer_type_id && 
							<Button 
								onClick={() => {
									this.setState({customerDefaultKey: 1, bShowCustomerPopin: true});
								}}> <Translate value="components.labels.addAddress" /> </Button>
							}
							{/*<div>
								Billing Address: &nbsp; <span>{this.state.billingAddress}</span>
							</div>
							<div>
								E-mail: &nbsp; <span>{this.state.email}</span>
							</div>*/}
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<hr />
						</Col> 
					</Row>
					<Row>
						<Col md={12}>
							<DataGrid 
								loading={this.state.saveDisabled}
								columns={formulasColumns} 
								fields={formulasFields} 
								onDoubleClick={this.onEditOrderFormula} 
								onSelect={this.onSelectOrderFormula} 
								data={orderFormulas} 
								formatColumns={formatColumns}
								/>
							<Button id={"btnAddFormula"} onClick={this.onAddOrderFormula} disabled={loggedInUser.accessOrdersCode == 'V'} ><Translate value="components.buttons.add" /></Button>
							&nbsp;
							<Button id={"btnRemoveFormula"} onClick={this.onRemoveOrderFormula} disabled={loggedInUser.accessOrdersCode == 'V'} ><Translate value="components.buttons.remove" /></Button>
							{orderFormulaForm}
							{customerPopin}
						</Col> 
					</Row>
					<Row>
						<Col md={12}>
							<hr />
						</Col> 
					</Row>
					{rowContainerTotals}
					{lastRowInForm}
				</Form>
				<OrderFormDeleteConfirmation
					visible={this.state.bShowDeleteConfirmation}
					onConfirm={this.onDelete}
					onClose={() => this.setState({bShowDeleteConfirmation: false})}
				/>
				{formulaFormContent}
				{billOfLadingInstructionsFormContent}
			</Modal.Body>
			<Modal.Footer>
				{this.state.editOrderId ? 
				<span>
					<Button
						bsStyle='danger'
						onClick={() => this.setState({bShowDeleteConfirmation: true})} disabled={loggedInUser.accessOrdersCode == 'V' || (this.state.initStatusId >= constants.order_status_shipped_invoiced && loggedInUser.userTypeId != constants.vip_type_id && loggedInUser.userTypeId != constants.admin_type_id && loggedInUser.userTypeId != constants.labtech_type_id)}
					>
						<Translate value="components.buttons.deleteOrder" />
					</Button>&nbsp;
					{
						// Sales Order Report
						loggedInUser.userReports[constants.sales_order_report_id] && 
						this.state.formData.orderTypeLookupId != lab_request_lookup_id && 
						this.state.formData.orderTypeLookupId != credit_memo_lookup_id && 
						<Button 
							onClick={() => openBirtReport('BCM_Sales_Order', {
									customer: this.state.formData.customerId,
									salesOrder: this.state.formData.id,
								}
							)}
							bsStyle={Config.reportBtnStyle}
						>
							<Translate value="components.reports.salesOrder" />
						</Button>
					}
					&nbsp;
					{
						// Invoice Order Report
						loggedInUser.userReports[constants.invoice_report_id] && 
						this.state.formData.orderTypeLookupId != lab_request_lookup_id && 
						this.state.formData.orderTypeLookupId != credit_memo_lookup_id && 
						<Button 
							onClick={() => openBirtReport('BCM_Invoice', {
									customer: this.state.formData.customerId,
									salesOrder: this.state.formData.id,
								}
							)}
							bsStyle={Config.reportBtnStyle}
						>
							<Translate value="components.reports.invoice" />
						</Button>
					}
					&nbsp;
					{
						// Batch Ticket Report
						loggedInUser.userReports[constants.batch_ticket_report_id] && 
						this.state.formData.orderTypeLookupId != lab_request_lookup_id && 
						this.state.formData.orderTypeLookupId != credit_memo_lookup_id && 
						<Button 
							onClick={() => {
								
								openBirtReport('BCM_Batch_Ticket', {
									customer: this.state.formData.customerId,
									// formula: 0, // formulaId, // We're no longer passing in the formula so that all formulas get fetched
									salesOrder: this.state.formData.id,
								});
							}}
							bsStyle={Config.reportBtnStyle}
							
						>
							<Translate value="components.reports.batchTicket" />
						</Button>
					}
					&nbsp;
					{
						// Bill of Lading Report
						loggedInUser.userReports[constants.bill_of_lading_report_id] && 
						this.state.formData.orderTypeLookupId != lab_request_lookup_id && 
						this.state.formData.orderTypeLookupId != credit_memo_lookup_id && 
						<Button 
							onClick={() => openBirtReport('BCM_Bill_Of_Lading', {
									orderId: this.state.formData.id,
								}
							)}
							bsStyle={Config.reportBtnStyle}
						>
							<Translate value="components.reports.billOfLading" />
						</Button>
					}
					&nbsp;
					{
						// Bucket Label Report
						loggedInUser.userReports[constants.bucket_label_report_id] && 
						this.state.formData.orderTypeLookupId != lab_request_lookup_id && 
						this.state.formData.orderTypeLookupId != credit_memo_lookup_id && 
						<Button 
							onClick={() => openBirtReport('BCM_Bucket_Label', {
									customer: this.state.formData.customerId,
									formula: formulaId,
									orderFormula: orderFormulaId,
									numberOfCopies: orderFormulaQty,
									salesOrder: this.state.formData.id
								}
							)}
							bsStyle={Config.reportBtnStyle}
							disabled={selectedOrderFormulaIndex == null || !qcFlag}
						>
							<Translate value="components.reports.bucketLabel" />
						</Button>
					}
					&nbsp;
					{
						// Packing Slip Report
						loggedInUser.userReports[constants.packing_slip_report_id] && 
						this.state.formData.orderTypeLookupId != lab_request_lookup_id && 
						this.state.formData.orderTypeLookupId != credit_memo_lookup_id && 
						<Button 
							onClick={() => openBirtReport('BCM_Packing_Slip', {
									customer: this.state.formData.customerId,
									salesOrder: this.state.formData.id,
								}
							)}
							bsStyle={Config.reportBtnStyle}
						>
							<Translate value="components.reports.packingSlip" />
						</Button>
					}
					&nbsp;
					{
						// Lab Request Form
						this.state.formData.orderTypeLookupId == lab_request_lookup_id && 
						<Button 
							onClick={() => openBirtReport('BCM_Lab_Request', {
									customer: this.state.formData.customerId,
									salesOrder: this.state.formData.id,
								}
							)}
							bsStyle={Config.reportBtnStyle}
						>
							<Translate value="components.reports.labRequestForm" />
						</Button>
					}
					&nbsp;
					{
						//CREDIT MEMO
						this.state.formData.orderTypeLookupId == credit_memo_lookup_id &&
						<Button 
							onClick={() => openBirtReport('BCM_Credit_Memo', {
									customer: this.state.formData.customerId,
									salesOrder: this.state.formData.id,
								}
							)}
							bsStyle={Config.reportBtnStyle}
						>
							<Translate value="components.reports.creditMemo" />
						</Button>
					}
					&nbsp;
				</span>
				 : ''}
				<Button onClick={() => {
					this.props.onClose(); 
					console.log("qboInvoiceInfo: ", this.getQboInvoiceInfo());
				}}>
					<Translate value="components.buttons.close" />
				</Button>
				{(loggedInUser.userName == 'mmurphy' || loggedInUser.userName == 'rburton') && 
					<Button onClick={() => {
						var qboEntityType = 'Invoice';
						if(this.state.formData.orderTypeLookupId == credit_memo_lookup_id) {
							qboEntityType = "CreditMemo";
						}
						quickbooksAPI({
							endpoint: "/v3/company/realmId/query?query=",
							query: "select * from " + qboEntityType + " where DocNumber='" + this.state.formData.id + "'",
						}).then(invoices => {
							// console.log("invoices: ", invoices);
							if(invoices['QueryResponse']['Invoice'] != null && invoices['QueryResponse']['Invoice'].length > 0) {
								alert('Invoice already exists in QBO');
							} else {
								quickbooksAPI({
									endpoint: "/v3/company/realmId/" + qboEntityType.toLowerCase() + "?minorversion=65",
									method: "POST",
									contentType: "application/json",
									data: encodeURIComponent(JSON.stringify(this.getQboInvoiceInfo())),
								}).then(invoice => {
									// console.log("created invoice: ", invoice);
									if(invoice == null) {
										alert('Invoice could not be transferred successfully!');
									}
								});
							}

						});
						console.log("qboInvoiceInfo: ", this.getQboInvoiceInfo());
					}}>
						<Translate value="components.buttons.Transfer to QBO" />
					</Button>
				}
				<Button onClick={this.handleSaveClicked} bsStyle="primary" disabled={loggedInUser.accessOrdersCode == 'V' || this.state.saveDisabled}><Translate value="components.buttons.saveChanges" /></Button>
			</Modal.Footer>
		</Modal>
		
		return content;
	}
}

export default OrderForm;


const orderFormulaLabRequestValidationFields = {
};

const orderFormulaSalesOrderValidationFields = {
	formulaId: "required",
	containerId: "required",
	quantity: "required,numeric,positive",
	umOrdered: "required,numeric",
	umMade: "numeric",
	discountAmt: "numeric",
};

class OrderFormulaForm extends Component
{
	constructor(props) {
		super(props);
		
		const errorsTemplate = mergeObjects(resetObject(orderFormulaLabRequestValidationFields), resetObject(orderFormulaSalesOrderValidationFields));
		
		this.state = {
			title: "New Formula",
			formData: {
				anilox:"",
				btransparent: false,
				bopaque: false,
				colorMatchName:"",
				completedDate:null,
				startDate:null,
				completedFlag:false,
				containerId:null,
				customerFormulaName:"",
				customerFormulaPriceId:0,
				discountAmt:0.0,
				dryerPercentage:0.0,
				duration:0,
				formula:"",
				formulaWithNumber: "",
				formulaId:null,
				formulaNote:"",
				formulaNumber:"",
				formulaReadId:0,
				formulaTypeLookupId: null,
				id:0,
				matchedByUserId:null,
				matchTo: "",
				matchToGCMI:"",
				matchToLiquidSample:null,
				matchToOther:"",
				matchToPMS:"",
				matchToPrintSample:null,
				model: 'com.phototype.myinkiq.model.vos.dtos::SalesOrderFormulasDto',
				name: null,
				orderId: 0,
				pH:0,
				price:0, // Unit Price
				extPrice: 0,
				qcDate:null,
				qcFlag:false,
				qcDisabled: false,
				completedDisabled: false,
				quantity:1,
				sellPrice:null,
				sequenceNumber:0,
				slideAngle:0.0,
				umMade:0.0,
				umOrdered:1,
				umShipped:1,
				viscosity:"",
				startDate:null,
				bBatchPrinted:null,
			},
			errors: errorsTemplate,
			formulaName: null, // The value to be displayed in the Single Select
			
			customerFormulasList: [],
			matchTosList: [],
			salesUsers: [],
			formulaSubstratesList: [],
			customerFormulaPricing: [],
			// labTechsList: [],
			
			// indexedContainersList: {},
			editRowIndex: null, // Index of the row to be edited. This is set when a row is double clicked
			bHasChanged: false,
			weightExceptionList: [],
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSaveOrderFormula = this.onSaveOrderFormula.bind(this);
		this.refreshExtPriceData = this.refreshExtPriceData.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		
		var bUpdate = true;
		// Update only if indexedContainersList contains something
		// bUpdate = this.state.indexedContainersList != {};
		// var numKeys = Object.keys(indexedContainersList).length;
		var numKeys = this.props.containersList.length;
		bUpdate = numKeys > 0;
		return bUpdate;
	}

	componentDidMount() {
		var customerFormulasList = this.state.customerFormulasList;
// 		var indexedContainersList = {};
		const customerId = this.props.customerId;
		var containerId = this.state.formData.containerId;
		const orderTypeLookupId = this.props.orderTypeLookupId;
		
		var formData = this.state.formData;

		if(orderTypeLookupId == lab_request_lookup_id)
		{
			// Samee - We really don't need all this info - we just need customer formulas
			/*
			getAPI("salesorders/matchtos/list").then(resMatchTosList => {
				getAPI("users?type=Sales").then(resUsers => {
					getAPI("formulas/substrates").then(resFormulaSubstrates => {
						getAPI("users/labtechsnrnds").then(resLabTechs => {
							this.setState({
								matchTosList: resMatchTosList['data'],
								salesUsers: resUsers['data'],
								formulaSubstratesList: resFormulaSubstrates['data'],
								// labTechsList: resLabTechs['data'],
							});
							if(this.props.formulaRow) {
								const formData = this.props.formulaRow;
								this.setState({
									formData: formData,
									title: formData['formula'],
								});
							}
						});
					});
				});
			});
			*/
			getAPI("customers/" + customerId + "/formulas").then(resCustomerFormulasList => {
				let activeFormulas = resCustomerFormulasList["data"].filter(row => row.status != "Inactive");
				// activeFormulas = activeFormulas.filter(row => row.formulaType != "Blending Ink");
				this.setState({
					customerFormulasList: activeFormulas,
				});
				if(this.props.formulaRow) {
					const formData = this.props.formulaRow;
					this.setState({
						formData: formData,
						title: formData['formula'],
					});
				}
			});
		}
		else
		{
			getAPI("customers/" + customerId + "/formulas").then(resCustomerFormulasList => {
				let activeFormulas = resCustomerFormulasList["data"].filter(row => row.status != "Inactive");
				activeFormulas = activeFormulas.filter(row => row.formulaType != "Blending Ink");
				this.setState({
					customerFormulasList: activeFormulas,
				});

				if(this.props.formulaRow) {
					const formData = this.props.formulaRow;
					const formulaId = formData['formulaId'];
					getAPI("formulas/" + formulaId + "/customers/" + customerId + 
						"/pricing").then(resCustomerFormulasPricing => {
						getAPI("formulas/" + formulaId + "/weight/exceptions").then(resWeightException => {
							this.setState({
								customerFormulaPricing: resCustomerFormulasPricing['data'],
								formData: formData,
								weightExceptionList: resWeightException['data'],
							});
						});
					});
				}
			});
		}
		
		
	}
	
	async refreshExtPriceData(name, value, formulaId, formData) {
		// Auto fill the lbs ordered and shipped and calculate pricing
		var umOrdered = this.state.formData.umOrdered;
		var umShipped = this.state.formData.umShipped;
		var umMade = this.state.formData.umMade;
		var price = this.state.formData.price;
		var extPrice = this.state.formData.extPrice;
		formulaId = this.state.formData.formulaId;
		const customerId = this.props.customerId;
		
		var containerId = name == 'containerId' ? value : this.state.formData.containerId;
		var discountAmt = name == 'discountAmt' ? (isNaN(value) ? 0 : value) : this.state.formData.discountAmt;
		var quantity = name == 'quantity' ? (isNaN(value) ? 0 : value) : this.state.formData.quantity;
		
		const containerRow = this.props.containersList.filter(obj => obj.id == containerId)[0];
		const weightFactor = (this.props.umCodeLookupId == constants.um_code_kg && Config.locale === "EN_US") ? Config.weightConversionUnit : 1;

		// Samee - ToDos: Should it be this: // new Date(new Date().toDateString());
		const orderCreatedDate = new Date((new Date()).toDateString()); // (new Date()).getTime(); // this.props.orderCreatedDate ? this.props.orderCreatedDate : (new Date()).getTime(); 
		// console.log("(new Date()).toDateString(): ", (new Date()).toDateString());
		const consignmentFlag = this.props.consignmentFlag ? this.props.consignmentFlag : false;
		
		let weightExceptionList = this.state.weightExceptionList;
		
		// if(indexedContainersList[containerId] != undefined)
		// console.log("containerRow: ", containerRow);
		if(containerRow != undefined)
		{
			if(name == 'umOrdered')
			{
				umOrdered = parseFloat(value);
				umShipped = umOrdered;
				umMade = umOrdered;
			}
			else if(name == 'quantity' || name == 'containerId')
			{
				let containerWeight = containerRow['weight'];
				let weightExceptionRow = null;
				if(name == 'quantity' || name == 'containerId') {
					
					await getAPI("customers/" + customerId + "/weight/exceptions/list").then(resCustomerWeightException => {
						// If 
						if(resCustomerWeightException['data'] != null && resCustomerWeightException['data'].length > 0) {
							weightExceptionRow = resCustomerWeightException['data'].filter(obj => obj.containerId == containerId && obj.weight > 0)[0];
						} 
					});
					if(weightExceptionRow == null && formulaId)
					{
						await getAPI("formulas/" + formulaId + "/weight/exceptions").then(resWeightException => {
							// console.log("resWeightException: ", resWeightException, ", containerId: ", containerId, ", formulaId: ", formulaId);
							weightExceptionList = resWeightException['data'];
							weightExceptionRow = resWeightException['data'].filter(obj => obj.containerId == containerId && obj.weight > 0)[0];
						});
					}
					
				}
				// console.log("weightExceptionRow: ", weightExceptionRow, ", this.props.umCodeLookupId: ", this.props.umCodeLookupId, ", Config.locale: ", Config.locale, ", containerKgWeightsToOverride: ", containerKgWeightsToOverride);
				if(weightExceptionRow)
					containerWeight = weightExceptionRow.weight;
				
				// Samee - TODOS - Should weight be divided by weight factor? 
				umOrdered = Math.round(quantity * containerWeight / weightFactor);
				// Samee - Overriding default container KG weights for specific containers
				// In case NO weight exceptions for customer/formula have been defined
				if(!weightExceptionRow) {
					// ONLY FOR International customers
					if((this.props.umCodeLookupId == constants.um_code_kg && Config.locale === "EN_US") || (this.props.umCodeLookupId == constants.um_code_lb && Config.locale === "ES_MX")) {
						// const containerWeightToOverride = containerKgWeightsToOverride[containerId];
						containerWeight = containerRow['overrideWt'];
						umOrdered = Math.round(quantity * containerWeight);
					}
					
				}
				umShipped = umOrdered;
				umMade = umOrdered;
				
				// console.log("in else - name: ", name, ", weightExceptionRow: ", weightExceptionRow, ", quantity: ", quantity, ", containerWeight: ", containerWeight, ", weightFactor: ", weightFactor, ", umOrdered: ", umOrdered);
			}
	
			
			// var pricingRow = this.state.customerFormulaPricing.filter(obj => obj.containerId == containerId && quantity >= obj.minAmount && (obj.maxAmount == null || quantity <= obj.maxAmount));
			// This is how it was done in the Flex App
			var pricingRow = this.state.customerFormulaPricing.filter(obj => 
				obj.containerId == containerId 
				&& obj.minAmount <= quantity
				&& (obj.maxAmount == null 
					|| obj.maxAmount == 0 
					|| obj.maxAmount >= quantity)
				&& (obj.expirationDate == null || obj.expirationDate >= orderCreatedDate)
				&& orderCreatedDate >= obj.effectiveDate
			);
			if(pricingRow[0]) {
				price = consignmentFlag ? 0 : pricingRow[0].price;
				// extPrice = price * umOrdered - discountAmt; // This may need to be adjusted
				extPrice = calculateExtPrice(price, umOrdered, discountAmt);
			}
			else {
				alert("Pricing does not exist for the specified container quantity. Please select a different container quantity.");
			}
			formData['containerName'] = containerRow['name'];
		}
		
		// Samee - ToDos: Adding 0.3 / 0.6 for mexico to UM Made
		if(Config.env == 'mex') { // change 'dev' to 'mex'
			// Samee - Don't do this if discount was typed in
			if(name != 'discountAmt')
				umMade += (this.props.bWetSample ? 0.6 : 0.3);
		}
		// console.log("Config.env: ", Config.env, ", this.props.bWetSample: ", this.props.bWetSample, ", umMade: ", umMade);

		formData['umOrdered'] = umOrdered;
		formData['umShipped'] = umShipped;
		formData['umMade'] = umMade;
		formData['price'] = price;
		formData['extPrice'] = extPrice;
		
		formData[name] = value;
		
		this.setState({
			formData: formData,
			bHasChanged: true,
			weightExceptionList: weightExceptionList,
		});
	}
	
	
	async handleInputChanged(event, name) { // (id, value, type) {

		const target = event.target;
		var value = '';
		var customerId = this.props.customerId;
		var formData = this.state.formData;
		var formulaId = null;
		
		switch(name) 
		{
	
			case 'formulaId':
				value = event.value;
				const formulaName = event;
				formulaId = value;
				const customerId = this.props.customerId;
				formData['formulaId'] = formulaId;
				const formulaRow = this.state.customerFormulasList.filter(obj => obj.formulaId == formulaId)[0];
				
				if(formulaRow){
					formData['formula'] = formulaRow['name'];
					formData['formulaNumber'] = formulaRow['formula'];
					// May want to use this pattern for the formula label
					// label: obj.formula + " - " + (obj.customerFormulaName ? obj.customerFormulaName : obj.name)
					formData['formulaWithNumber'] = // formulaRow['formula'] + " - " + 
					(formulaRow['customerFormulaName'] ? formulaRow['customerFormulaName'] : formulaRow['name']) + " (" + formulaRow['formula'] + ')';
					
					
				}
				if(this.props.lrTypeId == constants.lr_type_repull_lookup_id) {
					formData['colorMatchName'] = (formulaRow['customerFormulaName'] ? formulaRow['customerFormulaName'] : formulaRow['name']);
					this.setState({
						formData: formData,
					});
				} else {
					// console.log("formulaRow upon selecting formula: ", formulaRow, ", formData: ", formData);
					getAPI("formulas/" + formulaId + "/customers/" + customerId + "/pricing").then(resCustomerFormulasPricing => {
						this.setState({
							customerFormulaPricing: resCustomerFormulasPricing['data'],
							formData: formData,
						});
						this.refreshExtPriceData(name, value, formulaId, formData);
					});
				}
				
				break;
			
			// If a container is selected
			case 'containerId':
			case 'discountAmt':
			case 'quantity':
			case 'umOrdered':
				value = target.value;
				this.refreshExtPriceData(name, value, formulaId, formData);
				/*
				// Auto fill the lbs ordered and shipped and calculate pricing
				var umOrdered = this.state.formData.umOrdered;
				var umShipped = this.state.formData.umShipped;
				var umMade = this.state.formData.umMade;
				var price = this.state.formData.price;
				var extPrice = this.state.formData.extPrice;
				formulaId = this.state.formData.formulaId;
				
				var containerId = name == 'containerId' ? value : this.state.formData.containerId;
				var discountAmt = name == 'discountAmt' ? (isNaN(value) ? 0 : value) : this.state.formData.discountAmt;
				var quantity = name == 'quantity' ? (isNaN(value) ? 0 : value) : this.state.formData.quantity;
				
				const containerRow = this.props.containersList.filter(obj => obj.id == containerId)[0];
				const weightFactor = (this.props.umCodeLookupId == constants.um_code_kg && Config.locale === "EN_US") ? Config.weightConversionUnit : 1;
				const orderCreatedDate = (new Date()).getTime(); // this.props.orderCreatedDate ? this.props.orderCreatedDate : (new Date()).getTime();
				const consignmentFlag = this.props.consignmentFlag ? this.props.consignmentFlag : false;
				
				let weightExceptionList = this.state.weightExceptionList;
				
				// if(indexedContainersList[containerId] != undefined)
				// console.log("containerRow: ", containerRow);
				if(containerRow != undefined)
				{
					if(name == 'umOrdered')
					{
						umOrdered = parseFloat(value);
						umShipped = umOrdered;
						umMade = umOrdered;
					}
					else if(name == 'quantity' || name == 'containerId')
					{
						let containerWeight = containerRow['weight'];
						if(name == 'containerId' && formulaId) {
							await getAPI("formulas/" + formulaId + "/weight/exceptions").then(resWeightException => {
								// console.log("resWeightException: ", resWeightException, ", containerId: ", containerId, ", formulaId: ", formulaId);
								weightExceptionList = resWeightException['data'];
							});
						}
						const weightExceptionRow = weightExceptionList.filter(obj => obj.containerId == containerId && obj.formulaId == formulaId && obj.weight > 0)[0];
						if(weightExceptionRow)
							containerWeight = weightExceptionRow.weight;

						if(weightExceptionRow)
							containerWeight = weightExceptionRow.weight;
						
						// console.log("in else - name: ", name, ", weightExceptionRow: ", weightExceptionRow, ", containerWeight: ", containerWeight);
						umOrdered = Math.round(quantity * containerWeight / weightFactor);
						umShipped = umOrdered;
						umMade = umOrdered;
					}
			
					
					// var pricingRow = this.state.customerFormulaPricing.filter(obj => obj.containerId == containerId && quantity >= obj.minAmount && (obj.maxAmount == null || quantity <= obj.maxAmount));
					// This is how it was done in the Flex App
					var pricingRow = this.state.customerFormulaPricing.filter(obj => 
						obj.containerId == containerId 
						&& obj.minAmount <= quantity
						&& (obj.maxAmount == null 
							|| obj.maxAmount == 0 
							|| obj.maxAmount >= quantity)
						&& (obj.expirationDate == null || obj.expirationDate >= orderCreatedDate)
						&& orderCreatedDate >= obj.effectiveDate
					);
					if(pricingRow[0]) {
						price = consignmentFlag ? 0 : pricingRow[0].price;
						// extPrice = price * umOrdered - discountAmt; // This may need to be adjusted
						extPrice = calculateExtPrice(price, umOrdered, discountAmt);
					}
					else {
						alert("Pricing does not exist for the specified container quantity. Please select a different container quantity.");
					}
					formData['containerName'] = containerRow['name'];
				}

				formData['umOrdered'] = umOrdered;
				formData['umShipped'] = umShipped;
				formData['umMade'] = umMade;
				formData['price'] = price;
				formData['extPrice'] = extPrice;
				
				formData[name] = value;
				
				this.setState({
					formData: formData,
					bHasChanged: true,
					weightExceptionList: weightExceptionList,
				});
				*/
				
				break;
			
			case 'qcDate':
				formData[name] = target.checked ? (new Date()).getTime() : null;
				formData['qcFlag'] = target.checked;
				
				if(target.checked) {
					openBirtReport('BCM_Bucket_Label', {
						customer: this.props.customerId,
						formula: this.state.formData.formulaId,
						orderFormula: this.state.formData.id,
						numberOfCopies: this.state.formData.quantity,
						salesOrder: this.state.formData.id
					});
				}
				
				this.setState({
					formData: formData,
				});
				break;
				
			case 'completedDate':
				formData[name] = target.checked ? (new Date()).getTime() : null;
				formData['completedFlag'] = target.checked;
				this.setState({
					formData: formData,
				});
				break;
				
			case 'startDate':
				formData[name] = target.checked ? (new Date()).getTime() : null;
				formData['startFlag'] = target.checked;
				this.setState({
					formData: formData,
				});
				break;
			
			default:
				value = target.type === 'checkbox' ? target.checked : target.value;
				formData[name] = value;
				
				this.setState({
				  formData: formData,
				});

		}
	}
	
	onSaveOrderFormula(event) {
		event.preventDefault();
		
		// Validate Form
		let validation = {};
		if(this.props.orderTypeLookupId == lab_request_lookup_id) {
			validation = validateForm(this.state.formData, orderFormulaLabRequestValidationFields);
		}
		else {
			validation = validateForm(this.state.formData, orderFormulaSalesOrderValidationFields);
			
			// Checking if the weight entered exceeds the maximum container limit
			const containerRow = this.props.containersList.filter(row => row.id == this.state.formData.containerId)[0];
			let maxContainerWt = (
				(this.props.umCodeLookupId == constants.um_code_kg && Config.locale === "EN_US") || 
				(this.props.umCodeLookupId == constants.um_code_lb && Config.locale === "ES_MX")
			) ? containerRow['maxOverrideWt'] : containerRow['maxWeight'];
			
			// if formula container weight is defined set it
			
			let formulaContainerRow = this.state.weightExceptionList.filter(row => row.formulaId == this.state.formData.formulaId && row.containerId == this.state.formData.containerId)[0];
			let formulaContainerWt = formulaContainerRow != null ? formulaContainerRow.weight : 0;
			const weightFactor = (this.props.umCodeLookupId == constants.um_code_kg && Config.locale === "EN_US") ? Config.weightConversionUnit : 1;
			formulaContainerWt = Math.round(formulaContainerWt / weightFactor);
			
			console.log("maxContainerWt: ", maxContainerWt, ", formulaContainerWt: ", formulaContainerWt);
			if(formulaContainerWt > 0 && formulaContainerWt > maxContainerWt)
				maxContainerWt = formulaContainerWt;
			
			if(maxContainerWt != null && maxContainerWt > 0 
			&& this.state.formData.umOrdered > (maxContainerWt * this.state.formData.quantity)) {
				validation.errors.quantity = Config.locale === "EN_US" ? 
				"Weight Ordered is over the max weight for the container. Please adjust Quantity." : 
				"El peso pedido supera el peso máximo del contenedor. Ajuste la cantidad.";
				validation.result = false;
			}
			
		}
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			const formulaRow = this.state.formData;
			this.props.onSave(formulaRow, this.state.bHasChanged);
		}
		
	}
	
	render() {
		// console.log("OrderFormulaForm::render() - this.props: ", this.props);
		const loggedInUser = getLoggedInUser();
		const customerFormulasListMapped = this.state.customerFormulasList.map(obj => ({
			value: obj.formulaId,
			// label: obj.formula + " - " + obj.name + (obj.lastOrderedDate ? " - " + (new Intl.DateTimeFormat('en-US').format(new Date(obj.lastOrderedDate))) : ""),
			label: obj.formula + " - " + (obj.customerFormulaName ? obj.customerFormulaName : obj.name) + (obj.lastOrderedDate ? " - " + convertISOtoShortDate(obj.lastOrderedDate) : ""),
			})
		);
		const formulaId = this.state.formData.formulaId;
		const formulaName = formulaId ? 
			customerFormulasListMapped.filter(obj => obj.value == formulaId)[0] : null;
		
		const customerFormulaPricing = mapArrayToObject(this.state.customerFormulaPricing.map((obj, key) => ({
			id: obj.containerId, 
			name: this.props.containersList.filter(obj2 => obj2.id == obj.containerId)[0]['name'],
		})), 'id', 'name');
		
		const matchedByMapped = mapArrayToObject(this.props.labTechsList, 'id', 'name');
		

		var content = ''; 
		const orderTypeLookupId = this.props.orderTypeLookupId;
		const qcD = this.state.formData.qcDisabled; // this.state.formData.qcDate && true;
		const completedDate = this.state.formData.completedDate                       
		&& loggedInUser.userTypeId != constants.vip_type_id
		&& loggedInUser.userTypeId != constants.finance_type_id;
		
		const completedDisabled = this.state.formData.completedDisabled;
		
		let weightSymbol = Config.weightSymbol;
		if(this.props.umCodeLookupId == constants.um_code_kg) {
			weightSymbol = 'KG';
		} else if(this.props.umCodeLookupId == constants.um_code_lb) {
			weightSymbol = 'LB';
		} 
		
		switch(orderTypeLookupId)
		{
			case lab_request_lookup_id:
				content =
				<Form className='form-page' horizontal>
					<Row>
						<Col md={2}></Col>
						<Col md={8}>
							{this.props.lrTypeId == constants.lr_type_repull_lookup_id && <InputText label={<Translate value="components.labels.formula" />} 
								id={"formulaId"} name={"formulaId"} action={this.handleInputChanged} 
								data={customerFormulasListMapped} type={"SingleSelect"} 
								value={formulaName} errMsg={this.state.errors.formulaId} 
								disabled={completedDate}
							/>}
							<InputText 
								label={<Translate value={"components.labels." + (this.props.lrTypeId != constants.lr_type_repull_lookup_id ? "colorMatchName" : "formulaName")} />} 
								id={"colorMatchName"} name={"colorMatchName"} 
								action={this.handleInputChanged} 
								value={this.state.formData.colorMatchName} 
								disabled={completedDate}
							/>
							<InputText label={<Translate value={"components.labels." + (this.props.lrTypeId != constants.lr_type_repull_lookup_id ? "anilox" : "numDrawDowns")} />} 
								id={"anilox"} name={"anilox"} action={this.handleInputChanged} 
								value={this.state.formData.anilox} 
								disabled={completedDate}
							/>
							{this.props.lrTypeId != constants.lr_type_repull_lookup_id && <InputText label={<Translate value="components.labels.matchToPMS" />} 
								id={"matchToPMS"} name={"matchToPMS"} 
								action={this.handleInputChanged} 
								value={this.state.formData.matchToPMS} 
								disabled={completedDate}
							/>}
							{this.props.lrTypeId != constants.lr_type_repull_lookup_id && <InputText label={<Translate value="components.labels.matchToGCMI" />} 
								id={"matchToGCMI"} name={"matchToGCMI"} 
								action={this.handleInputChanged} 
								value={this.state.formData.matchToGCMI} 
								disabled={completedDate}
							/>}
							{this.props.lrTypeId != constants.lr_type_repull_lookup_id && <InputText 
								label={<Translate value="components.labels.matchToLiquidSample" />} 
								id={"matchToLiquidSample"} name={"matchToLiquidSample"} 
								action={this.handleInputChanged} type={"CheckBox"} 
								value={this.state.formData.matchToLiquidSample} 
								disabled={completedDate}
							/>}
							{this.props.lrTypeId != constants.lr_type_repull_lookup_id && <InputText 
								label={<Translate value="components.labels.matchToPrintSample" />} 
								id={"matchToPrintSample"} name={"matchToPrintSample"} 
								action={this.handleInputChanged} type={"CheckBox"} 
								value={this.state.formData.matchToPrintSample} 
								disabled={completedDate}
							/>}
							{this.props.lrTypeId != constants.lr_type_repull_lookup_id && <InputText label={<Translate value="components.labels.matchToOther" />} 
								id={"matchToOther"} name={"matchToOther"} 
								action={this.handleInputChanged} 
								value={this.state.formData.matchToOther} 
								disabled={completedDate}
							/>}
							<DataDropdown label={<Translate value={"components.labels." + (this.props.lrTypeId != constants.lr_type_repull_lookup_id ? "matchedBy" : "completedBy")} />} 
								id={"matchedByUserId"} name={"matchedByUserId"} 
								action={this.handleInputChanged} data={matchedByMapped} 
								value={this.state.formData.matchedByUserId} 
								disabled={completedDate}
							/>
							<InputText label={<Translate value="components.labels.notes" />} 
								id={"formulaNote"} name={"formulaNote"} 
								action={this.handleInputChanged} type={"TextArea"} 
								value={this.state.formData.formulaNote} 
								disabled={completedDate}
							/>
							{this.props.lrTypeId != constants.lr_type_repull_lookup_id && <InputText 
								label={<Translate value="components.labels.transparent" />} 
								id={"btransparent"} name={"btransparent"} 
								action={this.handleInputChanged} type={"CheckBox"} 
								value={this.state.formData.btransparent} 
							/>}
							{this.props.lrTypeId != constants.lr_type_repull_lookup_id && <InputText 
								label={<Translate value="components.labels.opaque" />} 
								id={"bopaque"} name={"bopaque"} 
								action={this.handleInputChanged} type={"CheckBox"} 
								value={this.state.formData.bopaque} 
							/>}
							{/* {this.props.editOrderId && //Don't need the checkbox on LRs
							<InputText label={<Translate value="components.labels.completed" />} 
								id={"completedDate"} name={"completedDate"} 
								action={this.handleInputChanged} type={"CheckBox"} 
								value={completedDate} 
								disabled={this.props.labRequestFormulaIndex != null 
									|| completedDisabled} 
							/>} */}
						</Col>
						<Col md={2}></Col>
					</Row>
				</Form>;
				break;
				
			default:
				content =
				<Form className='form-page' horizontal>
					<Row>
						<Col md={2}></Col>
						<Col md={8}>
							<InputText label={<Translate value="components.labels.formula" />} 
								id={"formulaId"} name={"formulaId"} action={this.handleInputChanged} 
								data={customerFormulasListMapped} type={"SingleSelect"} 
								value={formulaName} errMsg={this.state.errors.formulaId} 
								disabled={completedDate}
							/>
							<InputText label={<Translate value="components.labels.quantity" />} 
								id={"quantity"} name={"quantity"} action={this.handleInputChanged} 
								value={this.state.formData.quantity} 
								errMsg={this.state.errors.quantity} 
								disabled={completedDate}
							/>
							<DataDropdown label={<Translate value="components.labels.container" />} 
								id={"containerId"} name={"containerId"} 
								action={this.handleInputChanged} data={customerFormulaPricing} 
								value={this.state.formData.containerId} 
								errMsg={this.state.errors.containerId} 
								disabled={completedDate}
							/>
							<InputText label={<Translate value={"components.labels." + 
								weightSymbol + "sOrdered"} />} id={"umOrdered"} 
								name={"umOrdered"} action={this.handleInputChanged} 
								value={this.state.formData.umOrdered} 
								errMsg={this.state.errors.umOrdered} 
								disabled={completedDate}
							/>
							<InputText label={<Translate value={"components.labels." + 
								weightSymbol + "sShipped"} />} id={"umShipped"} 
								name={"umShipped"} action={this.handleInputChanged} 
								value={this.state.formData.umShipped} 
								errMsg={this.state.errors.umShipped} 
								disabled={completedDate}
							/>
							{this.props.editOrderId && 
							<InputText label={<Translate value={"components.labels." + 
								weightSymbol + "sMade"} />} id={"umMade"} 
								name={"umMade"} action={this.handleInputChanged} 
								value={this.state.formData.umMade} 
								errMsg={this.state.errors.umMade} 
								disabled={completedDate}
							/>}
							<InputText type='Label'
								label={<Translate value="components.labels.unitPrice" />}
								value={toCurrency(this.state.formData.price)}
							/>
							<InputText label={<Translate value="components.labels.discount" />} 
								id={"discountAmt"} name={"discountAmt"} 
								action={this.handleInputChanged} 
								value={this.state.formData.discountAmt} 
								errMsg={this.state.errors.discountAmt} 
								disabled={completedDate}
							/>
							<InputText type='Label'
								label={<Translate value="components.labels.extPrice" />}
								value={toCurrency(this.state.formData.extPrice)}
							/>
							{this.props.editOrderId && 
							<InputText label={"QC'd"} id={"qcDate"} name={"qcDate"} 
								action={this.handleInputChanged} type={"CheckBox"} 
								value={this.state.formData.qcFlag} disabled={qcD} />}
								
							{this.props.editOrderId && 
							<InputText label={<Translate value="components.labels.completed" />} 
								id={"completedDate"} name={"completedDate"} 
								action={this.handleInputChanged} type={"CheckBox"} 
								value={this.state.formData.completedFlag} 
								disabled={completedDisabled} />}
						</Col>
						<Col md={2}></Col>
					</Row>
				</Form>;
				break;
		}
		return(<Modal show={this.props.visible} >
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
				<Modal.Body>
					{content}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={this.onSaveOrderFormula} bsStyle="primary" 
						disabled={loggedInUser.accessOrdersCode == 'V' || completedDate}>
						<Translate value="components.buttons.saveChanges" />
					</Button>
				</Modal.Footer>
			</Modal.Header>
		</Modal>);
	}
}

class BillOfLadingInstructionsForm extends Component {
	
	constructor(props){
		super(props);
			
		this.state = {
			title: 'Bill of Instructions',
			formData: {
				skidCount: 0,
				billOfLadingInstructions: "",
			},
			bShowEditableReportSummaryForm: false,
			calculatedSummary: [],
			editableReportSummary: [],
			selectedReportDetailsRowIndex: null,
			deletedReportDetailIDs: [],
			skidWeight: 0,
		}
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onRemove = this.onRemove.bind(this);
		this.onSaveChanges = this.onSaveChanges.bind(this);
	}
	
	componentDidMount() {
		// console.log("BillOfLadingInstructionsForm::componentDidMount() called: this.props: ", this.props);
		const salesOrderId = this.props.salesOrderId;
		const orderFormulas = this.props.orderFormulas;
		
		let formData = this.state.formData;
		formData.skidCount = this.props.skidCount;
		formData.billOfLadingInstructions = this.props.billOfLadingInstructions;

		getAPI("salesorders/" + salesOrderId + "/reports/details/list").then(resDetails => {
			// console.log("orderFormulas: ", orderFormulas, ", resDetails: ", resDetails);
			let skidWeight = 0;
			
			
			
			let objContainers = {};
			orderFormulas.forEach((row, i) => {
				
				const containerName = row.containerName;
				const containerRow = this.props.containersList.filter(cont => cont.name == containerName)[0];
				const quantity = parseFloat(row.quantity);
				const umOrdered = parseFloat(row.umOrdered);
				let emptyWeight = containerRow ? parseFloat(containerRow.emptyWeight) : 0;
				if(this.props.umCodeLookupId == constants.um_code_kg && Config.locale === "EN_US"){ 
					emptyWeight = emptyWeight / Config.weightConversionUnit;
				}
				let weight = Math.round(umOrdered + (quantity * emptyWeight));
				// console.log("BillOfLadingInstructionsForm::componentDidMount() - this.props.umCodeLookupId: ", this.props.umCodeLookupId, ", Config.locale: ", Config.locale);
				
				
				if(objContainers[containerName] == undefined) {
					objContainers[containerName] = {
						id:0, 
						name: containerName, 
						quantity:0, 
						weight: 0, 
						salesOrderId: salesOrderId, 
						retired:false
					}
				}
				
				
				objContainers[containerName].quantity += quantity;
				objContainers[containerName].weight += weight;
				console.log("containerName: ", containerName, ", row.umOrdered: ", row.umOrdered, ", row.quantity: ", row.quantity, ", emptyWeight: ", emptyWeight, ", objContainers[containerName].weight: ", objContainers[containerName].weight );
				skidWeight += weight;
			});
			
			// console.log("objContainers.values: ", Object.values(objContainers));
			const calculatedSummary = Object.values(objContainers);
			
			let editableReportSummary = resDetails['data'];
			// console.log("editableReportSummary: ", editableReportSummary, ", calculatedSummary: ", calculatedSummary);
			if(!editableReportSummary.length)
				editableReportSummary = calculatedSummary;
				
			this.setState({
				formData: formData,
				calculatedSummary: calculatedSummary,
				editableReportSummary: editableReportSummary,
				skidWeight: skidWeight,
			});
		});
	}
	
	handleInputChanged(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		
		var formData = this.state.formData;
		formData[name] = value;
		
		this.setState({
		  formData: formData,
		});
	}
	
	onRemove(event) {
		event.preventDefault();
		
		const selectedReportDetailsRowIndex = this.state.selectedReportDetailsRowIndex;
		if(selectedReportDetailsRowIndex != null) {
			var editableReportSummary = this.state.editableReportSummary;
			var deletedReportDetailIDs = this.state.deletedReportDetailIDs;
		
			const row = 
				this.state.editableReportSummary[selectedReportDetailsRowIndex];
				
			if(row.id > 0)
				deletedReportDetailIDs.push(row);
			
			// console.log("deletedReportDetailIDs: ", deletedReportDetailIDs);
			editableReportSummary.splice(selectedReportDetailsRowIndex, 1);
			this.setState({
				editableReportSummary: editableReportSummary,
				deletedReportDetailIDs: deletedReportDetailIDs,
			});
		}
	}
	
	onSaveChanges(row) {
		let editableReportSummary = this.state.editableReportSummary;
		// Use this to Save the record
		row.bModified = true;
		row.retired = row.retired == null ? false : row.retired;
		if(this.state.selectedReportDetailsRowIndex != null)
		{
			//////// THIS WAS EDIT MODE
			editableReportSummary[this.state.selectedReportDetailsRowIndex] = row;	
		}
		else
		{
			///////// THIS WAS INSERT MODE
			editableReportSummary.push(row);
		}
		this.setState({
			editableReportSummary: editableReportSummary,
			bShowEditableReportSummaryForm: false,
		});
	}

	render() {
		let skidCount = this.state.formData.skidCount;
		let billOfLadingInstructions = this.state.formData.billOfLadingInstructions;
		
		
		const editReportDetailsRow = this.state.selectedReportDetailsRowIndex != null ? 
			this.state.editableReportSummary[this.state.selectedReportDetailsRowIndex] : null;
		const editableReportSummaryForm = this.state.bShowEditableReportSummaryForm ? 
		(<EditableReportSummaryForm
			visible={this.state.bShowEditableReportSummaryForm}
			onClose={() => this.setState({bShowEditableReportSummaryForm: false})}
			onSave={this.onSaveChanges}
			editReportDetailsRow={editReportDetailsRow}
			salesOrderId={this.props.salesOrderId}
		/>)
		: '';
		
		const content = <Form className='form-page' horizontal>
			<Row>
				<Col md={8}>
					<InputText label={<Translate value='components.labels.numberOfSkids' />} 
						name={"skidCount"} action={this.handleInputChanged}
						value={skidCount}
					/>
				</Col>
				<Col md={4}>
					<Button onClick={() => {
						const instructions = getDefaultBillOfLadingInstructions(skidCount, 
							this.state.skidWeight, this.props.scheduledDeliveryDate
							, this.props.umCodeLookupId);
						let formData = this.state.formData;
						formData['billOfLadingInstructions'] = instructions;
						this.setState({
							formData: formData,
						});
					}}>
						<Translate value='components.buttons.populate' />
					</Button>
				</Col>
			</Row>
			<Row>
				<Col md={8}>
					<InputText label={<Translate value='components.labels.instructions' />} 
						type='TextArea' name={"billOfLadingInstructions"}
						 action={this.handleInputChanged}
						value={billOfLadingInstructions}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={4}>
					<h4><Translate value='components.labels.calculatedSummary' /></h4>
					<DataGrid
						columns={[
							<Translate value='components.labels.quantity' />,
							<Translate value='components.labels.description' />,
							<Translate value='components.labels.weight' />,
						]}
						fields={['quantity', 'name', 'weight']}
						data={this.state.calculatedSummary}
					/>
				</Col>
				<Col md={8}>
					<h4><Translate value='components.labels.editableReportSummary' /></h4>
					<DataGrid
						columns={[
							<Translate value='components.labels.quantity' />,
							<Translate value='components.labels.description' />,
							<Translate value='components.labels.weight' />,
						]}
						fields={['quantity', 'name', 'weight']}
						data={this.state.editableReportSummary}
						onDoubleClick={() => {
							this.setState({bShowEditableReportSummaryForm: true});
						}} 
						onSelect={(rowIndex) => {
							// console.log("rowIndex: ", rowIndex);
							this.setState({selectedReportDetailsRowIndex: rowIndex});
						}}
					/>
					<ButtonGroup>
						<Button onClick={() => {
							this.setState({
								selectedReportDetailsRowIndex: null,
								bShowEditableReportSummaryForm: true,
							});
						}}>
							<Translate value='components.buttons.add' />
						</Button>
						<Button onClick={this.onRemove}>
							<Translate value='components.buttons.delete' />
						</Button>
						{editableReportSummaryForm}
					</ButtonGroup>
				</Col>
			</Row>
		</Form>

		return <Modal show={this.props.visible} bsSize='large' >
			<Modal.Header>
				<Modal.Title><Translate value='components.buttons.billOfLadingInstructions' /></Modal.Title>
				<Modal.Body>
					{content}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}>
						<Translate value="components.buttons.close" />
					</Button>
					<Button color='primary' onClick={() => this.props.onUpdate(this.state)}>
						<Translate value="components.buttons.update" />
					</Button>
				</Modal.Footer>
			</Modal.Header>
		</Modal>;
	}
}

const validationFieldsEditableReportSummary = {
	quantity: "required,numeric",
	name: "required",
	weight: "required,numeric",
};

class EditableReportSummaryForm extends Component
{
	constructor(props) {
		super(props);
		
		this.state = {
			title: "Editable Details",
			formData: {
				weight:0,
				id:0,
				model:"com.phototype.myinkiq.model.vos.dtos::SalesOrderReportDetailsDto",
				salesOrderId: this.props.salesOrderId,
				retired:false,
				quantity:0,
				name:"",
			},
			errors: {
				weight: "",
				quantity: "",
				name: "",
			},
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	
	componentDidMount() {
		/////////////// CHECKING FOR EDIT MODE //////////////////
		if(this.props.editReportDetailsRow) { // If this is not null
		
			this.setState({
				formData: this.props.editReportDetailsRow,
			});
		}
		
	}
	
	handleInputChanged(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		
		var formData = this.state.formData;
		formData[name] = value;
		
		this.setState({
		  formData: formData,
		});
	}
	
	
	onSave(event) {
		event.preventDefault();
		const validation = validateForm(this.state.formData, validationFieldsEditableReportSummary);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			let editReportDetailsRow = this.state.formData;
			this.props.onSave(editReportDetailsRow);
		}
	}
	
	render() {
		const loggedInUser = getLoggedInUser();
		var content = (<Form className='form-page' horizontal>
			<Row>
				<Col md={2}></Col>
				<Col md={8}>
					<InputText label={<Translate value="components.labels.quantity" />} 
						id={"quantity"} name={"quantity"} action={this.handleInputChanged} 
						value={this.state.formData.quantity} 
						errMsg={this.state.errors.quantity}
					/>
					<InputText label={<Translate value="components.labels.description" />} 
						id={"name"} name={"name"} action={this.handleInputChanged} 
						value={this.state.formData.name} 
						errMsg={this.state.errors.name}
					/>
					<InputText label={<Translate value="components.labels.weight" />} 
						id={"weight"} name={"weight"} action={this.handleInputChanged} 
						value={this.state.formData.weight} 
						errMsg={this.state.errors.weight}
					/>
				</Col>
				<Col md={2}></Col>
			</Row>
		</Form>);
				
		return(<Modal show={this.props.visible} >
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
				<Modal.Body>
					{content}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={this.onSave} bsStyle="primary">
						<Translate value="components.buttons.saveChanges" />
					</Button>
				</Modal.Footer>
			</Modal.Header>
		</Modal>);
	}
	
}
