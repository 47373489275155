export default {
	'customers': {
        'status': 'Estado',
        // Tab titles
        'customerInfo': 'Información del cliente',
        'additionalAddresses': 'Direcciones Adicionales',
        'contacts': 'Contactos',
        'orders': 'Pedidos',
        'processNotes': 'Notas de proceso',
        'presses': 'Prensas',
        // column titles
        'userName': 'Nombre de Usuario',
        'lastUsed': 'Utilizado por última vez',
        'loginCount': 'Cuenta de Ingreso',
        'phoneNumber': 'Número de Teléfono',
        'billingAddress': 'Dirección de Envio',
        'id': 'ID',
        'name': 'Nombre',
        'phone': 'Teléfono',
        'lastSalesOrderDate': 'Fecha de la última orden',
        // general fields
        'addresses':'Direcciones',
        'addNewAddress':'Agregar nueva dirección',
        'defaultMeasurementConditions':'Condiciones de medición predeterminadas',
        'miscInfo':'Información variada',
        'mainAddress':'Dirección principal',
        'customerName': 'Nombre del Cliente',
        'address1': 'Dirección 1',
        'address2': 'Dirección 2',
        'city': 'Ciudad',
        'country': 'País',
        'stateProvince': 'Estado/Provincia',
        'zipCode': 'Código postal',
        'phone': 'Teléfono',
        'fax': 'Fax',
        'clientUsers':'Usuarios del cliente',
        'salesReps': 'Representantes de ventas',
        'salesGoal': 'Objetivo de ventas',
        'defaultShipper': 'Remitente predeterminado',
        'defaultShipTime': 'Tiempo de envío predeterminado',
        'umCode': 'UM Código',
        'customerTerms': 'Términos del cliente',
        'discountPercent': 'Descuento %',
        'customerSince': 'Cliente desde',
        'printHmis': 'Imprimir HMIS?',
        'notes': 'Notas',
        'illuminant': 'Iluminante',
        'observerAngle': 'Ángulo del observador',
        'deltaE': 'ΔE',
        'device': 'Dispositivo',
        'colorTolerance': 'Tolerancia de color',
        'setupReadCount': 'Configurar lectura #',
        'productionReadCount': 'Producción leída #',
        'targetReadCount': 'Target Read #',
        'qcReadCount': 'QC Read #',
        'addAddress': 'Añadir dirección',
        'editAddress': 'Editar Dirección',
        'saveAddress': 'Guardar Dirección',
        'newCustomer': 'Nuevo Cliente',
        'deleteAddress': 'Eliminar Direccion',
        'editContact': 'Editar Contacto',
        'addNewContact': 'Añadir nuevo contacto',
        'type': 'Tipo',
        'mobileNumber': 'Número de móvil',
        'deleteContact': 'Eliminar Contacto',
        'saveContact': 'Guardar Contacto',
        'spanish': 'Espanol',
        'email': 'Dirección de Correo Electrónico',
        'addContact': 'Añadir Contacto',
        'close': 'Cerrar',
        'saveChanges': 'Guardar Cambios',
        'deleteCustomer': 'Eliminar Cliente',
        'areYouSure': '¿Estás seguro de que quieres eliminar este cliente?',
        'delete': 'Eliminar',
        'cancel': 'Cancelar',
        'formulas': 'Fórmulas',
        'formulaNumber': 'Fórmula #',
        'substrate': 'Sustrato',
        'anilox': 'Anilox',
        'price': 'Precio',
        'lastOrdered': 'Última Ordenada',
        'addNewPress': 'Añadir nueva prensa',
        'pressInformation': 'Información de prensa',
        'pressName': 'Nombre de la prensa',
        'pressType': 'Tipo de prensa',
        'vacuumTransfer': 'Transferencia de vacío',
        'varnishPackage': 'Paquete de barniz',
        'phMin': 'pH mínimo',
        'phMax': 'pH máx.',
        'blendingSystem': 'Sistema de mezcla',
        'baseSystem': 'Sistema base',
        'viscosityRange': 'Rango de viscosidad Din 4',
        'viscosityCup': 'Copa de viscosidad',
        'lastUpdatedBy': 'Última actualización por',
        'lastUpdatedOn': 'Ultima actualizacion en',
        'specialNotes': 'Notas especiales',
        'station': 'Estación',
        'dryers': 'Secadores',
        'meteringSystem': 'Sistema de medición',
        'bladesType': 'Tipo de cuchillas',
        'uv': 'UV',
        'deleteStation': 'Eliminar estacion',
        'newStation': 'Nueva estacion',
        'anilox': 'Anilox',
        'volume': 'Volumen',
        'matchWith': 'Juntar con',
        'deleteAnilox': 'Eliminar anilox',
        'newAnilox': 'Nuevo anilox',
        'numberOfStations': '# Estaciones',
        'save': 'Guardar',
        'orderStatus': 'Estado de Orden',
        'orderType': 'Tipo de Orden',
        'orderId': 'Orden Id',
        'orderedDate': 'Fecha ordenada',
        'orderAmount': 'Total de la Orden',
        'note': "Nota",
        'tieredVolumeDiscount': 'Descuentos por volumen escalonado',
        'tierDiscounts': 'Descuentos por volumen escalonado',
        'tier1Discount': 'Descuento %',
        'tier2Discount': 'Descuento %',
        'tier3Discount': 'Descuento %',
        'tier1StartVolume': 'Volumen inicial',
        'tier2StartVolume': 'Volumen inicial',
        'tier3StartVolume': 'Volumen inicial',
        'tier1EndVolume': 'Volumen final',
        'tier2EndVolume': 'Volumen final',
        'tier3EndVolume': 'Volumen final',
        'addNote': 'Añadir Nota',
        'processedBy': 'Procesado por',
        'processedDate': 'Procesado en',
        'saveNote': 'Guardar Nota',
        'deleteNote': 'Borrar Nota',
        'editNote': 'Editar Nota',
        'createFirstStations': 'Primero cree y guarde los datos básicos de la prensa antes de editar las estaciones.',
        'createFirstAniloxes': 'Primero cree y guarde los datos básicos de la prensa antes de editar los aniloxes.',
        'editPress': 'Editar Prensa',
        'emailsRecieved': "Correos electrónicos recibidos",
        'proccessed': "Procesada",
		'lrNotes': 'LR Notas',
		'bFuelSurcharge': 'Recargo por combustible',
		'bFreightSurcharge': 'Recargo de flete',
		'bPlasticDrums': 'Tambores de plástico',
		'bCustomizeBarcode': 'Código de barras más pequeño',
		'consignmentCustomer': 'Consignación Cliente',
		'autoPrintCofA': 'Imprimir C de A',
		'bSpectro1': 'Spectro1',
		'bWetSample': 'Muestra húmeda',
		'underContract': 'Según el contrato',
		'contractExpires': 'El contrato finaliza',
		'currentPrimaryInkSupplier': 'Proveedor de tinta principal actual',
		'blendingSystem': 'Sistema de mezcla',
		'numberOfHeads': 'Numero de cabezas',
		'customerOwned': 'Propiedad del cliente',
		'condition': 'Condición',
		'estimatedInkSpendFit': 'Gasto anual estimado en tinta apto para BCM',
		'fittedBusinessCaptured': 'Porcentaje de negocios ajustados capturados',
		'bcmCurrentPosition': 'Posición actual BCM',
		'prepressPrintingPlates': 'Planchas de preimpresión / impresión',
		'aniloxRollProvider': 'Proveedor de rollos anilox',
		'dryersLampsProvider': 'Proveedor de secadoras / lámparas',
		'otherRelativeSuppliers': 'Otros proveedores relativos',
		'startDate': 'Fecha de inicio',
		'endDate': 'Fecha final',
		'totalAmount': 'Cantidad total',
		'totalSales': 'Ventas totales',
		'equipment': 'Equipo',
		'percentTotalContract': '% al contrato total',
		'editContract': 'Editar contrato',
		'addContract': 'Agregar contrato',
		'saveContract': 'Guardar contrato',
		'deleteContract': 'Eliminar contrato',
		'download': 'Descargar',
		'contract': 'Contrato',
		'otherSuppliers': 'Otros proveedores',
		'defaultPrints': 'Impresiones predeterminadas',
		'defaultWeights': 'Pesos predeterminados',
		'bUseZahn2EZCup': 'Usa la copa Zahn2EZ',
		'viscosityCupConversion': 'Conversión de copa de viscosidad',
		'shipmentOutlier': 'Valor atípico del envío',
		'matchesFound': 'El nombre del cliente coincide con los siguientes existentes:',
		'saveAnyway': 'Guardar de todos modos',
		"isCorporate": "Es corporativa",
		"parentCorporate": "Corporación matriz",

    }
}
