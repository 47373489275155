export default {
	'settings': {
		'companyInfo': 'Información de la compañía',
		'displayName': 'Nombre para mostrar',
		'address1': 'Dirección 1',
		'address2': 'Dirección 2',
		'city': 'Ciudad',
		'zipCode': 'Código postal',
		'country': 'País',
		'state': 'Estado',
		'phone': 'Teléfono',
		'fax': 'Fax',
		'website': 'Sitio web',
		'formulaCostInfo': 'Información de costo de fórmula (Mex$)',
		'formulaMiscCharge': 'Fórmula Misc. Cargar',
		'formulaContainerCost': 'Costo del contenedor de fórmula',
		'formulaShrinkageAmount': 'Cantidad de reducción de la fórmula %',
		'overheadMarkup': 'Margen de arriba %',
		'shippingCost': 'Costo de envío',
		'defaultMeasurementConditions': 'Condiciones de medición predeterminadas',
		'illuminant': 'Iluminante',
		'observerAngle': 'Ángulo del observador',
		'deltaE': 'ΔE',
		'device': 'Dispositivo',
		'baseTolerance': 'Tolerancia base',
		'inkTolerance': 'Tolerancia de tinta',
		'numQcReads': '# QC Lee',
		'numTargetReads': '# Target Reads',
		'cancel': 'Cancelar',
		'save': 'Salvar/Provincia',
		'userType': 'Tipo de usuario',
		'firstName': 'Nombre de pila',
		'lastName': 'Apellido',
		'email': 'Email',
		'displayName': 'Nombre para mostrar',
		'userName': 'Nombre de usuario',
		'inactive': 'Inactivo',
		'newPassword': 'Nueva contraseña',
		'confirmNewPassword': 'Confirmar nueva contraseña',
		'requireChange': 'Requiere cambio',
		'customers': 'Clientes',
		'components': 'Componentes',
		'formulas': 'Fórmulas',
		'inventory': 'Inventario',
		'pos': 'POs',
		'salesOrders': 'Ordenes de venta',
		'vendors': 'Vendedores',
		'shippers': 'Remitentes',
		'accountingFunctions': 'Funciones contables',
		'confirmSalesOrder': 'Confirmar orden de venta',
		'userMaintanence': 'Mantenimiento del usuario',
		'userMaintanenceCustomer': 'Mantenimiento del usuario (Cliente)',
		'userMaintanenceAccess': 'Mantenimiento del usuario (acceso)',
		'editCompanyInformation': 'Editar información de la compañía',
		'approveFormulaPrice': 'Aprobar el precio de la fórmula',
		'userMaintanenceRights': 'Mantenimiento del usuario (derechos)',
		'userMaintanenceReports': 'Mantenimiento del usuario (informes)',
		'userInfo': 'Información de usuario',
		'sessionInfo': 'Información de la sesión',
		'loginCount': 'Cuenta de inicio de sesión',
		'spanish': 'Español',
		'programAccess': 'Acceso al programa',
		'canView': 'Puedo ver',
		'canEdit': 'Poder editar',
		'reportAccess': 'Informe de acceso',
		'userRights': 'Derechos de los usuarios',
		'colorConnexionAccess': 'Color ConneXion Access',
		'colorConnexion': 'Color ConneXion',
		'admin': 'Administración',
		'name': 'Nombre',
		'contact': 'Contacto',
		'paymentType': 'Tipo de pago',
		'sameAsContactInfo': 'Igual que la información de contacto',
		'genericCommodityVendor': 'Proveedor genérico de productos básicos',
		'paymentTerms': 'Términos de pago',
		'minOrderQuantity': 'Cantidad minima para ordenar',
		'comments': 'Comentarios',
		'vendorInfo': 'Información del vendedor',
		'billingInfo': 'Información de facturación',
		'other': 'Otro',
		'newVendor': 'Nuevo vendedor',
		'newContactType': 'Nuevo tipo de contacto',
		'close': 'Cerrar',
		'saveChanges': 'Guardar cambios',
		'prePaid': 'Pagado por adelantado',
		'collect': 'Recoger',
		'thirdParty': 'Tercero',
		'dueOnReceipt': 'Debido a la recepción',
		'shipperInfo': 'Información del remitente',
		'newShipper': 'Nuevo cargador',
		'users': 'Usuarios',
		'administration': 'Administración',
		'company': 'Empresa',
		'type': 'Tipo',
		'lastUpdated': 'Última actualización',
		'newUser': 'Nuevo usuario',
		'vendorName': 'Nombre del vendedor',
		'poNumber': 'PO #',
		'status': 'Estado',
		'location': 'Ubicación',
		'substrateName': 'Nombre del sustrato',
		'newSubstrate': 'Nuevo sustrato',
		'addNewSubstrate': 'Agregar nuevo sustrato',
		'viscosityCupName': 'Nombre de copa de viscosidad',
		'newViscosityCup': 'Nuevo copa de viscosidad',
		'addNewViscosityCup': 'Agregar nuevo copa de viscosidad',
		'customerAccess': 'Acceso de clientes',
		'canAccessCustomers': 'Puede acceder a los clientes',
		'lastLogin': 'Último acceso',
		'category': 'Categoría',
		'allowCreateColor': 'Permitir la creación de colores',
		'allowEditColor': 'Permitir editar color',
		'allowCreateEditJobs': 'Permite crear y editar trabajos',
		'allowDeactivationOfFormulas': 'Permitir la desactivación de fórmulas',
		'allowPerformReads': 'Permitir realizar lectura',
		'allowJobColorDelete': 'Permitir borrar trabajos y colores',
		'allowJobNoteCreation': 'Permitir la creación de notas de trabaj',
		'selectAll': 'Seleccionar todo',
		'jobInterface': 'Interfaz de trabajo',
		'colorConnexion': 'Color ConneXion',
		'createColor': 'Crear color',
		'accessSetTarget': 'Permitir establecer objetivo',
		'performReads': 'Realizar lecturas',
		'maintainJob': 'Mantener el trabajo',
		'accessDelete': 'Acceso eliminar',
		'deactivateFormula': 'Desactivar fórmula',
		'notes': 'Notas',
		'taxRate': 'Tasa de impuesto %',
		'currencyConversionUnit': 'USD to Mex$ Rate',
		'allCustomers': 'Todos los clientes',
		'rfc': 'RFC',
		'emailAccess': 'Acceso al correo electrónico',
		"visibleToCustomer": "Fórmula visible para la cliente",
		"shipmentOutlierAccess": "Acceso a envíos atípicos",
		"instrumentsDisabledFlag": "Instrumentos desactivados",
		"inkQualificationQCFlag": "Calificación de tinta (QC)",
		"contactUs": "Contacta con nosotras",
		"subject": "Sujeta",
		"message": "Mensaje",
		"send": "Enviar",
		"viscosityCups": "Copas de viscosidad",
		"substrates": "Sustratos",
		"contactPositions": "Posiciones de contacto",
		"cannotConvert": "No convertir",
		"din4Value": "Valor DIN 4",
		"otherCupValue": "Otro valor de taza",
		"viscosityCupConversion": "Conversión de copa de viscosidad",
	}
}
